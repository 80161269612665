import {Button} from "../../atoms/Button/Button";
import './User.scss';
import {useContext, useEffect, useState} from "react";
import {UsersContext} from "../../providers/UserProvider";

export function User({filterLevel, searchQuery}: any) {
    const {users, deleteUser}: { users: any[], deleteUser: Function } = useContext(UsersContext);
    const [filteredUsers, setFilteredUsers] = useState(users);

    useEffect(() => {
        setFilteredUsers(performFilter(filterLevel, searchQuery));
    }, [filterLevel, users, searchQuery]);

    const performFilter = (filter: string | undefined, searchQuery: string | undefined) => {
        let filteredUsers = users;
        //console.log(filter);
        if (filter && filter !=='all') {
            //console.log('filtering on level');
            filteredUsers = users.filter((user)=>user.groups.map((group:string)=>group.toLowerCase()).includes(filter.toLowerCase()));
        }
        if (searchQuery && searchQuery !== '') {
            filteredUsers = filteredUsers.filter((user)=>user.name.toLowerCase().includes(searchQuery.toLowerCase()));
        }
        return filteredUsers;
    }

    return (
        <>
            {filteredUsers && filteredUsers.map((user:any, index:any) => {
                console.log('user', user);
                return (
                    <div key={index}
                         className={'w-full justify-between user rounded-corners-big-card p-5 gap-6 flex bg-white'}>
                        <dl className={'gap-6 flex'}>
                            <div className={'flex flex-col-reverse w-[240px] justify-end'}>
                                <dt>{user['custom:subject']}</dt>
                                <dd>{user.name} {user.family_name}</dd>
                            </div>
                            <div>
                                <dt>Hoot CV</dt>
                                <dd className={`${user.hasCV ? 'primary' : 'error'}`}>{user.hasCV ? 'Yes' : 'No'}</dd>
                            </div>
                            <div>
                                <dt>Phone</dt>
                                <dd>{user.phone_number}</dd>
                            </div>
                            <div>
                                <dt>Email</dt>
                                <dd>{user.email}</dd>
                            </div>
                        </dl>
                        <div className={'flex gap-4'}>
                            {/*<Button className={'font-bold'} color={'light-blue'} text={'Notify'} variant={'secondary'} />*/}
                            <Button className={'font-bold'} color={'light-red'} text={'Delete'}
                                    clickHandler={() => deleteUser(user.email)}/>
                        </div>
                    </div>
                )
            })}
        </>
    )
}
