import {createContext, ReactNode, useContext, useEffect, useState} from "react";

export const LocationContext: any = createContext(null);

export function LocationProvider({children}: {children: ReactNode}) {
    const [latLng, setLatLng] = useState([] as undefined | number[]);
    const [iP, setIP] = useState(undefined as string | undefined);
    const [postcode, setPostcode] = useState(undefined as string | undefined);
    const [radius, setRadius] = useState(300000);

    useEffect(() => {
        async function getIP() {
            try {
                const response = await fetch('https://api.ipify.org?format=json');
                const json = await response.json();
                setIP(json.ip);
            }
            catch (e: any) {
                console.log('failed');
            }
        }

        getIP();
    }, []);

    useEffect(() => {
        async function setLatLngFromPostcode(postcode: string) {
            const response = await fetch(`https://api.postcodes.io/postcodes/${postcode}`)
            if (response.status === 200) {
                const json = await response.json();
                const {result: {latitude, longitude}} = json;
                //console.log('lat lon from postcode: ', latitude, longitude);
                if (latitude && longitude) {
                    setLatLng([latitude, longitude]);
                }
            }
        }

        async function setLatLngFromIP(iP: string) {
            const response = await fetch(`http://ip-api.com/json/${iP}`)
            const json = await response.json();
            const {lat, lon} = json;
            //console.log('lat lon from ip: ', lat, lon);
            if (lat && lon) {
                setLatLng([lat, lon]);
            }
        }

        if(postcode) {
            setLatLngFromPostcode(postcode);
        }
        else if(iP) {
            setLatLngFromIP(iP);
        }
    }, [iP, postcode]);

    return (
        <LocationContext.Provider value={{latLng, postcode, setPostcode, radius, setRadius}}>
            {children}
        </LocationContext.Provider>
    )
}
