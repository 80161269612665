import {Header} from "../../components/Header/Header";
import {Splash} from "../../components/Splash/Splash";
import {SearchJobCard as JobCard} from "../../components/JobCard/SearchJobCard";
import {Button} from "../../atoms/Button/Button";
import {Footer} from "../../components/Footer/Footer";
import {useCallback, useContext, useEffect, useState} from "react";
import {JobsContext} from "../../providers/JobsProvider";
import './Home.scss';
import {JobProvider} from "../../providers/JobProvider";
import {Configure, Hits, InstantSearch} from "react-instantsearch";
import {CustomSearchBox} from "../../components/Search/SearchBox";
import {SearchBar} from "../../organisms/SearchBar/SearchBar";
import {ResultsCount} from "../ResultsCount";
import {LocationContext} from "../../providers/LocationProvider";
import algoliasearch from "algoliasearch/lite";
import {SearchOptionsContext, SearchOptionsProvider} from "../../providers/SearchOptionsProvider";

type PropTypes = {
    numJobsDisplayed?: number
}

export function Home({numJobsDisplayed = 6}: PropTypes) {
    const {jobs, dispatch}: any = useContext(JobsContext);
    const {query, setQuery, searchClient}: any = useContext(SearchOptionsContext);

    const jobSlice = useCallback(() => {
        return jobs.slice(0, numJobsDisplayed);
    }, [jobs, numJobsDisplayed]);

    const loadMore = (num: number) => {
        numJobsDisplayed += num;
    }

    const {latLng, setPostcode, radius}: { radius: number, latLng: number[], setPostcode: any } = useContext(LocationContext)

    console.log('latLng', latLng);

    const pageSize = 52;

    const [numJobsToDisplay, setNumJobsToDisplay] = useState(pageSize);


    return (

        <div id={'home'} className={'relative flex flex-col'}>
            <InstantSearch searchClient={searchClient} indexName="hoot_jobs_search">
                <Configure hitsPerPage={numJobsToDisplay} />
            <header className={'relative max-sm:mb-[160px] max-sm:pb-[100px]'}>
                <Header color={'primary'} logoColor={'white'} />
                <Splash />
            </header>
            </InstantSearch>
            <InstantSearch searchClient={searchClient} indexName="hoot_jobs_search">
                <Configure query={query} hitsPerPage={numJobsToDisplay}
                           aroundLatLng={latLng?.join(', ')} aroundRadius={radius} filters={`application.closeDateTimestamp >= ${Date.now()}`} />
            <main>
                <h3 className={'recent-header mb-6 mt-8'}>Recent Jobs</h3>


                    <div className="cards">

                        <Hits hitComponent={JobCard}/>

                        <div className="view-more max-sm:mt-14 mt-12 flex w-full">

                               <div className={'max-w-full'}>
                                   <Button disabled={false} expandable={true} text=""
                                           clickHandler={() => setNumJobsToDisplay(numJobsToDisplay + pageSize)}>
                                                View More
                                   </Button>
                               </div>

                        </div>
                    </div>


            </main>
            </InstantSearch>
            <Footer/>
        </div>
    )
}
