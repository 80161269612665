import {Button} from "../../atoms/Button/Button";
import {JobEdit} from "../../components/JobEdit/JobEdit";
import {Job} from "../../types/job";
import React, {useContext, useEffect, useState} from "react";
import {JobsContext} from "../../providers/JobsProvider";
import imageLogo from "../../assets/images/logo-blue.svg"
import './EditPage.scss';

import DocumentIcon from "../../assets/icons/24/file-plus-blue.svg";
import SendIcon from "../../assets/icons/24/send-white.svg";
import {JobProvider} from "../../providers/JobProvider";
import {EditAside} from "../../components/EditAside/EditAside";
import {
    Button as AriaButton,
    Dialog,
    DialogTrigger,
    Heading,
    Input,
    Label,
    Modal,
    TextField
} from 'react-aria-components';
import {Configure, Hits, InstantSearch, SearchBox} from "react-instantsearch";
import {SearchJobCard as JobCard} from "../../components/JobCard/SearchJobCard";
import algoliasearch from "algoliasearch/lite";
import {JobHit} from "../../components/JobEdit/JobHit";
import {SearchOptionsContext} from "../../providers/SearchOptionsProvider";
import {AuthContext} from "../../providers/AuthProvider";
import {DynamicSvg} from "../../atoms/DynamicSVG/DynamicSVG";

export function EditPage({filter}:{filter?:string}) {
    const {jobs, dispatch}: any = useContext(JobsContext);
    const [menuCollapsed, setMenuCollapsed] = useState(false);
    const [checked, setChecked] = useState([] as number[]);
    const {searchClient}: any = useContext(SearchOptionsContext);
    //sconsole.log('searchClient', searchClient);

    const toggleMenuCollapse = () => {
        setMenuCollapsed(!menuCollapsed);
    }

    const selectAll = () => {
        const ids = jobs.map((job:any) => job.id);
        ids.forEach((id:number) => {
            if (!checked.includes(id)) {
                setChecked([...checked, id])
            }
        });
    }

    const unselectAll = () => {
        setChecked([]);
    }

    const [selectAllText, setSelectAllText] = useState('Select All');

    useEffect(() => {
        setSelectAllText(checked.length === jobs.length ? 'Unselect All' : 'Select All');
    }, [checked]);
    /*const selectAllText = () => {
        return checked.length === jobs.length ? 'Unselect All' : 'Select All';
    }*/

    const toggleSelected = (id: number) => {
        if (!checked.includes(id)) {
            setChecked([...checked, id]);
        }
        else {
            const index = checked.indexOf(id);
            checked.splice(index-1, 1);
        }
    }

    const removeJobs = async () => {
        localStorage.removeItem('jobs');
    }



    const pageSize = 60;

    const [numJobsToDisplay, setNumJobsToDisplay] = useState(pageSize);


    let [isOpen, setOpen] = useState(false);

    const {logout}: any = useContext(AuthContext);

    return (
        <div className={`edit-page page ${menuCollapsed?'menu-collapsed':''}`}>
            <div className="sidebar">
                <div className="top">
                    <div className="logo-holder">
                        <img src={imageLogo} alt="" className="logo" />
                    </div>
                    <span className="g">God Mode</span>
                </div>
                <ul className="top-menu">
                    <li><a href="/hoot-admin/editpage" className="web-scrape">Web Scrape</a></li>
                    <li><a href="/hoot-admin/editsaved" className="hoot-schools">Hoot Schools</a></li>
                    <li><a href="/hoot-admin/editpage" className="other-schools">Other Schools</a></li>
                    <li><a href="/hoot-admin/users" className="users">Users</a></li>
                </ul>
                <ul className="bottom-menu">
                <li><a className={'cursor-pointer collapse-btn'} onClick={toggleMenuCollapse}>Collapse</a></li>
                    <li><a className="logout cursor-pointer" onClick={()=>logout()}>Log-out</a></li>
                </ul>
            </div>
            <div className="main">
                <div className="top-header z-10">
                    {/*<div className="review xl w600"><span className="number">2700</span> Jobs to review</div>*/}
                    <div className="review xl w600">Edit Jobs</div>
                    <div className="ctas">
                        {/*<Button size="sm" color="blue" variant="tertiary" text="" clickHandler={()=>checked.length === jobs.length ? unselectAll(): selectAll()}>{ selectAllText }</Button>*/}
                        <Button size="sm" variant="outline" color="blue" text="" icon={DocumentIcon} clickHandler={() => setOpen(true)}>
                            <DynamicSvg name={'file-plus'} stroke={'#3475F5'} />
                            New Job
                        </Button>
                        {/*<Button size="small" onClick={onLogout} label="Log out" />*/}
                    </div>
                </div>
                <main>
                    <div className={'mr-4'}>
                        <JobProvider>
                            <Modal isOpen={isOpen}  onOpenChange={setOpen} isDismissable>
                                <Dialog>
                                    {({ close }) => (
                                        <div style={{backgroundColor: '#FFFFFF', width:'fit-content', height: 'fit-content', 'borderRadius':'16px'}} className={'relative'}>
                                            <EditAside />
                                            <AriaButton onPress={close} style={{background: 'none'}} className={'bg-none absolute right-2 top-2 text-body-24 flex gap-2 items-center close-button'}>
                                                <svg width="20" height="20" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M4 4L12 12M12 4L4 12" stroke="rgba(27, 31, 59, 0.8)" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                                </svg></AriaButton>
                                        </div>
                                    )}
                                </Dialog>
                            </Modal>
                        </JobProvider>
                        {/*{jobs.map((job:Job, index: any) =>
                        <JobProvider key={index} jobId={job.id}>
                            <JobEdit dirtied={dirtyJobs} selected={checked} onSelectToggled={toggleSelected} onJobDirtied={handleJobDirtied} onJobCleaned={handleJobCleaned} />
                        </JobProvider>
                    )}*/}
                        <InstantSearch searchClient={searchClient} indexName="hoot_jobs_search" future={{
                            preserveSharedStateOnUnmount: true
                        }} >
                            <Configure filters={filter??''} hitsPerPage={numJobsToDisplay}   />

                            <div className={'sticky top-[110px] z-10 bg-bg-emphasis py-[32px] translate-y-[-33px]'}>
                                <SearchBox placeholder={'Search for job to edit'} />
                            </div>


                            <div className={'translate-y-[-64px]'}>
                                <Hits hitComponent={JobHit} />
                            </div>
                            <div className="view-more">
                                <Button style={{width: '100%'}} disabled={false} expandable={true} text="" clickHandler={() => setNumJobsToDisplay(numJobsToDisplay + pageSize)}>View More</Button>
                            </div>
                        </InstantSearch>
                    </div>
                </main>
            </div>
        </div>
    )
}
