/*import {useCognito} from "../../../hooks/aws/cognito/useCognito";
import {HubUser} from "../../../models/HubUser";
import {useDateComposite} from "../../../hooks/useComposite";
import {Main} from "../../../layout/Main/Main";
import {PageHeader} from "../../../layout/PageHeader/PageHeader";
import {Fieldset} from "../../../layout/Fieldset/Fieldset";
import {HubTextField} from "../TextField/TextField";*/
import React, {forwardRef, ReactNode, useContext, useImperativeHandle} from "react";
//import DynamicSvgRenderer from "../../../hooks/useDynamicSVG/useDynamicSVG2";
import {Button, DropItem, DropZone, FileDropItem, FileTrigger} from "react-aria-components"
/*import {HubButton} from "../Button/Button";
import {ToastContext} from "../../../molecules/Toast/ToastProvider";*/

export interface FileUploadService {
    uploadMany(files: File[], uploadChunked?: boolean): void;
    uploadOne(file: File): void;
    uploadChunked(file: File): void;
}

export const formatFileExtension = (file: any) => {
    return file.name.split('.').pop();
}

const isValidated = (files: any[], allowedFileTypes = ['image/jpeg', 'image/png']) => {
    if (files.length > 1) {
        throw new Error('You can only upload one file at a time');
    }

    else {
        const file = files[0];

        console.log('file', file);
        console.log('allowed', allowedFileTypes);
        if (!allowedFileTypes.includes(file.type)) {
            throw new Error('The file type is not allowed');
        }

        if (file.size > 10000000) {
            throw new Error('The file size is too large');
        }

        return true;
    }
}

const readFileAsDataURL = async (file:any) => {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();

        reader.onload = function(event) {
            resolve(event?.target?.result);
        };

        reader.onerror = function(error) {
            reject(error);
        };

        reader.readAsDataURL(file);
    });
}

export const FileUploadTrigger = forwardRef(({uploadService, allowedFileTypes, children, allowsMultiple=false, onUrlGenerated, onFileRef}:
                                      {uploadService?: any, allowedFileTypes?: string[], children:ReactNode, allowsMultiple?: boolean, onUrlGenerated?:Function, onFileRef?:Function}, ref) => {

    let [files, setFiles] = React.useState(undefined as File[]|undefined);

    //const {addErrorToast} = useContext(ToastContext);

    const handleSelect = async (e: any) => {

        if (e) {
            let files: any[] = Array.from(e);
            try {
                if (isValidated(files, allowedFileTypes)) {
                    const fileArray: File[] = files;
                    /*for (let file of files) {
                        console.log('iterating files', files);
                        fileArray.push(await file.getFile());
                    }*/
                    setFiles(fileArray);
                    console.log('checking url generated', onUrlGenerated);
                    if (onUrlGenerated) {
                        console.log('onUrlGenerated active', files);
                        //const url = await readFileAsDataURL(files[0]);
                        //console.log((files[0] as any[])[0]);
                        const url = URL.createObjectURL((files[0]) as File);
                        onUrlGenerated(url);
                    }
                    if (onFileRef) {
                        onFileRef(files[0]);
                    }
                }
            }
            catch (e:any) {
                //addErrorToast && addErrorToast(e.message);
                console.log('error happened:', e);
            }
        }
    }

    useImperativeHandle(ref, () => ({
        triggerUpload() {
            if (files && uploadService) {
                uploadService.uploadMany(files);
            }
        }
    }));

    return (
        <FileTrigger allowsMultiple={allowsMultiple} onSelect={handleSelect} >
            {/*<HubButton flexWidth={false} text={'Choose photo'} leftIcon={'image-gallery'} />*/}
            {children}
        </FileTrigger>
    )
});

export const FileUpload = forwardRef(({uploadService, allowedFileTypes, children, allowsMultiple=false, includeTrigger=true, onFileRef, onUrlGenerated}:
                               {uploadService?: FileUploadService, allowedFileTypes?: string[], children:ReactNode, allowsMultiple?: boolean, includeTrigger?:
                                       boolean, onFileRef?:Function, onUrlGenerated?:Function}, ref) => {
    //const {addErrorToast} = useContext(ToastContext);

    let [files, setFiles] = React.useState([] as File[]|undefined);

    const handleDrop = async (e: any) => {
        let files = e.items.filter((file:DropItem) =>
            file.kind === 'file'
        ) as FileDropItem[];
        try {
            if (isValidated(files, allowedFileTypes)) {
                const fileArray: File[] = [];
                for (let file of files) {
                    fileArray.push(await file.getFile());
                }
                setFiles(fileArray);
                if (onUrlGenerated) {
                    const url = URL.createObjectURL((await files[0].getFile()) as File);
                    onUrlGenerated(url);
                    /*const url = await readFileAsDataURL(files[0]);
                    onUrlGenerated(url);*/
                }
                if (onFileRef) {
                    onFileRef(files[0]);
                }
            }
        }
        catch (e:any) {
            //addErrorToast && addErrorToast(e.message);
        }
    }

    useImperativeHandle(ref, () => ({
        logMessage(msg: string) {
            console.log(`message from FileUpload: ${msg}`);
        },
        triggerUpload() {
            if (files && uploadService) {
                uploadService.uploadMany(files);
            }
        }
    }));


    return (
        <>
            <DropZone onDrop={handleDrop}>
                {includeTrigger?
                    <FileUploadTrigger allowedFileTypes={allowedFileTypes} allowsMultiple={allowsMultiple} onFileRef={onFileRef}>
                        <Button className={'w-full'}>
                            {children}
                        </Button>
                    </FileUploadTrigger>
                    :
                    children
                }
            </DropZone>
        </>
    )
});
