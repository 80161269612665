import {JobCard} from "./JobCard";
import {Job} from "../../types/job";

export function SearchJobCard({hit: job}: {hit: any}) {
    /*if ((job as Job)?.application?.closeDate) {
        return null;
    }*/

    console.log('job', job);

    const date = (job as Job)?.application?.closeDate;
    if(!date || new Date(date) < new Date()) {
        //return <></>
    }

    return (
        <JobCard job={job} />
    )
}
