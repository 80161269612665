import {LandingSplash} from "../../organisms/LandingSplash/LandingSplash";
import {KeyFeatures} from "../../organisms/KeyFeatures/KeyFeatures";
import {TextFeature} from "../../organisms/TextFeature/TextFeature";
import {ImageFeature} from "../../organisms/ImageFeature/ImageFeature";
import {FAQs} from "../../organisms/FAQs/FAQs";
import {Header} from "../../components/Header/Header";
import DynamicImage from "../../components/DynamicImage";
import {Footer} from "../../components/Footer/Footer";
import {Configure, Hits, InstantSearch} from "react-instantsearch";
import {CustomSearchBox} from "../../components/Search/SearchBox";
import {SearchBar} from "../../organisms/SearchBar/SearchBar";
import {ResultsCount} from "../ResultsCount";
import {SearchJobCard as JobCard} from "../../components/JobCard/SearchJobCard";
import {Button} from "../../atoms/Button/Button";
import algoliasearch from "algoliasearch/lite";
import {useContext} from "react";
import {SearchOptionsContext} from "../../providers/SearchOptionsProvider";
import {ImageCarousel} from "../../components/ImageCarousel/ImageCarousel";
import {AutoSlider} from "../../components/AutoSlider/AutoSlider";

export function TeacherLanding() {
    const {query, setQuery, searchClient}: any = useContext(SearchOptionsContext);
    const numJobsToDisplay = 24;

    return (
        <div className={'flex flex-col gap-[42px]  overflow-scroll'}>
            <div className={'bg-yellow text-black relative max-xl:pb-[500px] max-lg:pb-[350px] max-md:pb-[250px] max-custom:pb-[400px] max-sm:pb-[400px]'}>
                <DynamicImage imagePath={'happy-teachers.png'} className={'absolute bottom-[-10px] right-0 z-10 h-[550px] max-custom:h-[350px]'}></DynamicImage>
                <DynamicImage imagePath={'Teachers-Hoot-Pattern.svg'} className={'absolute bottom-[-10px] right-[-100px] h-[100%] opacity-60'}></DynamicImage>
                <Header logoColor={'rgba(219, 157, 3, 1)'} color={'gold'}></Header>
                <LandingSplash></LandingSplash>
            </div>
            <InstantSearch searchClient={searchClient} indexName="hoot_jobs_search">
                <Configure  query={query} hitsPerPage={numJobsToDisplay}   />
                <AutoSlider speed={0} enabled={false} initialOffset={145} >
                    <div className={'flex gap-[300px] items-center'}>
                        <Hits hitComponent={JobCard} />
                    </div>
                </AutoSlider>
                <TextFeature emphasisColor={'primary'} title={'Live job board'} subtitle={<span>Browse <em>thousands of <br className={'max-sm:hidden'} /> jobs </em> every day</span>}></TextFeature>
                <AutoSlider speed={0} enabled={false} initialOffset={2960} >
                    <div className={'flex gap-[300px] items-center'}>
                        <Hits hitComponent={JobCard} />
                    </div>
                </AutoSlider>
            </InstantSearch>
            <ImageFeature
                imagePath={'Frame 2609939-3.png'} header={'Hoot CV'} subheader={'A beautiful CV that schools love!'}></ImageFeature>
            <ImageFeature
                copy={<p>We work with thousands of schools across the UK and abroad to bring you thousands of jobs each week.</p>}
                imagePath={'Frame 2609939_2.png'} header={'School Profiles'} subheader={'Find a school which shares your ethos'} reverse={true}></ImageFeature>
            <ImageFeature
                copy={<p>Be seen by your future employer by upgrading your application. Your application will appear first in their School Dashboard.</p>}
                imagePath={'Frame 2609939-2.png'} header={'Boosted Applications'} subheader={'Get to the top of the pile'}></ImageFeature>
            <ImageFeature
                copy={<p>Explore our evolving catalogue of interesting articles and courses written by the worlds most influential educational voices.</p>}
                imagePath={'Frame 2609939-2_2.png'} header={'Hoot Articles'} subheader={'Keep up-to-date with educational trends'} reverse={true}></ImageFeature>
            <KeyFeatures></KeyFeatures>
            <FAQs></FAQs>
            <Footer />
        </div>
    )
}
