import DynamicImage from "../../components/DynamicImage";


export function ImageFeature({reverse = false,
                                header = 'Hoot CV',
                                subheader = 'A beautiful CV that schools love!',
                                imagePath='logo.svg',
                                copy = (<p>Create your Hoot CV once and enjoy the benefits of quick applications.
                                    No more repetitive applications and emailing</p>),
                            }:
                                 {reverse?: boolean, header?: string, subheader?: string, imagePath?: string, copy?: string | JSX.Element}) {
    return (
        <div className={`max-md:text-center p-8 md:p-16 w-full max-md:flex-col-reverse flex justify-center gap-4 md:gap-[145px] w-[1312px] items-center
            ${reverse ? 'flex-row' : 'flex-row-reverse'}
        `}>
            <div className={'w-full md:w-[752px] rounded-corners'}>
                <DynamicImage className={'w-full md:w-[752px] rounded-corners-big-card'} imagePath={imagePath} />
            </div>
            <div className={'w-full md:w-[415px] flex flex-col gap-6'}>
                <div className={'flex flex-col gap-1'}>
                    <h3 className={'max-sm:text-center text-body-18'}>{header}</h3>
                    <h2 className={'max-sm:text-center text-body-24 sm:text-body-32'} >{subheader}</h2>
                </div>
                {copy}
            </div>
        </div>
    )
}
