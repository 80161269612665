import {parseDate} from "@internationalized/date";
import {
    Button, Calendar, CalendarCell,
    CalendarGrid,
    CalendarGridBody,
    CalendarGridHeader,
    CalendarHeaderCell,
    Heading, Text
} from "react-aria-components";
import {DynamicSvg} from "../../atoms/DynamicSVG/DynamicSVG";
import {DashboardSubscription} from "../../components/DashboardSubscription/DashboardSubscription";
import {DashboardStats} from "../../components/DashboardStats/DashboardStats";
import {DashboardCard} from "../../components/DashboardCard/DashboardCard";
import {ArticleCard} from "../../components/ArticleCard/ArticleCard";
import DynamicImage from "../../components/DynamicImage";

export function Dashboard() {
    return (
        <div className={"flex"}>
            <div className={"menu"}></div>
            <div className={"content max-w-[1654px] mx-auto"}>
                <div className={"flex pl-8 h-[208px] items-center bg-bg-emphasis justify-between w-full"}>
                    <header className={"flex flex-col "}>
                        <h1 className={"text-title-42"}>Welcome, Felixstowe School</h1>
                        <span className={"text-body-18"}>
                            Add, edit, manage and review your job posts and applicants
                        </span>
                    </header>
                    <DynamicImage imagePath={"hoot-gold.svg"}></DynamicImage>
                </div>
                <main className={"px-8 flex flex-col gap-[42px]"}>
                    <div className={"py-8"}>
                        <div className={"flex gap-8"}>
                            <div className={"flex w-[696px] border-[1px] border-solid border-border rounded-corners"}>
                                <div className={"flex flex-col w-[100px] gap-2 p-2 text-body-12"}>
                                    <a href=""
                                       className={"py-2 hover:bg-background rounded-corners hover:text-primary text-center"}>This
                                        Week</a>
                                    <a href=""
                                       className={"py-2 hover:bg-background rounded-corners hover:text-primary text-center"}>Last
                                        Week</a>
                                    <a href=""
                                       className={"py-2 hover:bg-background rounded-corners hover:text-primary text-center"}>This
                                        Month</a>
                                    <a href=""
                                       className={"py-2 hover:bg-background rounded-corners hover:text-primary text-center"}>This
                                        Year</a>
                                    <a href=""
                                       className={"py-2 hover:bg-background rounded-corners hover:text-primary text-center"}>Last
                                        Year</a>
                                    <a href=""
                                       className={"py-2 hover:bg-background rounded-corners hover:text-primary text-center"}>All
                                        Time</a>
                                </div>
                                <div className={"flex w-[696px] border-l-[1px] border-solid border-border"}>
                                    <Calendar className={'react-aria-Calendar text-primary flex flex-col w-full p-1'}>
                <span className={'flex h-[44px] flex items-center'}>
                    <Button slot="previous" className={'text-primary order-1 w-8'}>
                        <DynamicSvg name={"chevron-left"} size={"16"} stroke={"rgba(52, 117, 245, 1)"}/>
                    </Button>
                    <Heading className={'text-body-18 text-header pl-[10px] mr-auto'}/>
                    <Button slot="next" className={'text-primary w-8 order-2'}><DynamicSvg name={"chevron-right"}
                                                                                           size={"16"}
                                                                                           stroke={"rgba(52, 117, 245, 1)"}/></Button>
                </span>
                                        <div>
                                            <CalendarGrid className={'flex-grow-0 text-std-text'}
                                                          weekdayStyle={"short"}>
                                                <CalendarGridHeader>
                                                    {(day) =>
                                                        <CalendarHeaderCell>
                                                            {day.toString().substring(0, 2)}
                                                        </CalendarHeaderCell>
                                                    }
                                                </CalendarGridHeader>
                                                <CalendarGridBody>
                                                    {(date) => {
                                                        console.log(date.toString());
                                                        return (<CalendarCell date={date}>
                                                            <div className={"relative"}>
                                                                {date.toString().substring(8, 10)}
                                                                {["2023-03-16", "2023-03-17"].includes(date.toString()) &&
                                                                    <span
                                                                        className={"absolute h-2 w-2 block bg-third mx-auto rounded-full left-1/2 -translate-x-1/2"}></span>}
                                                            </div>
                                                        </CalendarCell>)
                                                    }
                                                    }
                                                </CalendarGridBody>
                                            </CalendarGrid>
                                        </div>
                                        <Text slot="errorMessage"/>
                                    </Calendar>
                                </div>
                            </div>
                            <div className={"flex flex-col gap-8 min-w-[862px]"}>
                                <DashboardSubscription/>
                                <div className={"flex gap-8"}>
                                    <DashboardStats></DashboardStats>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={"flex flex-col gap-6"}>
                        <header className={"flex justify-between items-center"}>
                            <h2 className={"text-body-28"}>My Job Posts</h2>
                            <a className={"text-primary"}>View all</a>
                        </header>
                        <div className={"grid gap-8 grid-cols-3"}>
                            <DashboardCard title={'Head of Drama'} />
                            <DashboardCard title={'Head of English'}/>
                            <DashboardCard title={'Teacher of Physics'}/>
                            <DashboardCard title={'PA to the Head'}/>
                            <DashboardCard title={'Teacher of Classics'}/>
                            <DashboardCard title={'Teacher of Maths'} date={new Date('2011-04-11T10:20:30Z')}/>
                        </div>
                    </div>
                    <div className={"flex gap-[42px]"}>
                        <div className={"flex w-1/2 flex-col gap-6"}>
                            <header className={"flex justify-between items-center"}>
                                <h2 className={"text-body-28"}>Featured Articles</h2>
                                <a className={"text-primary"}>View all</a>
                            </header>
                            <div className={"grid gap-8 grid-cols-2"}>
                                <ArticleCard imagePath={'a1.png'}
                                    title={'Building a Student-Centred School: Empowering Educators and Enhancing Education'} />
                                <ArticleCard imagePath={'a2.png'}
                                    title={'Fostering a Culture of Continuous Learning: Employee Development and Growth'} />
                                <ArticleCard imagePath={'a3.png'}
                                             title={'Fostering a Culture of Continuous Learning: Employee Development and Growth'} />
                                <ArticleCard imagePath={'a4.png'}
                                             title={'Building a Student-Centred School: Empowering Educators and Enhancing Education'} />
                            </div>
                        </div>
                        <div className={"flex w-1/2 flex-col gap-6"}>
                            <header className={"flex justify-between items-center"}>
                                <h2 className={"text-body-28"}>Learning Centre</h2>
                                <a className={"text-primary"}>View all</a>
                            </header>
                            <div className={"grid gap-8 grid-cols-2"}>
                                <ArticleCard type={'learning'} imagePath={'lc1.png'}
                                             title={'Fostering a Culture of Continuous Learning: Employee Development and Growth'} />
                                <ArticleCard type={'learning'} imagePath={'lc2.png'}
                                             title={'Nurturing Employee Engagement in Schools: Strategies for a Thriving Workforce'} />
                                <ArticleCard type={'learning'} imagePath={'lc3.png'}
                                             title={'Fostering a Culture of Continuous Learning: Employee Development and Growth'} />
                                <ArticleCard type={'learning'} imagePath={'lc4.png'}
                                             title={'Nurturing Employee Engagement in Schools: Strategies for a Thriving Workforce'} />
                            </div>
                        </div>
                    </div>
                </main>
            </div>
        </div>
    )
}
