import {KeyFeature} from "./KeyFeature/KeyFeature";
import {useState} from "react";
import './KeyFeatures.scss';

export function KeyFeatures({audience = 'teachers'}: {audience?: string}) {
    const [features, _setFeatures] = useState([1, 1, 1, 1, 1, 1, 1, 1, 1, 1]);

    return (
        <div className={'flex flex-col gap-16 items-center '}>
            <div className={'text-center'}>
                <h3 className={'text-primary text-center text-primary'}>Key features</h3>
                <h2 className={'text-center'}>See why {audience} like you <br/> are
                    <em className={'text-primary'}> joining</em> us</h2>
            </div>
            <div className="features-grid max-sm:px-8">
                {/*{features.map((feature, index) =>*/}
                    <KeyFeature icon={'users'} color={'rgba(19, 111, 99, 1)'} text={'Thousands of jobs added per-week.'} title={'Large School Network'} />
                    <KeyFeature icon={'bell'} color={'rgba(253, 57, 118, 1)'} text={'Receive notifications when jobs match your profile.'} title={'Instant Notifications'} />
                    <KeyFeature icon={'Teacher'} color={'black'} text={'Get to the top of the piles with our Hoot Boost feature.'} title={'Premium Options'} />
                    <KeyFeature icon={'Learn-center'} color={'rgba(0, 192, 255, 1)'} text={'Train your staff with our Hoot Courses written by experts.'} title={'Learning Platform'} />
                    <KeyFeature icon={'file-text'} color={'rgba(52, 117, 245, 1)'} text={'Our clean design aids your own recruitment'} title={'Hoot CV’s'} />
                    <KeyFeature icon={'table'} color={'rgba(219, 157, 3, 1)'} text={'Save, draft and submit applications in your dashboard.'} title={'Teacher Dashboard'} />

                {/*)}*/}
            </div>
        </div>
    )
}
