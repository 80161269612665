import './FAQ.scss';
import {DynamicSvg} from "../../../atoms/DynamicSVG/DynamicSVG";
import {useState} from "react";

export function FAQ({title='Is Hoot free to sign up?', text='Absolutely! HOOT is committed to providing a user-friendly platform that connects job seekers with exciting opportunities without any cost. Creating a profile on HOOT is completely free, and you can start browsing and applying for your next career move today!'}: {title: string, text: string}) {
    const [isOpen, setOpen] = useState(false);

    return (
        <div className={'flex flex-col bg-bg-emphasis p-5 gap-4 -big-card'}>
            <span className={'flex justify-between'}>
                <h3 className={'text-body-18'}>{title}</h3>
                <span className={'text-primary flex items-center'}>
                    <button onClick={()=>setOpen(!isOpen)}>
                        <>
                            {isOpen && <DynamicSvg name={'chevron-down'} stroke={'rgba(52, 117, 245, 1)'}/>}
                            {!isOpen && <DynamicSvg name={'chevron-up'} stroke={'rgba(52, 117, 245, 1)'}/>}
                        </>
                    </button>
                </span>
            </span>
            <p className={`${isOpen?'':'max-h-0 overflow-hidden'}`}>{text}</p>
        </div>
    )
}
