import {JobProvider} from "../../providers/JobProvider";
import {JobEdit} from "./JobEdit";

export const JobHit = ({hit: job}: {hit: any}) => {
    return (
        <JobProvider jobId={job.id}>
            <JobEdit hit={job} />
        </JobProvider>
    )
}
