import {ImageCarousel} from "../../components/ImageCarousel/ImageCarousel";
import {Search} from "../../components/Search/Search";
import './LandingSplash.scss';

export function LandingSplash({audience='teacher',
        header=(<>Find your next<br /> <em className={`${audience} `}>Education</em> Job!</>),
        copy=(<p>Our platform makes finding new education jobs a breeze.</p>),
        ctas=(<a href={'register'} className={'rounded-corners bg-gold w-full md:w-[205px] flex justify-center items-center text-white hover:text-white cursor-pointer'}>Create a Free Account</a>)
    }: {audience?: 'teacher'|'school', header?: JSX.Element, copy?: JSX.Element, ctas?: JSX.Element}) {

    const emColor = audience === 'teacher' ? 'text-gold' : 'text-primary';
    const backgroundColor = audience === 'teacher' ? 'bg-yellow' : 'bg-school-blue';

    return (
        <div className={`${audience} landing-splash max-sm:text-center`}>
            <div className={'p-8 md:p-16 flex flex-col gap-8'}>
                <div className={'flex flex-col gap-8'}>
                    <div className={'flex flex-col gap-4'}>
                        <h1 className={'text-title-32 font-bold'}>{header}</h1>
                        {copy}
                    </div>
                    <span className={'flex gap-8'}>
                    <div className={'flex flex-col'}>
                        <dt className={'text-body-28 text-gold font-bold'}>9,679</dt>
                        <dd>Eduction Jobs</dd>
                    </div>
                    <div className={'flex flex-col'}>
                        <dt className={'text-body-28 text-gold font-bold'}>365</dt>
                        <dd>Universities</dd>
                    </div>
                    <div className={'flex flex-col'}>
                        <dt className={'text-body-28 text-gold font-bold'}>1234</dt>
                        <dd>Schools</dd>
                    </div>
                </span>
                    <div className={'flex h-11 gap-4 z-20'}>
                        {ctas}
                    </div>
                </div>
                <ImageCarousel />
            </div>
        </div>
    )
}
