import moment from "moment";
import './JobDates.scss';
import clock from '../../assets/icons/16/clock.svg';

type PropTypes = {
    startDate?: Date | string,
    applyDate?: Date | string
}

export function JobDates({startDate, applyDate}: PropTypes) {
    const formatDate = (date: any) => {
        //console.log('date', date);
        let tryFormat = moment(new Date(date)).format("DD.MM.YY");;

        return tryFormat == "Invalid date" ? undefined : tryFormat;
    }

    const formattedStartDate = () => {
        return formatDate(startDate);
    };

    const formattedApplyDate = () => {
        return formatDate(applyDate);
    };

    return (
        <div className="time">
            {formattedApplyDate() && <div className="apply">
                <span className="xs label" style={{lineHeight: '17px', textAlign: 'center'}}>Apply by</span>
                <span className="sm value" style={{lineHeight: '21px'}}>
        <img src={clock} alt="" className="clock"/>
        <span>{formattedApplyDate()}</span>
      </span>
            </div>}
            {formattedStartDate() && <div className="start">
                <span className="xs label" style={{lineHeight: '17px'}}>Job Start</span>
                <span className="sm value" style={{lineHeight: '21px'}}>{formattedStartDate()}</span>
            </div>}
        </div>
    )
}
