import {Select} from "../../atoms/Select/Select";
import './SearchBar.scss';
import {RefinementList, useCurrentRefinements} from "react-instantsearch";
import {FacetDropdown} from "../../pages/FacetDropdown";
import {useContext, useEffect, useState} from "react";
import {LocationContext} from "../../providers/LocationProvider";
import {Input, Label, Slider, SliderOutput, SliderThumb, SliderTrack} from "react-aria-components";
import {SearchOptionsContext} from "../../providers/SearchOptionsProvider";
import {AriaSlider} from "../../atoms/AriaSlider/AriaSlider";

export function SearchBar() {
    const { items, refine } = useCurrentRefinements();
    const [location, setLocation] = useState(null as string | null);
    const [type, setType] = useState(null as string | null);
    const [status, setStatus] = useState(null as string | null);
    const {latLng, setPostcode, postcode, radius, setRadius}: { postcode:string, setRadius: any, radius: number, latLng: number[], setPostcode: any } = useContext(LocationContext);
    const {query, setQuery}: any = useContext(SearchOptionsContext);

    useEffect(() => {
        const locationItems = items.filter((item: any) => item.attribute === 'displayLocation');
        if (locationItems.length > 0) {
            setLocation(locationItems[0].refinements[0].label);
        }
        else {
            setLocation(null);
        }

        const statusItems = items.filter((item: any) => item.attribute === 'employer.information.displayFundingStatus');
        if (statusItems.length > 0) {
            setStatus(statusItems[0]?.refinements[0]?.label);
        }
        else {
            setStatus(null);
        }

        const typeItems = items.filter((item: any) => item.attribute === 'employer.location.type');
        if (typeItems.length > 0) {
            setType(typeItems[0]?.refinements[0]?.label);
        }
        else {
            setType(null);
        }
    }, [items]);

    const formatRadius = (radius: number) => {
        if (radius < 1000) {
            return radius + 'm';
        }
        return Math.floor(kmToMiles(radius / 1000)) + 'mi';
    }

    const kmToMiles = (km: number) => {
        return Math.floor(km * 0.621371);
    }

    return (
        <div className="search-bar">
            {/*<FacetDropdown closeOnChange={true} buttonText={type??'Type'}>
                <RefinementList  operator="and" attribute="employer.location.type" />
            </FacetDropdown>*/}
            <FacetDropdown badgeText={formatRadius(radius)} closeOnChange={false} buttonText={location??'Distance'}>
                <span className={'flex gap-2 p-5 pl-[.8rem] py-2'}>
                <AriaSlider
                    value={radius}
                    minValue={20000}
                    maxValue={600000}
                    onChange={setRadius}
                />
                </span>
                <RefinementList  operator="and" attribute="displayLocation" />
            </FacetDropdown>
            {/*<FacetDropdown closeOnChange={true} buttonText={status??'Funding Status'}>
                <RefinementList  operator="and" attribute="employer.information.displayFundingStatus" />
            </FacetDropdown>*/}
        </div>
    )
}
