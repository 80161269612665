import {useCallback, useContext, useEffect, useState} from "react";
import {DragUpload} from "../DragUpload/DragUpload";
import {Job} from "../../types/job";
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import moment from "moment";
import {JobContext} from "../../providers/JobProvider";
import {config} from "../../ckeditor/settings";
import './EditAside.scss';
import type {Key, TextFieldProps, ValidationResult} from 'react-aria-components';
import {Input, ListBox, ListBoxItem, Select, SelectValue, TextArea, TextField} from 'react-aria-components';
import {AriaDropzone, uuidv4} from "../../atoms/DropZone/DropZone";
import {Calendar, CalendarCell, CalendarGrid, CalendarGridBody, CalendarGridHeader, CalendarHeaderCell, DateInput, DatePicker, DateSegment, Dialog, FieldError, Group, Heading, Label, Popover, Text} from 'react-aria-components';
import {parseDate} from '@internationalized/date';
import {AriaDatePicker} from "../../atoms/AriaDatePicker/AriaDatePicker";
import {Button} from "../../atoms/Button/Button";
import {AriaListItem, AriaSelect} from "../../atoms/Select/AriaSelect";
import {DynamicSvg} from "../../atoms/DynamicSVG/DynamicSVG";

const editor = ClassicEditor;

export function EditAside({isNew = false, onClose}: {isNew?: boolean, onClose?: Function}) {
    const {advertAssign, applicationAssign, employerAssign, contactAssign, locationAssign, salaryAssign, job, deleteJob, saveJob, retrieveJob, setJobValue, setJobValues, headers, setHeaders, attachments, setAttachments, startDate, setStartDate, endDate, setEndDate}: any = useContext(JobContext);

    const tabs = ["Job Details", "Job Description", /*"School Description", */"Attachments", "Dates"];
    const [banner, setBanner] = useState<File | undefined>(undefined);
    const [isReadOnly, setIsReadOnly] = useState<boolean>(true);

    const deleteAttachment = (index:number) => {
        //console.log('deleting attachment', index);
        if (attachments) {
            const filesCopy = [...attachments];
            filesCopy.splice(index, 1);
            setAttachments([...filesCopy]);
        }
    }

    useEffect(() => {
        if (!job) {
            retrieveJob();
        }
        setIsReadOnly(job && job.source !== 'hoot');
    }, [job]);

    /*const saveJob = async () => {
        try {
            const files = banner? [banner, ...attachments]: attachments;
            await uploadFiles(files);
            const response = await fetch('http://localhost:3030/jobs', {
                method: "post",
                headers: {
                    'Content-Type': 'application/json'
                },

                //make sure to serialize your JSON body
                body: JSON.stringify({...job, date_updated: new Date().toString()})
            });
            //const response = await fetch(`https://api.hoot.works/jobs/${props.jobId}`);
            const json = await response.json();
            console.log(json);
        }
        catch (e) {
            console.log(e);
        }
    }*/

    const uploadFiles = async (files: File[]) => {
        try {
            await Promise.all(files.map(async (file) => {
                const url = await getS3UploadUrl(file.name, file.size, file.type);
                const response = await fetch(url, {
                    method: "put",
                    headers: {
                        'Content-Type': 'application/json'
                    },

                    //make sure to serialize your JSON body
                    body: JSON.stringify(job.banner)
                });
            }))
        }
        catch (e) {
            //console.log(e);
        }
    }

    const getS3UploadUrl = async (name: string, size: number, mimeType: string) => {
        try {
            const response = await fetch(`${process.env.REACT_APP_API}/jobs/upload`, {
                method: "post",
                headers: {
                    'Content-Type': 'application/json'
                },

                //make sure to serialize your JSON body
                body: JSON.stringify({
                    name,
                    size,
                    mimeType,
                })
            });
            //const response = await fetch(`https://api.hoot.works/jobs/${props.jobId}`);
            const json = await response.json();
            //console.log(json);
            return json.url;
        }
        catch (e) {
            //console.log(e);
        }
    }

    const truncatedName = (name: string) => {
        if (name.length > 20) {
            return name.substring(0, 20) + "...";
        }
        return name;
    }

    const getFileExtension = (name: string) => {
        return name?.split('.').pop()?.toUpperCase();
    }

    function capitalizeFirstLetter(string: string) {
        return string.charAt(0).toUpperCase() + string.slice(1);
    }

    const addAttachment = (payload: File[]) => {
        if (payload) {
            const att = {
                name: payload[0].name,
                size: payload[0].size.toString(),
                caption: payload[0].name?.split('.')[0],
                url: '',
                id: Math.random().toString(36).substr(2, 9),
                mimeType: payload[0].type
            };

            job.attachments?.push(att);
        }
    }

    const removeAttachment = (jobId: any) => {
        // Find the index of the item to remove
        const index = job.attachments?.findIndex((job:Job) => job.id === jobId);

        // If the item exists in the array, remove it
        if (index && index !== -1 && job.attachments) {
            job.attachments?.splice(index, 1);
        }
    }

    const [selectedSchool, setSelectedSchool] = useState(undefined as Key | undefined);

    const textInputOptions = {
        format: 'MMMM yyyy'
    };

    const getFileName = (attachment:any) => {
        //console.log('attachment name', attachment);
        const urlParts = attachment?.split('/');
        //console.log('attachment parts', urlParts);
        return urlParts?.pop()??attachment;
    }

    const setDate = (value:any, input:any) => {
        if (job.advert) {
            if (input === 'endDate') {
                job.advert.endDate = moment(new Date(value).toString()).format('YYYY-MM-DDTHH:mm:ss.000Z');
            } else if (input === 'startDate') {
                job.advert.startDate = new Date(value).toString();
            }
        }
    }

    const setAsHootJob = () => {
        console.log('setting as hoot job');
        setJobValues([{key:'source', value:'hoot'}, {key:'TesID', value:job?.id}, {key:'ID', value:uuidv4()}]);
    }

    let options = [
        {id: 1, name: 'Haberdashers\' Academies Trust South'},
    ];

    const [currentTab, setCurrentTab] = useState(0 as number);

    return (
        <aside className="edit-aside container flex flex-row pt-10 min-h-[850px]">
            <div className="nav flex flex-col w-[200px] gap-5 border-r-[1px] border-solid border-border">
                {tabs.map((tab, index) =>
                    <button
                        disabled={isReadOnly} onClick={() => setCurrentTab(index)}
                        className={`${isReadOnly?'opacity-30':''} flex gap-2 h-[50px] text-left menu-item ${currentTab === index?'current':''}`}  >
                        {index === 0 && <DynamicSvg name={'database'} stroke={'inherit'}/>}
                        {index === 1 && <DynamicSvg name={'book'} stroke={'inherit'}/>}
                        {index === 2 && <DynamicSvg name={'folder'} stroke={'inherit'}/>}
                        {index === 3 && <DynamicSvg name={'calendar'} stroke={'inherit'}/>}
                        <span>{ tab }</span>
                    </button>
                )}
            </div>
            <div className={'flex flex-col gap-4 w-[800px] relative'}>
                <div className={'flex justify-between'}>
                    <h4 className={'text-primary'}>
                        {job?.id && job?.employer?.name && <><span className="primary">{job?.employer?.name}</span> - {job?.employer?.location?.description}</>}
                        {!job?.employer?.name && <>New Job</>}
                    </h4>

                        <div className="cta">
                            {isReadOnly &&<button className={'primary flex gap-2 items-center cta-button'} onClick={setAsHootJob}>
                                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M9.33464 1.33398H4.0013C3.64768 1.33398 3.30854 1.47446 3.05849 1.72451C2.80844 1.97456 2.66797 2.3137 2.66797 2.66732V13.334C2.66797 13.6876 2.80844 14.0267 3.05849 14.2768C3.30854 14.5268 3.64768 14.6673 4.0013 14.6673H12.0013C12.3549 14.6673 12.6941 14.5268 12.9441 14.2768C13.1942 14.0267 13.3346 13.6876 13.3346 13.334V5.33398M9.33464 1.33398V5.33398H13.3346M9.33464 1.33398L13.3346 5.33398M8.0013 12.0007V8.00065M6.0013 10.0007H10.0013" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                </svg>
                                Set as Hoot Job</button>}
                            {<button style={{color: 'white'}} className={'bg-error text-white flex gap-2 items-center cta-button'} onClick={() => {
                                deleteJob();
                                onClose && onClose();
                            }}>Delete</button>}
                            {!isReadOnly && <button className={'primary flex gap-2 items-center cta-button'} onClick={() => {
                                saveJob();
                                onClose && onClose();
                            }}>Save</button>}

                        </div>


                </div>
                {job &&
                <>
                    {currentTab===0 && <div>
                        <div className="apply-details">
                            <div className="apply-detail">
                                {isNew && <AriaSelect selectedKey={selectedSchool} onSelectionChange={setSelectedSchool} placeholder={'Select a school'} isDisabled={isReadOnly}
                                             label="Select School" items={options}>
                                    {(item) => <AriaListItem>{item.name}</AriaListItem>}
                                </AriaSelect>}
                            </div>
                            <div className="apply-detail">
                                <TextField  onChange={(val) => setJobValue('displayLocation', val)} value={job.displayLocation} className={'flex flex-col'}>
                                    <Label>{'Display Location'}</Label>
                                    <div className={'flex'}>
                                        <Input readOnly={isReadOnly} className={'h-12 flex-shrink-0'}/>
                                    </div>
                                </TextField>
                            </div>
                            <div className="apply-detail">
                                <TextField  onChange={(val) => setJobValue('shortDescription', val)} value={job.shortDescription} className={'flex flex-col'}>
                                    <Label>{'Short Description'}</Label>
                                    <div className={'flex'}>
                                        <TextArea  readOnly={isReadOnly} className={'w-full p-4 h-[100px] border-solid border-border border-[1px] focus:outline-0 focus:border-primary active:border-primary rounded-corners h-12 flex-shrink-0'}/>
                                    </div>
                                </TextField>
                            </div>
                            <div className="apply-detail">
                                <TextField  onChange={(val) => setJobValue('canonicalUrl', val)} value={job.canonicalUrl} className={'flex flex-col'}>
                                    <Label>{'Url'}</Label>
                                    <div className={'flex'}>
                                        <Input readOnly={isReadOnly} className={'h-12 flex-shrink-0'}/>
                                    </div>
                                </TextField>
                            </div>
                            <div className="apply-detail">
                                <TextField  onChange={(val) => setJobValue('title', val)} value={job.title} className={'flex flex-col'}>
                                    <Label>{'Job Title'}</Label>
                                    <div className={'flex'}>
                                        <Input readOnly={isReadOnly} className={'h-12 flex-shrink-0'}/>
                                    </div>
                                </TextField>
                            </div>
                            <div className="apply-detail">
                                <TextField {...salaryAssign.useField('description')} className={'flex flex-col'}>
                                    <Label>{'Salary'}</Label>
                                    <div className={'flex'}>
                                        <Input readOnly={isReadOnly} className={'h-12 flex-shrink-0'}/>
                                    </div>
                                </TextField>
                            </div>
                            {/*<div className="apply-detail">
                                <TextField  onChange={(val) => setJobValue('displayContractTypes', val)} value={job.displayContractTypes} className={'flex flex-col'}>
                                    <Label>{'Contract Type'}</Label>
                                    <div className={'flex'}>
                                        <Input readOnly={isReadOnly} className={'h-12 flex-shrink-0'}/>
                                    </div>
                                </TextField>
                            </div>
                            <div className="apply-detail">
                                <TextField  onChange={(val) => setJobValue('displayContractTerms', val)} value={job.displayContractTerms} className={'flex flex-col'}>
                                    <Label>{'Contract Terms'}</Label>
                                    <div className={'flex'}>
                                        <Input readOnly={isReadOnly} className={'h-12 flex-shrink-0'}/>
                                    </div>
                                </TextField>
                            </div>*/}
                            <div className="apply-detail">
                                <div className="label">
                                    Banner: {/*{headers?'banner'+headers:'No banner'}*/}
                                </div>
                                <div className="value w600" style={{display: 'flex', alignItems: 'center'}}>
                                    {!isReadOnly && <AriaDropzone files={headers} setFiles={setHeaders} previewImages={true} />}
                                    {isReadOnly && headers && headers.length > 0 &&  <img src={job?.employer?.images?.header} />}
                                </div>
                            </div>
                        </div>
                    </div>}
                    {currentTab===1 && <div>
                        <div className="apply-details">
                            <div className="apply-detail">
                                <div className="label">
                                    Job Description:
                                </div>
                                <div className="value w600" style={{overflow: 'auto', height: '600px'}}>
                                    {/*<ckeditor editor="editor"  v-model="job.description_0" :config="config" ></ckeditor>*/}
                                    <CKEditor
                                        disabled={isReadOnly}
                                        editor={ editor }
                                        data={job.description}
                                        config={config}
                                        /*onReady={ editor => {
                                            // You can store the "editor" and use when it is needed.
                                            console.log( 'Editor is ready to use!', editor );
                                        } }*/
                                        onChange={ ( event, editor ) => {
                                            const data = editor.getData();
                                            //console.log( { event, editor, data } );
                                            setJobValue('description', data);
                                        } }
                                        /*onBlur={ ( event, editor ) => {
                                            console.log( 'Blur.', editor );
                                        } }
                                        onFocus={ ( event, editor ) => {
                                            console.log( 'Focus.', editor );
                                        } }*/
                                    />
                                </div>
                            </div>
                        </div>
                    </div>}
                    {currentTab===200 && <div>
                        <div className="apply-details">
                            <div className="apply-detail" style={{overflow: 'auto'}}>
                                <div className="label">
                                    School Description:
                                </div>
                                <div className="value w600" style={{height:'600px'}}>
                                    <CKEditor
                                        disabled={isReadOnly}
                                        editor={ editor }
                                        data={employerAssign.useField('description').value}
                                        config={config}
                                        /*onReady={ editor => {
                                            // You can store the "editor" and use when it is needed.
                                            console.log( 'Editor is ready to use!', editor );
                                        } }*/
                                        onChange={ ( event, editor ) => {
                                            const data = editor.getData();
                                            //console.log( { event, editor, data } );
                                            employerAssign.useField('description').setValue(data);
                                        } }
                                        /*onBlur={ ( event, editor ) => {
                                            console.log( 'Blur.', editor );
                                        } }
                                        onFocus={ ( event, editor ) => {
                                            console.log( 'Focus.', editor );
                                        } }*/
                                    />
                                </div>
                            </div>
                        </div>
                    </div>}
                    {currentTab===2 && <div v-if="tab===3">
                        <div style={{display: 'flex', minHeight: '600px'}} className={'flex-col gap-8'}>
                            <div>
                                <div className="apply-details">
                                    <div className="apply-detail">
                                        <div className="label">
                                            Attachments:
                                        </div>
                                        <div className="value w600">
                                            <AriaDropzone mode={'append'} files={attachments} setFiles={setAttachments} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div style={{flex:1}}>
                                <div className="attachments-grid flex-wrap" style={{ display: 'flex', gap: '12px', flexWrap: 'wrap'}}>
                                    {attachments?.map((attachment:any, index:number) =>
                                        <div className="attachment">
                                            <span className="name">{ capitalizeFirstLetter(truncatedName(attachment.caption??'')) }</span>
                                            <span>{ getFileExtension(attachment.mimeType?.split('/').pop()) }</span>
                                            <button onClick={()=>deleteAttachment(index)} style={{backgroundColor: 'transparent'}} >
                                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M5 6V20C5 20.5304 5.21071 21.0391 5.58579 21.4142C5.96086 21.7893 6.46957 22 7 22H17C17.5304 22 18.0391 21.7893 18.4142 21.4142C18.7893 21.0391 19 20.5304 19 20V6H5ZM5 6H3M5 6H21M8 6V4C8 3.46957 8.21071 2.96086 8.58579 2.58579C8.96086 2.21071 9.46957 2 10 2H14C14.5304 2 15.0391 2.21071 15.4142 2.58579C15.7893 2.96086 16 3.46957 16 4V6" stroke="rgb(253, 57, 118)" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                                </svg>

                                            </button>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>}
                    {currentTab===3 && <div v-if="tab===4">
                        <div style={{display: 'flex', justifyContent: 'space-between'}} className={'gap-8'}>
                            <div className="part-width" style={{width:'400px', height: '400px', display: 'flex', flexDirection: 'column', gap:'16px'}}>
                                <div className="label">Application End Date</div>
                                {/*<VueDatePicker :model-value="job.advert.endDate" :enable-time-picker="false" @update:model-value="(value:any) => setDate(value, 'endDate')" ></VueDatePicker>*/}
                                <AriaDatePicker date={endDate} setDate={setEndDate}></AriaDatePicker>
                            </div>
                            <div className="part-width" style={{width:'400px', height: '400px', display: 'flex', flexDirection:'column', gap:'16px'}} >
                                <div className="label">Job Start Date</div>
                                {/*<VueDatePicker v-model="job.displayJobStartDate" :enable-time-picker="false" :text-input="textInputOptions"   ></VueDatePicker>*/}
                                <AriaDatePicker date={startDate} setDate={setStartDate}></AriaDatePicker>
                            </div>
                        </div>
                    </div>}
                </>
                }
            </div>
        </aside>
    )
}
