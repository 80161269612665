import {ContentLayout} from "./Layout";

export const Terms = () => {
    return (
        <ContentLayout title={"Terms and Conditions "}
                       subtitle={"Effective Date: [Date]"}>
            <section>
                <header>
                    <h3>Introduction</h3>
                    <p>
                        HOOT is a dedicated teacher recruitment platform serving the educational community in
                        the United Kingdom. Our website www.hoot.org.uk is designed to connect schools with
                        potential teaching candidates, facilitating a streamlined recruitment process. By
                        accessing and using Hoot, you agree to be bound by these Terms and Conditions, which
                        govern your use of our services and our website.
                    </p>
                </header>
                <p>
                    HOOT provides a unique platform where schools can post job advertisements and manage
                    their recruitment process through a bespoke Human Resources (HR) dashboard. Teachers can
                    use our platform to search for job opportunities, save potential job listings to their
                    profiles, and access detailed job descriptions and application packs.
                </p>
                <p>
                    These Terms and Conditions apply to all users of the site, including teachers seeking
                    employment and schools (or other educational institutions) posting job listings. It is
                    important that you read and understand these terms before using our services. By creating
                    an account and using our platform, you signify your agreement to these Terms and Conditions.
                    If you do not agree to these terms, you should not use the Hoot platform.
                </p>
                <p>
                    Please be aware that these Terms and Conditions may be updated or amended from time to time.
                    Your continued use of the platform after any such changes constitutes your acceptance of the
                    new Terms and Conditions. We recommend that you periodically review this page for any updates.
                </p>
                <p>
                    Your privacy and the security of your data are important to us. Please refer to our Privacy
                    Policy ([insert link to Privacy Policy]) for information on how we collect, use, and protect
                    your personal data.
                </p>
                <p>
                    Should you have any questions or concerns regarding these Terms and Conditions, please contact
                    us at hello@hoot.org.uk
                </p>
            </section>

            <section>
                <header>
                    <h3>Definitions</h3>
                    <p>
                        In these Terms and Conditions, the following terms shall have the meanings set out below:
                    </p>
                </header>
                <p>
                    "HOOT": Refers to the online teacher recruitment platform, including all associated services and
                    features.
                </p>
                <p>
                    "User": Any individual or entity that accesses or uses the HOOT platform. This includes both teachers
                    seeking employment and educational institutions seeking to recruit teaching staff.
                </p>
                <p>
                    "Teacher": An individual who uses HOOT to search for teaching job opportunities, download application
                    packs, and apply for positions.
                </p>
                <p>
                    "School": An educational institution, including but not limited to schools, colleges, and universities,
                    that uses HOOT to post job advertisements and manage recruitment processes.
                </p>
                <p>
                    "Job Advertisement": A listing posted on the HOOT platform, detailing an open teaching position, including
                    requirements, responsibilities, and application information.
                </p>
                <p>
                    ‘Single Job Advertisement’: A listing which has been individually paid for by the School and will run until
                    the scheduled ‘apply by’ date.
                </p>
                <p>
                    "HR Dashboard": A feature of the Hoot platform provided to Schools, allowing them to manage job postings,
                    view and rate applicants, and handle various aspects of the recruitment process.
                </p>
                <p>
                    "Account": The registered profile created by a User on the HOOT platform, through which they can access
                    services and features offered by Hoot.
                </p>
                <p>
                    "Application Pack": The collection of documents and information provided by a School in relation to a Job
                    Advertisement, which may include job descriptions, application forms, and other relevant materials.
                </p>
                <p>
                    "Content": All text, graphics, images, audio, video, software, data compilations, and any other form of
                    information capable of being stored in a computer that appears on, or forms part of, the HOOT platform.
                </p>
                <p>
                    "Platform" refers to the online teacher recruitment platform provided by HOOT, including its website, mobile
                    applications, and any related services offered by HOOT.
                </p>
                <p>
                    "Personal Data": Any information relating to an identified or identifiable natural person (‘data subject’);
                    an identifiable natural person is one who can be identified, directly or indirectly, in particular by reference
                    to an identifier such as a name, an identification number, location data, or an online identifier.
                </p>
            </section>

            <section>
                <header>
                    <h3>Use of the Platform</h3>
                    <h4>Creation and Maintenance of User Accounts</h4>
                </header>
                <ol>
                    <li>
                        <h4>Registration</h4>
                        <p>To access certain features of HOOT, both Teachers and Schools are required to create a User Account.
                            Registration involves providing accurate and current information as prompted during the sign-up
                            process, including but not limited to name, contact details, and professional information. Users are
                            responsible for maintaining the accuracy of this information.
                        </p>
                    </li>
                    <li>
                        <h4>Account Security</h4>
                        <p>Users are responsible for maintaining the confidentiality of their account credentials, including
                            usernames and passwords. Any activity under a User's account is deemed to have been conducted by the User.
                            In case of any unauthorised use of an account or breach of security, Users must immediately notify HOOT.
                            You are responsible for safeguarding your account, such as using a strong password and keeping all sensitive
                            data secure. We cannot and will not be liable for any loss or damage arising from your failure to comply with the above.
                        </p>
                    </li>
                    <li>
                        <h4>Account Types</h4>
                        <ul>
                            <li>Teacher Accounts: Created by individual teachers for the purpose of job searching, application submission, and accessing application packs.</li>
                            <li>School Accounts: Created by educational institutions for posting job advertisements, accessing the HR Dashboard, and managing recruitment processes.</li>
                        </ul>
                    </li>
                </ol>
            </section>

            <section>
                <header>
                    <h3>Job Advertisements</h3>
                </header>
                <ol>
                    <li>
                        <h4>Use of Account</h4>
                        <p>
                            Users agree to use their HOOT accounts in a manner consistent with all applicable laws and regulations and in line with the intended purpose of the
                            platform. HOOT reserves the right to terminate or suspend accounts without forewarning for deceptive or unlawful purposes.
                        </p>
                    </li>
                    <li>
                        <h4>Content and Interactions</h4>
                        <p>
                            Users are responsible for the content they post and their interactions with other Users on the platform. HOOT does not endorse any User content and expressly disclaims any liability in relation to user-generated content.
                        </p>
                    </li>
                    <li>
                        <h4>Compliance with Guidelines</h4>
                        <p>
                            Users must adhere to any guidelines or policies provided by HOOT regarding account usage, including but not limited to, guidelines related to job posting, application submissions, and communication on the platform.
                        </p>
                    </li>
                </ol>
            </section>

            <section>
                <header>
                    <h3>Account Termination</h3>
                </header>
                <ol>
                    <li>
                        <h4>Voluntary Termination</h4>
                        <p>
                            Users may choose to delete their accounts at any time by following the account termination
                            procedure outlined on the HOOT platform in the ‘Profile’ area of your account. Alternatively,
                            users can email <a href={'mailto:hello@hoot.org.uk'}>hello@hoot.org.uk</a> with a deletion
                            request. All associated data will be permanently deleted.
                        </p>
                    </li>
                    <li>
                        <h4>Involuntary Termination</h4>
                        <p>
                            HOOT reserves the right to suspend or terminate a User's account if they are found to be in
                            violation of these Terms and Conditions, any applicable laws or regulations, or for any other
                            reason deemed necessary by HOOT to protect its interests and those of its Users.
                        </p>
                    </li>
                    <li>
                        <h4>Effect of Termination</h4>
                        <p>
                            Upon termination, the User's right to use the platform will cease immediately, and, where
                            applicable, their content may be removed from the platform. Termination of the account does not
                            affect the User's obligations under these Terms and Conditions, including any liabilities incurred
                            prior to termination.
                        </p>
                    </li>
                </ol>
            </section>

            <section>
                <header>
                    <h3>Use of the Platform</h3>
                </header>
                <ol>
                    <li>
                        <h4>General Guidelines</h4>
                        <p>Users are expected to use the HOOT platform in a manner consistent with its intended
                            professional purpose – facilitating the recruitment of teachers and the posting of
                            educational job opportunities. Any use of the platform for unauthorised purposes, including
                            but not limited to commercial advertising, spamming, or harassment, is strictly prohibited.
                        </p>
                    </li>
                    <li>
                        <h4>Content Standards</h4>
                        <p>All content posted by Users, whether in job advertisements, profiles, or communications, must be accurate, comply with applicable law, and adhere to professional standards. Content that is defamatory, obscene, offensive, or infringes on intellectual property rights or privacy rights is prohibited.
                        </p>
                    </li>
                    <li>
                        <h4>Interactions and Communications</h4>
                        <p>Users must conduct themselves professionally and respectfully in all interactions on the platform. HOOT promotes a harassment-free environment and expects all Users to contribute to maintaining such a climate.
                        </p>
                    </li>
                </ol>
            </section>

            <section>
                <header>
                    <h3>Restrictions on Use</h3>
                </header>
                <ol>
                    <li>
                        <h4>Prohibited Activities</h4>
                        <p>Users are prohibited from engaging in activities that:</p>
                        <ul>
                            <li>Violate any applicable laws or regulations.</li>
                            <li>
                                Infringe upon the rights of others, including privacy and intellectual property rights.
                            </li>
                            <li>
                                Involve the dissemination of viruses, malicious code, or other harmful technology.
                            </li>
                            <li>
                                Attempt to gain unauthorised access to the platform, other User accounts, or systems and
                                networks connected to HOOT.
                            </li>
                            <li>
                                Engage in or promote hate speech, discrimination, or harassment based
                            </li>
                            <li>
                                Promote or engage in any illegal or fraudulent activities, including but not limited to
                                phishing, scamming, identity theft, and financial fraud.
                            </li>
                        </ul>
                    </li>
                    <li>
                        <h4>Limitation on Content and Services</h4>
                        <p>Users may not:</p>
                        <ul>
                            <li>Copy, modify, distribute, sell, or lease any part of HOOT’s services or included
                                software.
                            </li>
                            <li>Reverse engineer or attempt to extract the source code of the software, unless laws
                                prohibit these restrictions or Users have HOOT's written consent.
                            </li>
                        </ul>
                    </li>
                    <li>
                        <h4>Reporting Misuse</h4>
                        <p>
                            Users are encouraged to report any misuse of the platform, including violations of these
                            guidelines, to HOOT’s support team. HOOT is committed to taking appropriate actions to
                            enforce these Terms and Conditions.
                        </p>
                    </li>
                    <li>
                        <h4>Response to Violations</h4>
                        <p>
                            HOOT reserves the right to investigate and take appropriate legal action against anyone
                            who, in HOOT’s sole discretion, violates this provision, including removing the offending
                            content from the platform, suspending or terminating the account of such violators, and
                            reporting them to law enforcement authorities.
                        </p>
                    </li>
                </ol>
            </section>

            <section>
                <header>
                    <h3>Job Listings</h3>
                </header>
                <ol>
                    <li>
                        <h4>Live Listings</h4>
                        <p>
                            HOOT job listings are live and dynamic, meaning they may change or be updated at any time
                            without prior notice. While we strive to maintain accurate and up-to-date information, we
                            cannot guarantee the accuracy, availability or permanence of any job listing on the
                            Platform. All Users must take full responsibility for doing their own research and background
                            checks to validate the information presented on the HOOT platform.
                        </p>
                    </li>
                    <li>
                        <h4>Screening</h4>
                        <p>
                            HOOT provides a platform for job listings in the education sector. While we always strive
                            to maintain the integrity of our platform, we do not screen or vet applicants for listed
                            positions. It is the sole responsibility of the institutions posting job listings ("School
                            Users") to conduct all necessary screening, legal checks, and verification of qualifications
                            of applicants in accordance with applicable laws and regulations.
                        </p>
                    </li>
                    <li>
                        <h4>Verification</h4>
                        <p>
                            HOOT does not verify the accuracy or truthfulness of information provided by applicants or
                            School Users. School Users must exercise due diligence and discretion when evaluating
                            applicants and making hiring decisions.
                        </p>
                    </li>
                    <li>
                        <h4>Release of Liability</h4>
                        <p>
                            By using the Platform, School Users acknowledge and agree that HOOT is not responsible for
                            any damages, losses, or liabilities arising from the hiring process, including but not
                            limited to the suitability, qualifications, or conduct (historic or future) of applicants.
                            School Users assume all risks associated with the use of the Platform and hiring decisions
                            made based on information obtained through the Platform.
                        </p>
                    </li>
                </ol>
            </section>

            <section>
                <header>
                    <h3>Posting Job Advertisements</h3>
                </header>
                <ol>
                    <li>
                        <h4>Accuracy of Information</h4>
                        <p>
                            Schools must ensure that all information included in job advertisements is accurate,
                            complete, and up-to-date. This includes details about the position, qualifications
                            required, application deadlines, and any other relevant information.
                        </p>
                    </li>
                    <li>
                        <h4>Compliance with Laws</h4>
                        <p>
                            All job advertisements must comply with relevant employment and anti-discrimination
                            laws applicable in the United Kingdom (or place of institution). Schools are responsible
                            for ensuring that their advertisements do not unlawfully discriminate on grounds such
                        </p>
                    </li>
                    <li>
                        <h4>Payments</h4>
                        <p>
                            Prices for single job advertisements and subscription plans are listed on the Platform
                            and are subject to change. Payments on the Platform are processed securely through
                            third-party payment processors. By making a payment, you agree to abide by the terms and
                            conditions of the respective payment processor.
                        </p>
                    </li>
                    <li>
                        <h4>Clear and Professional Language</h4>
                        <p>
                            Advertisements should be written in clear, professional language. Any use of offensive or
                            inappropriate language is strictly prohibited and may result in the termination of a User’s
                            account.
                        </p>
                    </li>
                    <li>
                        <h4>Approval of Job Advertisements</h4>
                        <p>
                            All job advertisements submitted to the HOOT platform must undergo an approval process
                            before they appear live on the site. This measure is in place to ensure that each advert
                            meets our standards for formatting, completeness, and anti-discrimination legislation.
                        </p>
                    </li>
                </ol>
            </section>

            <section>
                <header>
                    <h3>Responsibilities of Schools</h3>
                </header>
                <ol>
                    <li>
                        <h4>Responsiveness</h4>
                        <p>
                            Schools are expected to manage responses to their advertisements promptly and professionally.
                            This includes communicating with applicants, providing feedback, and updating the status of job
                            openings.
                        </p>
                    </li>
                    <li>
                        <h4>Confidentiality of Applications</h4>
                        <p>
                            Schools must treat all applications and candidate information with confidentiality and respect.
                            Personal data obtained through the recruitment process should be handled in compliance with GDPR
                            and other relevant data protection laws.
                        </p>
                    </li>
                    <li>
                        <h4>Selection and Recruitment Process</h4>
                        <p>
                            Schools are responsible for their own selection and recruitment processes. HOOT does not
                            participate in or influence these processes, nor does it guarantee the suitability of candidates.
                            The school is fully responsible for checking and validating all applicants to ensure they are
                            suitable to work in your institution.
                        </p>
                    </li>
                </ol>
            </section>

            <section>
                <header>
                    Management through HR Dashboard
                </header>
                <ol>
                    <li>
                        <h4>Scheduling Adverts</h4>
                        <p>
                            Schools can schedule job adverts using the HR Dashboard, allowing for effective planning and timing
                            of their recruitment campaigns.
                        </p>
                    </li>
                    <li>
                        <h4>Rating Applicants</h4>
                        <p>
                            The HR Dashboard provides tools for schools to rate and manage applicants. These tools are to be used
                            fairly and in accordance with the platform's guidelines and applicable laws.
                        </p>
                    </li>
                    <li>
                        <h4>Modification and Removal</h4>
                        <p>
                            Schools have the ability to modify or remove their job advertisements at any time. However, any changes
                            should maintain the accuracy and compliance of the advert.
                        </p>
                    </li>
                </ol>
            </section>

            <section>
                <header>
                    <h3>Data Protection and GDPR Compliance</h3>
                </header>
                <ol>
                    <li>
                        <h4>Data Collection and Processing</h4>
                        <p>
                            HOOT is committed to protecting the privacy and personal data of its users in accordance with the
                            General Data Protection Regulation (GDPR) and other applicable data protection laws. We collect and
                            process personal data only for the purposes outlined in our Privacy Policy, which includes facilitating
                            the recruitment process, improving our services, and complying with legal obligations.
                        </p>
                    </li>
                    <li>
                        <h4>User Consent</h4>
                        <p>
                            By using the HOOT platform, users consent to the collection, processing, and sharing of their personal
                            data as described in our Privacy Policy. Users have the right to withdraw their consent at any time by
                            contacting us or adjusting their account settings, although this may affect their ability to use certain
                            features of the platform.
                        </p>
                    </li>
                    <li>
                        <h4>Data Security</h4>
                        <p>
                            We implement appropriate technical and organisational measures to safeguard the security of users' personal
                            data and prevent unauthorised access, disclosure, alteration, or destruction. However, users should be aware
                            that no method of transmission over the internet or electronic storage is 100% secure, and we cannot guarantee
                            absolute security.
                        </p>
                    </li>
                    <li>
                        <h4>User Rights</h4>
                        <p>
                            Users have certain rights regarding their personal data under the GDPR, including the right to access, rectify,
                            or erase their data, the right to restrict or object to processing, and the right to data portability. Users can
                            exercise these rights by contacting us or using the relevant features provided on the platform.
                        </p>
                    </li>
                    <li>
                        <h4>Third-party Processors</h4>
                        <p>
                            We may engage third-party service providers to assist with data processing activities, such as hosting, analytics,
                            and payment processing. These service providers are contractually obligated to comply with applicable data protection
                            laws and ensure the security and confidentiality of users' personal data.
                        </p>
                    </li>
                    <li>
                        <h4>International Data Transfers</h4>
                        <p>
                            As a global platform, user data may be transferred to, stored in, or processed in countries outside the European
                            Economic Area (EEA) where data protection laws may differ. In such cases, we will ensure that appropriate safeguards
                            are in place to protect users' rights and interests, such as through the use of standard contractual clauses or relying
                            on the recipient's Privacy Shield certification.
                        </p>
                    </li>
                    <li>
                        <h4>Data Retention</h4>
                        <p>
                            We retain users' personal data only for as long as necessary to fulfil the purposes for which it was collected, unless
                            a longer retention period is required or permitted by law. Upon expiry of the retention period, we will securely delete
                            or anonymise the data in accordance with our data retention policies.
                        </p>
                    </li>
                    <li>
                        <h4>Contact Information</h4>
                        <p>
                            If users have any questions, concerns, or requests regarding the handling of their personal data or our data protection
                            practices, they can contact us at <a href={'mailto:hello@hoot.org.uk'}>hello@hoot.org.uk</a>
                        </p>
                    </li>
                </ol>
            </section>

            <section>
                <header>
                    <h3>Changes to Terms and Conditions</h3>
                    <p>
                        HOOT reserves the right to modify these Terms and Conditions at any time. Changes will be
                        effective immediately upon posting on the Platform. Your continued use of the Platform after
                        the posting of changes constitutes your acceptance of such changes.
                    </p>
                </header>
            </section>

            {/*
            <ol>
            <li>
                    <header>
                        <h3>1. Acceptance of Terms</h3>
                        <p>By accessing or using our Website, you affirm that you are at least 18 years old and capable
                            of entering into legally binding agreements. If you are using the Website on behalf of an
                            organization, you represent and warrant that you have the authority to bind that
                            organization to these Terms.</p>
                    </header>
                </li>
                <li>
                    <h3>2. Use of the Website</h3>
                    <header className={'subheader'}>
                        <h4>a) User Account</h4>
                        <p>To access certain features of the Website, you may be required to create a user account.
                            You agree to provide accurate, current, and complete information during the registration
                            process and to update such information to keep it accurate, current, and complete.</p>
                    </header>
                    <div className={'header-ul'}>
                        <header className={'subheader'}>
                            <h4>b) User Conduct</h4>
                            <p>You agree to use the Website in compliance with all applicable laws and regulations.
                                You also agree not to engage in any of the following prohibited activities:</p>
                            <ul>
                                <li>Use the Website for any illegal purpose or in violation of any applicable laws or
                                    regulations.
                                </li>
                                <li>Impersonate any person or entity or falsely state or otherwise misrepresent your
                                    affiliation with a person or entity.
                                </li>
                                <li>Interfere with or disrupt the Website or servers or networks connected to the
                                    Website.
                                </li>
                                <li>Attempt to gain unauthorized access to the Website or its related systems or
                                    networks.
                                </li>
                                <li>Upload or transmit viruses or other harmful code.</li>
                                <li>Harass, threaten, or otherwise violate the rights of others</li>
                                <li>Collect or store personal information about other users without their consent.</li>
                            </ul>
                        </header>
                    </div>
                    <header className={'subheader'}>
                        <h4>c) Termination:</h4>
                        <p>We reserve the right to suspend or terminate your access to the Website at our sole
                            discretion,
                            with or without notice, for any reason, including, but not limited to, your violation of
                            these Terms.</p>
                    </header>
                </li>
                <li>
                    <header>
                        <h3>3. Privacy</h3>
                        <p>Your use of the Website is also governed by our Privacy Policy, which can be found here.
                            By using the Website, you consent to the practices described in the Privacy Policy.</p>
                    </header>
                </li>
                <li>
                    <div>
                        <header>
                            <h3>4. Intellectual Property</h3>
                            <p>Your use of the Website is also governed by our Privacy Policy, which can be found here.
                                By using the Website, you consent to the practices described in the Privacy Policy.</p>
                        </header>
                        <header className={'subheader'}>
                            <h4>a. Content Ownership: </h4>
                            <p>ll content on the Website, including but not limited to text, graphics, logos, images,
                                and software, is owned or licensed by Hoot and is protected by copyright, trademark, and
                                other intellectual property laws.</p>
                        </header>
                        <header className={'subheader'}>
                            <h4>b. Limited License: </h4>
                            <p>Subject to these Terms, Hoot grants you a limited, non-exclusive, non-transferable, and
                                revocable license to access and use the Website for your personal and non-commercial use.</p>
                        </header>
                        <header className={'subheader'}>
                            <h4>c) Prohibited Use: </h4>
                            <p>You may not reproduce, distribute, modify, or create derivative works of the Website or its
                                content without our express written consent.</p>
                        </header>
                    </div>
                </li>
                <li>
                    <header>
                        <h3>5. Disclaimer of Warranties</h3>
                        <p>The Website is provided on an "as-is" and "as-available" basis. Hoot makes no warranties or
                            representations about the accuracy or completeness of the Website's content or the
                            suitability
                            of the Website for any particular purpose. Your use of the Website is at your own risk.</p>
                    </header>
                </li>
                <li>
                    <header>
                        <h3>6. Limitation of Liability</h3>
                        <p>To the extent permitted by law, Hoot shall not be liable for any indirect, incidental, special,
                            consequential, or punitive damages, or any loss of profits or revenues, whether incurred directly or
                            indirectly, or any loss of data, use, goodwill, or other intangible losses resulting from (a) your use
                            or inability to use the Website; (b) any unauthorized access to or use of our servers and/or any personal
                            information stored therein; (c) any errors or omissions in the content or functionality of the Website; or
                            (d) any other matter related to the Website.</p>
                    </header>
                </li>
                <li>
                    <header>
                        <h3>7. Indemnification</h3>
                        <p>You agree to indemnify and hold Hoot, its affiliates, officers, agents, employees, and partners harmless from any
                            claim or demand, including reasonable attorneys' fees, made by any third party due to or arising out of your use
                            of the Website, your violation of these Terms, or your violation of any rights of another person or entity.</p>
                    </header>
                </li>
                <li>
                    <header>
                        <h3>8. Changes to the Terms</h3>
                        <p>Hoot reserves the right to update or modify these Terms at any time without prior notice. Your continued use of the
                            Website after any such changes constitutes your acceptance of the updated Terms. It is your responsibility to review
                            these Terms regularly.</p>
                    </header>
                </li>
                <li>
                    <header>
                        <h3>9. Governing Law</h3>
                        <p>These Terms shall be governed by and construed in accordance with the laws of [Jurisdiction], without regard to its
                            conflict of law principles.</p>
                    </header>
                </li>
                <li>
                    <header>
                        <h3>10. Contact Us</h3>
                        <p>If you have any questions or concerns regarding these Terms, please contact us.</p>
                    </header>
                </li>
            </ol>

            <section>
                <h3>Thank you for choosing Hoot for your teacher recruitment needs.</h3>
            </section>*/}

        </ContentLayout>
    )
}
