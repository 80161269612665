import {ContentLayout} from "./Layout";

export const Privacy = () => {
    return (
        <ContentLayout title={"Privacy Policy"}
                       subtitle={"Effective: 2024"}>

            <section>
                <header>
                    <h3>Introduction</h3>
                    <p>Welcome to HOOT, the dedicated teacher recruitment platform serving the educational
                        community in the United Kingdom. Protecting your privacy and securing your personal
                        data are of paramount importance to us. This Privacy Policy explains how Hoot,
                        accessible at www.hoot.org.uk, collects, uses, discloses, and safeguards your
                        information when you visit our website or use our services.</p>
                </header>
            </section>

            <section>
                <header>
                    <h3>Information We Collect</h3>
                    <p>We collect information that identifies, relates to, describes, is reasonably capable
                        of being associated with, or could reasonably be linked, directly or indirectly, with
                        a particular consumer or device (“personal information”). In particular, our platform
                        has collected the following categories of personal information from its users:</p>
                </header>
            </section>

            <section>
                <header>
                    <h3>Personal identifiers </h3>
                    <p>Your name, email address, phone number, and any other contact details you provide when
                        creating an account, applying for a job, or subscribing to our services.</p>
                </header>
            </section>

            <section>
                <header>
                    <h3>Professional or employment-related information</h3>
                    <p>Including your CV, job preferences, employment history, qualifications, and any other
                        information you provide as part of the job application process.</p>
                    <p>Education information: that is not publicly available personally identifiable information
                        as defined in the Family Educational Rights and Privacy Act (FERPA).</p>
                    <p>Internet or other similar network activity: such as browsing history, search history,
                        information on a consumer’s interaction with our website, application, or advertisements.</p>
                </header>
            </section>

            <section>
                <header>
                    <h3>How We Use Your Information</h3>
                    <p>We use your personal information for various purposes, including to:</p>
                </header>
                <ul>
                    <li>
                        Provide, operate, and maintain our platform
                    </li>
                    <li>
                        Improve, personalise, and expand our platform
                    </li>
                    <li>
                        Understand and analyse how you use our platform
                    </li>
                    <li>
                        Develop new products, services, features, and functionality
                    </li>
                    <li>
                        Communicate with you, either directly or through one of our partners,
                            including for customer service, to provide you with updates and other
                            information relating to the website, and for marketing and promotional purposes
                    </li>
                    <li>
                        Send you emails
                    </li>
                    <li>
                        Find and prevent fraud
                    </li>
                </ul>
            </section>

            <section>
                <header>
                    <h3>Sharing Your Information</h3>
                    <p>We may share your information with third parties in the following situations:</p>
                </header>
                <ol>
                    <li>
                        <h4>With Service Providers</h4>
                        <p>We may share your personal information with service providers that perform
                            services for us or on our behalf, such as payment processing, data analysis,
                            email delivery, hosting services, customer service, and marketing assistance.</p>
                    </li>
                    <li>
                        <h4>For Legal Reasons</h4>
                        <p>We may share information as required by law or in response to legal process or
                            lawful requests, including from law enforcement and government agencies.</p>
                    </li>
                    <li>
                        <h4>Merger or Acquisition</h4>
                        <p>In the event that we undergo a merger, acquisition, bankruptcy, dissolution,
                            reorganisation, or similar transaction or proceeding that involves the transfer
                            of the information described in this Policy.</p>
                    </li>
                </ol>
            </section>

            <section>
                <header>
                    <h3>How We Protect Your Information</h3>
                    <p>We implement a variety of security measures to maintain the safety of your personal information,
                        including:</p>
                </header>
                <ul>
                    <li>
                        <p>Secure Socket Layer (SSL) technology to encrypt any information you submit to us via our
                            website</p>
                    </li>
                    <li>
                        <p>Secure storage and processing of your data in accordance with best practices</p>
                    </li>
                    <li>
                        <p>Regular malware scanning and system security checks</p>
                    </li>
                </ul>
            </section>

            <section>
                <header>
                    <h3>Your Data Protection Rights</h3>
                    <p>You have certain rights regarding the personal information we hold about you. These
                        may include the rights to access, correct, delete, or restrict the use of your
                        personal information, as well as the right to object to certain uses of your
                        information, the right to data portability, and the right to withdraw consent for
                        processing where consent forms the basis for processing.</p>
                </header>
            </section>

            <section>
                <header>
                    <h3>Changes to This Privacy Policy</h3>
                    <p>We may update our Privacy Policy from time to time. We will notify you of any changes
                        by posting the new Privacy Policy on this page and updating the “Last updated” date
                        at the top of this Privacy Policy.</p>
                </header>
            </section>

            <section>
                <header>
                    <h3>Contact Us</h3>
                    <p>If you have any questions or concerns about this Privacy Policy or our practices
                        with regard to your personal information, please contact us at hello@hoot.org.uk</p>
                    <p>By using our site, you acknowledge that you have read and understand our Privacy Policy.</p>
                </header>
            </section>

            {/*<section>
                <header>
                    <h3>Personal Information</h3>
                    <p>When you visit our Website, we may collect the following types of
                        personal information:<br/>
                        Contact Information: This includes your name, email address, phone
                        number, and postal address.<br/>
                        Professional Information: We may collect information related to your
                        teaching qualifications, experience, certifications, and other details
                        relevant to teacher recruitment.</p>
                </header>
                <ol>
                    <li>
                        <h4>Contact Information:</h4>
                        <p>This includes your name, email address, phone number, and postal
                            address.</p>
                    </li>
                    <li>
                        <h4>Professional Information:</h4>
                        <p>We may collect information related to your teaching qualifications, experience,
                            certifications,
                            and other details relevant to teacher recruitment.</p>
                    </li>
                </ol>
            </section>

            <section>
                <header>
                    <h3>Usage Data</h3>
                    <p>We may also collect non-personal information about your interaction with our Website. This may
                        include:</p>
                </header>
                <header className={'subheader'}>
                    <h4>Log Data:</h4>
                    <p>This includes your name, email address, phone number, and postal address.</p>
                </header>
                <header className={'subheader'}>
                    <h4>Cookies and Similar Technologies:</h4>
                    <p>We use cookies and other tracking technologies to collect information about your browsing
                        patterns and preferences. You can control cookies through your browser settings.</p>
                </header>
                <div className={"headed-ol"}>
                    <h4>How We Use Your Information</h4>
                    <ol>
                        <li>

                                <h4>Recruitment:</h4>
                                <p>To match you with potential teaching positions and facilitate communication between
                                    you
                                    and prospective employers.</p>

                        </li>
                        <li>
                            <h4>Improvement:</h4>
                            <p>To improve our Website, services, and user experience.</p>
                        </li>
                        <li>
                            <h4>Communication:</h4>
                            <p>We may engage third-party service providers to assist with various aspects of our
                                business, such as website hosting, analytics, and marketing. These service providers may have
                                access to your personal information as necessary to perform their functions.</p>
                        </li>
                        <li>
                            <h4>Legal Compliance:</h4>
                            <p>To comply with legal obligations and protect our rights.</p>
                        </li>
                    </ol>
                </div>
            </section>

            <section>
                <header>
                    <h3>Disclosure of Your Information</h3>
                    <p>We may share your personal information with the following entities:</p>
                </header>
                <header className={'subheader'}>
                    <h4>Prospective Employers:</h4>
                    <p>Your information may be shared with educational institutions and employers seeking qualified
                        teachers
                        for recruitment purposes.</p>
                </header>
                <header className={'subheader'}>
                    <h4>Service Providers: </h4>
                    <p>We may engage third-party service providers to assist with various aspects of our business, such
                        as
                        website hosting, analytics, and marketing. These service providers may have access to your
                        personal
                        information as necessary to perform their functions.</p>
                </header>
                <header className={'subheader'}>
                    <h4>Legal Requirements: </h4>
                    <p>We may disclose your information to comply with legal obligations, such as responding to a
                        subpoena
                        or government request, or to protect our rights and interests.</p>
                </header>
            </section>

            <section>
                <header>
                    <h3>Data Security</h3>
                    <p>We take reasonable measures to protect your personal information from unauthorized access,
                        disclosure,
                        alteration, or destruction. However, please be aware that no method of data transmission over
                        the
                        Internet or electronic storage is completely secure, and we cannot guarantee absolute
                        security.</p>
                </header>
                <header className={'headed-ol'}>
                    <h4>Your Choices </h4>
                    <p>You have the following rights regarding your personal information:</p>
                    <ol>
                        <li>
                            <h4>Access and Correction: </h4>
                            <p>You can access and update your personal information by contacting us through the contact
                                information provided below.</p>
                        </li>
                        <li>
                            <h4>Opt-Out:</h4>
                            <p>You can opt-out of receiving promotional communications from us by following the
                                unsubscribe
                                instructions in our emails or by contacting us.</p>
                        </li>
                        <li>
                            <h4>Cookies:</h4>
                            <p>You can manage your cookie preferences through your browser settings.</p>
                        </li>
                    </ol>
                </header>
            </section>

            <section>
                <header>
                    <h3>Changes to this Privacy Policy</h3>
                    <p>We may update this Privacy Policy from time to time to reflect changes in our practices or for
                        other
                        operational, legal, or regulatory reasons. We will post the updated Privacy Policy on our
                        Website,
                        and
                        the revised policy will take effect when it is posted </p>
                </header>
            </section>

            <section>
                <header>
                    <h3>Contact Us</h3>
                    <p>If you have any questions, concerns, or requests regarding this Privacy Policy or the way we
                        handle
                        your
                        personal information, please contact us.</p>
                </header>
            </section>

            <section>
                <p>By using our Website, you agree to the terms and conditions outlined in this Privacy Policy. We
                    encourage you to review this policy regularly to stay informed about how we are protecting your
                    information.</p>
            </section>

            <section>
                <h3>Thank you for choosing Hoot for your teacher recruitment needs.</h3>
            </section>*/}

        </ContentLayout>
    )
}
