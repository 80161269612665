import './AttachmentsGrid.scss';

const truncatedName = (name: string) => {
    if (name.length > 20) {
        return name.substring(0, 20) + "...";
    }
    return name;
}

const getFileExtension = (name: string) => {
    return name;//name.split('.').pop()?.toUpperCase();
}

function capitalizeFirstLetter(string: string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
}

type PropTypes = {
    attachments: any[]
}

const parseDocLink = (url: string) => {
    const links = url.split('cloudfront.net/');
    if (links.length > 2) {
        const [first, ...rest] = links;
        return rest.join('cloudfront.net/');
    }
    return url;
}

export function AttachmentsGrid({attachments}: PropTypes) {
  return (
      <div className="attachments-grid">
          {attachments && attachments.map((attachment, index) => {
                return (
                    <a className="attachment" key={index} href={parseDocLink(attachment?.url)}>
                        <span className="name">{ capitalizeFirstLetter(truncatedName(attachment?.caption)) }</span>
                        <span>{ getFileExtension(attachment?.name) }</span>
                    </a>
                )
          })}
      </div>
  )
}
