import {RegisterForm} from "../../organisms/Register/RegisterForm";
import image from  '../../assets/images/hoot-peeps.png';
import logo from  '../../assets/images/logo.svg';
import {DynamicSvg} from "../../atoms/DynamicSVG/DynamicSVG";
import {Header} from "../../components/Header/Header";

export function Register() {
    return (
        <div className={'relative'}>
            <div className={'absolute w-full'}  >
                <Header displayCtas={false} />
            </div>
            <div className={'flex items-center justify-center sm:h-[100vh] px-8 md:px-16'}>
                <div className={'flex items-center'}>
                    <div className={'flex justify-between max-sm:mt-[100px] gap-[20px] sm:gap-[147px] max-sm:flex-col flex-row-reverse'}>
                        <div className={'rounded-huge max-sm:h-[181px] overflow-hidden'}>
                            <img src={image} alt="" className={'relative top-[-80px]'} />
                        </div>
                        <div className={'flex flex-col gap-6'}>
                        <RegisterForm/>
                            <span className={'opacity-50 text-body-14'}>© 2023 HOOT. All rights reserved.</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
