import {Children, createContext, useCallback, useContext, useEffect, useReducer, useState} from "react";
import {JobsContext} from "./JobsProvider";
import {Job} from "../types/job";
import {saveToS3} from "../utils/aws/s3";
import {AuthContext} from "./AuthProvider";
import {uuidv4} from "../atoms/DropZone/DropZone";
import {useAssign} from "../hooks/useAssign";

export const JobContext: any = createContext(null);

type JobProviderProps = {
    children: any,
    jobId?: number | string,
    immediateLoad?: boolean
}

export function JobProvider(props:JobProviderProps) {
    const {jobs, dispatch}: any = useContext(JobsContext);

    const employer = {
        "location": {
            "id": "a4zw0000000GnzwAAC",
            "version": null,
            "name": "Lewisham",
            "code": "GB-LEW",
            "description": "Lewisham",
            "level": 5,
            "type": "Borough",
            "continent": "Europe",
            "continentRegion": "Northern Europe",
            "country": "United Kingdom",
            "adminLevel1": "England",
            "adminLevel2": "London",
            "adminLevel3": "Lewisham",
            "adminLevel4": null,
            "latitude": 51.442325,
            "longitude": -0.017315,
            "includeInEu": false,
            "internalRegionLevel": "UK South",
            "timezone": "Europe/London"
        },
        "information": {
            "numberOfPupils": null,
            "denomination": null,
            "gender": null,
            "funding": {
                "type": null,
                "group": null
            },
            "ageRange": {
                "to": null,
                "from": null
            },
            "phase": null,
            "organisation": {
                "group": "MAT Group",
                "type": "English Secondary Schools"
            },
            "customer": {
                "group": "Registered Charity"
            }
        },
        "onlineId": 1060287,
        "name": "Haberdashers' Academies Trust South",
        "assets": {
            "_id": "57592b55bb2fdf26e0c8d2ed",
            "id": "bdba93e6-0dd7-469e-a7fd-dd666a98139c",
            "photos": {
                "items": []
            },
            "schoolId": "1060287",
            "brandAssets": {
                "images": {
                    "header": {
                        "variants": {
                            "desktop": {
                                "source": "https://l.imgt.es/employer-assets/employers/1060287/brandimages/20210304_1009AM_desktop_1060287_header.jpg?profile=job-header-large",
                                "metadata": {
                                    "widthStr": "",
                                    "aspectRatioPerc": "0"
                                }
                            }
                        }
                    },
                    "footer": {
                        "variants": {},
                        "name": "footer",
                        "class": "CompositImage",
                        "id": "DZLmb_gKOp"
                    },
                    "logo": {
                        "variants": {
                            "desktop": {
                                "class": "File",
                                "id": "r1W1GNAMu",
                                "name": "1060287_logo.jpg",
                                "source": "https://l.imgt.es/employer-assets/employers/1060287/brandimages/20210304_1009AM_desktop_1060287_logo.jpg?profile=employer-logo",
                                "metadata": {
                                    "size": 23324,
                                    "createdAt": "2021-03-04T10:09:28.947Z",
                                    "width": 120,
                                    "height": 60
                                }
                            }
                        },
                        "name": "logo",
                        "class": "CompositImage",
                        "id": "2T79rTUCwx",
                        "error": null
                    }
                },
                "colors": {
                    "color1": {
                        "value": "#0e2f6f",
                        "name": "colour1",
                        "id": "LxLdflsQ03"
                    },
                    "color2": {
                        "name": "colour2",
                        "id": "7UoEJL7Xzg"
                    }
                }
            },
            "awards": {
                "items": []
            },
            "hideDescription": false,
            "generationTime": "2015-11-01 21:08:09Z",
            "links": {
                "items": []
            },
            "attachments": {
                "items": []
            },
            "videos": {
                "items": []
            },
            "employerOnlineId": "1060287",
            "hasVideo": false
        },
        "contact": {
            "dialingCode": "44",
            "email": "habstrust@haaf.org.uk",
            "website": "https://www.habsfed.org.uk/",
            "longitude": -0.04132747650146,
            "latitude": 51.47408602118451,
            "country": "United Kingdom",
            "postCode": "SE14 5NY",
            "stateCounty": "London",
            "cityTown": "Lewisham",
            "addressLine3": "",
            "addressLine2": "",
            "addressLine1": "Jerningham Road",
            "campusFaculty": "c/o Hatcham College",
            "department": "",
            "fax": null,
            "telephone": "020 7652 9516",
            "timezone": "Europe/London",
            "displayTelephone": "+44 20 7652 9516",
            "telephoneUrl": "+442076529516"
        },
        "employerPageCanonicalUrl": "https://www.tes.com/jobs/employer/haberdashers-academies-trust-south-1060287",
        "tesUrl": "/jobs/employer/haberdashers--academies-trust-south-1060287",
        "logoUrl": "https://l.imgt.es/employer-assets/employers/1060287/brandimages/20210304_1009AM_desktop_1060287_logo.jpg?profile=employer-logo",
        "headerUrl": "https://l.imgt.es/employer-assets/employers/1060287/brandimages/20210304_1009AM_desktop_1060287_header.jpg?profile=job-header-large",
        "photoUrls": [],
        "displayAddress": "Jerningham Road, Lewisham, London, SE14 5NY, United Kingdom"
    };

    const [job, setJob] = useState(undefined as Job | undefined);
    const [canSave, setCanSave] = useState(false);
    const [originalJob, setOriginalJob] = useState(undefined as Job | undefined);
    const [headers, setHeaders] = useState<any[]>([]);
    const [attachments, setAttachments] = useState<any[]>([]);
    const [startDate, setStartDate] = useState(new Date().toString());
    const [endDate, setEndDate] = useState(new Date().toString());
    const {accessToken}: any = useContext(AuthContext);

    const advertAssign = useAssign(job?.advert??{});
    const applicationAssign = useAssign(job?.application??{});
    const employerAssign = useAssign(job?.employer??{});
    const contactAssign = useAssign(job?.contact??{});
    const locationAssign = useAssign(job?.location??{});
    const salaryAssign = useAssign(job?.salary??{});


    useEffect(() => {
        //if(props.immediateLoad) {
            advertAssign.overwriteWithValue(job?.advert??{});
            applicationAssign.overwriteWithValue(job?.application??{});
            employerAssign.overwriteWithValue(job?.employer??{});
            contactAssign.overwriteWithValue(job?.contact??{});
            locationAssign.overwriteWithValue(job?.location??{});
            salaryAssign.overwriteWithValue(job?.salary??{});
        //}
    }, [job]);


    useEffect(() => {
        if(props.immediateLoad) {
            retrieveJob();
        }
    }, []);

    useEffect(() => {
        console.log('headers', headers);
    }, [headers]);

    useEffect(() => {
        console.log('job', job);
    }, [job]);

    function addDays(date: Date, days: number) {
        var result = new Date(date);
        result.setDate(result.getDate() + days);
        return result;
    }

    const deleteJob = async(ids: any[]) => {
        try {
            console.log('ids', ids);
            const body = JSON.stringify(ids);
            console.log('body', body);
            const response = await fetch(`${process.env.REACT_APP_API}/api/dynamo/jobs`, {
                method: "put",
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': localStorage.getItem('accessToken') || '',
                },
                body: JSON.stringify({
                    keyData: {ID: job?.ID},
                    updateData: {isDeleted: 'true'}
                }),
            });
            const results = await response.json();
            if (results) {
                setJobValue('isDeleted', 'true');
            }
        }
        catch {
            console.log('Logged out');
        }
    }


    const saveJob = async () => {

        let savedAttachments = [];
        let savedHeader = '';

        //const savedHeaders = await saveToS3(headers);
        if (attachments.length > 0) {

            savedAttachments = await saveToS3(attachments, undefined, `${job?.ID}/attachments`);
            console.log('saved headers', savedAttachments);

            const savedHeaders = await saveToS3(headers, 'header', job?.ID);
            console.log('saved headers', savedHeaders[0]);

            savedHeader = savedHeaders[0].url;

            setHeaders(savedHeaders);
            setAttachments(savedAttachments);
        }
        else {
            //console.log('no files to save');
        }

        const jobToSave = Object.assign({}, job);



        if (!job?.employer) {
            jobToSave.employer = {...employerAssign.value, ...employer};

            jobToSave.date_updated = new Date(startDate.toString()).getTime();
            jobToSave._geoloc = {
                lat: employer.location.latitude,
                lng: employer.location.longitude
            };
            jobToSave.advert = {
                startDate: new Date().toISOString(),
                endDate: addDays(new Date(), 21).toISOString()
            }

            //jobToSave.headerUrl = savedHeaders[0].url;
        }

        if (jobToSave.employer) {
            jobToSave.employer.headerUrl = savedHeader;
        }


        jobToSave.displayJobStartDate = startDate;
        jobToSave.application = {closeDate: endDate};
        jobToSave.attachments = savedAttachments.map((attachment) => {
            const {isNewFile, file, ...rest} = attachment;
            return rest;
        });

        console.log('job to save', jobToSave);

        let  {ID, ...rest} = jobToSave;
        let modifiedJobToSave = {ID:ID?.toString(), ...rest, id:ID?.toString()};


        if (job) {
            //console.log('accessToken', accessToken);
            const response = await fetch(`${process.env.REACT_APP_API}/api/dynamo/jobs`, {
                method: "post",
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': accessToken,
                },

                //make sure to serialize your JSON body
                body: JSON.stringify(modifiedJobToSave)
            });
            //const response = await fetch(`https://api.hoot.works/jobs/${props.jobId}`);
            const json = await response.json();
            //console.log(json);
        }

    };

    function randomNumber(min:number, max:number) {
        return Math.floor(Math.random() * (max - min) + min);
    }

    const retrieveJob = async () => {
        console.log('My PROPS', props);
        console.log('retrieving job');
            try {
                if (!props.jobId) {
                    console.log('retrieving job 1');
                    const ID = uuidv4(); //randomNumber(1000000000, 9999999999);
                    setJob(Object.assign({'ID': uuidv4(), 'source': 'hoot', 'files': []}));
                }
                if (props.jobId && !job) {
                    console.log('retrieving job 2');
                    const response = await fetch(`${process.env.REACT_APP_API}/dynamo/jobs?ID=${props.jobId}`);
                    //const response = await fetch(`https://api.hoot.works/jobs/${props.jobId}`);
                    const json = await response.json();
                    console.log('Items:', json.Items);
                    if (json.Items && json.Items.length > 0) {
                        const loadedJob = json.Items[0];
                        setJob(Object.assign(loadedJob));
                        console.log('setting headers:', loadedJob.employer?.headerUrl);
                        if (loadedJob.employer) {
                            setHeaders([{url:loadedJob.employer?.headerUrl}]);
                        }
                        if (loadedJob.advert) {
                            setEndDate(loadedJob.advert?.endDate)
                        }
                        loadedJob.attachments && setAttachments(loadedJob.attachments);
                        loadedJob.advert?.startDate && setStartDate(loadedJob.displayJobStartDate);
                    }


                    //console.log("job", json);
                    //console.log("header", json.employer.headerUrl);
                }

            }
            catch (e) {

                // setJob(undefined);
            }
        }

    const softDeleteHootJob = async (job: Job) => {
        const response = await fetch(`${process.env.REACT_APP_API}/api/softdeletejob`, {
            method: "post",
            headers: {
                'Content-Type': 'application/json',
                'Authorization': accessToken,
            },

            //make sure to serialize your JSON body
            body: JSON.stringify(job.ID)
        });
    }

        const setAsHootJob = (job: Job) => {
            job.source = 'hoot';
            job.oldId = job.id;
            job.ID = randomNumber(1000000000, 9999999999);
            setJob(job);
            saveJob();
        }

    const setJobValue = (key: string, value: any) => {
        const clone = Object.assign({}, job);
        clone[key] = value;
        console.log('check job');

        if (job) {
            if(canSave === false) {
                setOriginalJob(job);
                console.log('setting original job');
                setCanSave(true);
                console.log('setting can save to true');
                //console.log('dirtying job');
            }
            else {
                if (JSON.stringify(clone) === JSON.stringify(originalJob)) {
                    setCanSave(false);
                    console.log('setting can save to false');
                    //console.log('cleaning job');
                }
            }

            setJob(clone);
        }
        //console.log('job', job);
    }

    const setJobValues = (values: {key: string, value: any}[]) => {
        const clone = Object.assign({}, job);
        values.forEach((value) => {
            clone[value.key] = value.value;
        });

        if (job) {
            if(canSave === false) {
                setOriginalJob(job);
                setCanSave(true);
                //console.log('dirtying job');
            }
            else {
                if (JSON.stringify(clone) === JSON.stringify(originalJob)) {
                    setCanSave(false);
                    //console.log('cleaning job');
                }
            }

            setJob(clone);
        }
        //console.log('job', job);
    }



    return (
        <JobContext.Provider value={{advertAssign, applicationAssign, employerAssign, contactAssign, locationAssign, salaryAssign, startDate, deleteJob, setStartDate, endDate, setEndDate, job, retrieveJob, saveJob, setJobValue, setJobValues, canSave, headers, setHeaders, attachments, setAttachments}}>
            {props.children}
        </JobContext.Provider>
    )
}
