import {Job} from "../../types/job";
import {useContext, useEffect, useState} from "react";
import {JobContext} from "../../providers/JobProvider";
import './ContactAside.scss';

export function ContactAside() {
    const {job, retrieveJob}: any = useContext(JobContext);
    useEffect(() => {
        if (!job) {
            retrieveJob();
        }
    }, [job]);

    return (
        <aside>
            <h4><span className="primary">{ job?.employer?.name }</span> - { job?.employer?.location?.description }</h4>
            <div className="apply-details">
                <div className="apply-detail">
                    <div className="label">
                        Name:
                    </div>
                    <div className="value w600">
                        { job?.employer?.name }
                    </div>
                </div>
                {job?.employer?.contact?.location?.addressLine1 && <div className="apply-detail">
                    <div className="label">
                        Display Address:
                    </div>
                    <div className="value w600">
                        {job?.employer?.contact?.location?.addressLine1}
                    </div>
                </div>}
                <div className="apply-detail">
                    <div className="label">
                        Address:
                    </div>
                    <div className="value w600">
                        {job?.employer?.contact?.location?.addressLine2 && <>{job?.employer?.contact?.location?.addressLine2}<br/></>}
                        {job?.employer?.contact?.location?.addressLine3 &&
                            <span>{job?.employer?.contact?.location?.addressLine3}<br/></span>}
                        {job?.employer?.contact?.location?.cityTown}<br/>
                        {job?.employer?.contact?.location?.stateCounty}<br/>
                        {job?.employer?.contact?.location?.postCode}
                    </div>
                </div>
                {job?.employer?.contact?.telephone && <div className="apply-detail">
                    <div className="label">
                        Phone:
                    </div>
                    <div className="value w600">
                        {job?.employer?.contact?.telephone}
                    </div>
                </div>}
            </div>
        </aside>
    )
}
