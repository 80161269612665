import {Button, DropZone, FileDropItem, FileTrigger, Text} from 'react-aria-components';
import './DropZone.scss';
import {DropzoneFileList} from "./DropzoneFileList";
import {useEffect} from "react";
import {ImageList, VideoList} from "./FilleDisplayTemplates";

const imageMimeTypesByExtension = {
    'jpg': 'image/jpeg',
    'jpeg': 'image/jpeg',
    'png': 'image/png',
    'gif': 'image/gif',
    'svg': 'image/svg+xml',
}

export type DropzoneFile = {
    caption?: string,
    id?: string,
    mimeType?: string,
    name?: string,
    size?: string,
    url?: string,
    isNewFile?: boolean,
    file?: File,
}

type ADZProps = {
    files: DropzoneFile[],
    setFiles: Function,
    FileList?: any,
    listFiles?: boolean;
    mode?: 'replace' | 'append',
    previewImages?: boolean,
}

export const fileIsImage = ({mimeType, url}:DropzoneFile) => {
    if (!mimeType && url) {
        if (Object.keys(imageMimeTypesByExtension).includes((url.split('.').pop() as string)?.split('?')[0])) {
            return true;
        }
    }
    return mimeType?.includes('image/');
}

export const fileIsVideo = ({mimeType}:DropzoneFile) => {
    return mimeType?.includes('video/');
}

export function uuidv4() {
    return "10000000-1000-4000-8000-100000000000".replace(/[018]/g, (c:any) =>
        (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16)
    );
}

export function AriaDropzone({files, setFiles, mode='replace', listFiles=false, previewImages=false, FileList=DropzoneFileList}:ADZProps) {

    const FileDropItemToDropzoneFile = async (fileDropItem:FileDropItem):Promise<DropzoneFile> => {
        const file = await fileDropItem.getFile();
        return FileToDropzoneFile(file);
    }

    const FileToDropzoneFile = (file:File):DropzoneFile => {
        return {
            caption: file.name?.split('.')[0].replace(/_/g, ' '),
            id: uuidv4(),
            mimeType: file.type,
            name: file.name,
            size: `${file.size.toString()}B`,
            file: file,
            isNewFile: true,
        }
    }

    const onDrop = async (e:any) => {
        let fileDropItems = e.items.filter((file:FileDropItem) =>
            file.kind === 'file'
        ) as FileDropItem[];
        let newFiles = await Promise.all(fileDropItems.map((fileDropItem) => FileDropItemToDropzoneFile(fileDropItem)));
        console.log('newFiles', newFiles);
        await processDropzoneFiles(newFiles);
    }

    const onSelect = async (e:any) => {
        let files:File[] = Array.from(e);
        let newFiles:DropzoneFile[] = files.map((file) => FileToDropzoneFile(file));
        await processDropzoneFiles(newFiles);
    }

    const processDropzoneFiles = async (addedFiles:DropzoneFile[]) => {
        const newFiles = await Promise.all(addedFiles.map(async (file)=> {
            if (fileIsImage(file) || fileIsVideo(file)) {
                return {
                    ...file,
                    url: await generateImagePreviewUrl(file)
                }
            }
            else {
                return file;
            }
        }));

        if (mode === 'replace') {
            setFiles([newFiles[0]]);
        }
        else {
            setFiles([...files, ...newFiles]);
        }
    }

    const generateImagePreviewUrl = async (file:DropzoneFile) => {
        if (file.file) {
            return new Promise(function(resolve, reject) {
                const reader = new FileReader();
                reader.onload = (e) => {
                    resolve(e?.target?.result as string);
                }
                if (file && file.file) {
                    reader.readAsDataURL(file.file);
                }
            });
        }
        else {
            return file.url;
        }
    }

    const deleteFile = (index:number) => {
        const filesCopy = [...files];
        filesCopy.splice(index, 1);
        setFiles([...filesCopy]);
    }

    return (
        <DropZone
            className={'flex border border-1 border-bg w-full min-h-[200px] rounded-corners-big-card flex-col'}
            onDrop={onDrop}
        >
            <FileTrigger
                allowsMultiple={mode === 'append'}
                onSelect={onSelect}
            >
                <Button>Select files</Button>
            </FileTrigger>

            {files && listFiles && <FileList files={files} deleteItem={deleteFile}></FileList>}

            {files && previewImages && <ImageList files={files} deleteItem={deleteFile}></ImageList>}

            {files && previewImages && <VideoList files={files} deleteItem={deleteFile}></VideoList>}
        </DropZone>
    )
}
