import {Children, createContext, useContext, useEffect, useReducer, useState} from "react";
import {signIn, signUp} from "../utils/aws/cognito";
import {useNavigate} from "react-router-dom";
import { jwtDecode } from 'jwt-decode'

export const AuthContext: any = createContext(null);

type AuthProviderProps = {
    children: any,
}
export function AuthProvider(props:AuthProviderProps) {
    const navigate = useNavigate();

    const [accessToken, setAccessToken] = useState(undefined as string | undefined);
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const [userGroups, setUserGroups] = useState(['GodMode'] as string[]);
    const [userDetails, setUserDetails] = useState(undefined as any | undefined);

    const login = async ({email, password}: any) => {
        console.log('logging in');
        try {
            const response = await signIn({email, password});
            console.log('response', response);
            if (response.AuthenticationResult) {
                await saveUserDetails(response.AuthenticationResult.AccessToken, response.AuthenticationResult.IdToken);
                setIsLoggedIn(true);
            }
            return response;
        }
        catch (e) {
            console.log('Error from login', e);
            throw new Error('Incorrect username or password');
        }
    }

    const saveUserDetails = async (accessToken: string, IdToken?: string) => {
        if (accessToken) {
            setIsLoggedIn(true);
            console.log('saving accessToken', accessToken);
            setAccessToken(accessToken);
            localStorage.setItem('accessToken', accessToken);
            if (IdToken) {
                localStorage.setItem('IdToken', IdToken);
                const decoded = jwtDecode(IdToken);
                setUserDetails(decoded);
                console.log('decoded', decoded);
            }
            const time = Date.now().toString();
            console.log('time', time);
            localStorage.setItem('loginTime', time);
        }
    }

    const timeIsOverAnHourAgo = (ms: number) => {
        const now = new Date();
        const then = new Date(ms);
        const diff = now.getTime() - then.getTime();
        const diffInHours = diff / (1000 * 60 * 60);
        return diffInHours > 1;
    }

    const logout = async () => {
        setIsLoggedIn(false);
        localStorage.removeItem('accessToken');
        localStorage.removeItem('userGroups');
        setUserGroups([]);
        setAccessToken(undefined);
        navigate('/');
    }

    const register = async ({email, password, phone, subject, fullName, surname, signUpType}: any) => {
        const response = await signUp({fullName, surname, email, password, phone, subject, signUpType});
        console.log('response', response);
        if (response.AuthenticationResult) {
            await saveUserDetails(response.AuthenticationResult.AccessToken, response.AuthenticationResult.IdToken);
            setIsLoggedIn(true);
        }
        return response;
    }

    const isLoggedInFunc = () => {
        const accessToken = localStorage.getItem('accessToken');
        const userGroups = localStorage.getItem('userGroups');
        const lastLoggedIn = Number(localStorage.getItem('loginTime'));
        console.log('accessToken check', accessToken);
        console.log('loginTime', new Date(1708077084839));
        console.log('timeIsOverAnHourAgo', timeIsOverAnHourAgo(lastLoggedIn));
        return accessToken && !timeIsOverAnHourAgo(lastLoggedIn);
    }

    useEffect(() => {
        console.log('setting access token', isLoggedIn);
        if (!isLoggedIn) {
            const accessToken = localStorage.getItem('accessToken');
            const userGroups = localStorage.getItem('userGroups');
            const userDetails = localStorage.getItem('IdToken');
            if (userDetails) {
                setUserDetails(jwtDecode(userDetails));
                console.log('userDetails', jwtDecode(userDetails));
            }
            if (isLoggedInFunc()) {
                setAccessToken(accessToken??undefined);
                setIsLoggedIn(true);
                console.log("is logged in");
            }
            if (userGroups) {
                setUserGroups(JSON.parse(userGroups));
            }
        }
    }, []);

    useEffect(() => {
        if(isLoggedIn && accessToken && userGroups.length === 0) {
            const retriveUserGroups = async () => {
                try {
                    const response = await fetch(`${process.env.REACT_APP_API}/api/list-groups`, {
                        method: "get",
                        headers: {
                            'Authorization': accessToken,
                        }
                    });
                    const json = await response.json();
                    console.log('user groups', json);
                    setUserGroups(json);
                    localStorage.setItem('userGroups', JSON.stringify(json));
                }
                catch (e) {
                    console.log('failed to retrieve user groups');
                }
            }
            console.log('retrieving groups');

            retriveUserGroups();
        }
    }, [accessToken]);


    return (
        <AuthContext.Provider value={{accessToken, isLoggedIn, login, logout, register, userGroups, isLoggedInFunc, userDetails}}>
            {props.children}
        </AuthContext.Provider>
    )
}
