import {useEffect, useState} from "react";

export function useDynamo({tableName, id}: {tableName: string, id: string}) {
    const [value, setValue] = useState<any>(null);

    useEffect(() => {
        console.log(id);
        const set = async () => {
            try {
                if (localStorage.getItem('accessToken')) {
                    const response = await fetch(`${process.env.REACT_APP_API}/api/dynamo/${tableName}?ID=${id}`, {
                        method: "get",
                        headers: {
                            'Authorization': localStorage.getItem('accessToken') || '',
                        }
                    });
                    const results = await response.json();
                    console.log('dynamo results', results);
                    setValue(results.Items[0]);
                }
                else {
                    return [];
                }
            }
            catch (e) {
                console.log('dynamo error:', e);
            }
        }

        set();
    }, []);

    return value;
}
