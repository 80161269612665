import {Header} from "../../components/Header/Header";
import {ReactNode} from "react";
import {Footer} from "../../components/Footer/Footer";
import DynamicImage from "../../components/DynamicImage";

export const ContentLayout = ({children,
                              title,
                              subtitle}:{
                                children:ReactNode,
                                title:string,
                                subtitle:string}) => {
    return (
        <>
            <div className={"page"}>
                <Header/>
                <header className={"bg-background-2 px-8 sm:px-[64px] pt-5 pb-[130px] sm:py-[60px] flex flex-col gap-[6px] sm:gap-3 relative"}>
                    <div className={'max-w-[1312px] w-full mx-auto items-left text-left'}>
                        <h2 className={"text-body-16 sm:text-2xl font-bold text-primary"}>{subtitle}</h2>
                        <h1 className={"text-title-32 sm:text-4xl font-bold"}>{title}</h1>
                    </div>
                    <DynamicImage imagePath={'hoot-grey-med.svg'}
                                  className={'absolute right-0 bottom-0 sm:top-0 sm:h-full'}/>
                </header>
                <div className={"text-block max-w-[1440px] px-8 sm:px-[64px] mx-auto"}>
                    {children}
                </div>
            </div>
            <Footer/>
        </>
    )
}
