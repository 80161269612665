import React, { useState, useEffect } from 'react';

function DynamicImage({imagePath, ...rest}: { imagePath: string, className?: string }) {
    const [imageSrc, setImageSrc] = useState(null);

    useEffect(() => {
        import(`../assets/images/${imagePath}`)
            .then((module) => {
                setImageSrc(module.default);
            })
            .catch((error) => {
                // Handle the error if the image couldn't be loaded
                console.error('Error loading image:', error);
            });
    }, [imagePath]);

    return (
        <div>
            {imageSrc && <img {...rest} src={imageSrc} alt="Dynamic Image" />}
        </div>
    );
}

export default DynamicImage;
