import {ReactNode, useContext, useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import {AuthContext} from "./AuthProvider";

export function AuthGuard({children, accessLevel}: {children: ReactNode, accessLevel?: string[]}) {
    const navigate = useNavigate();
    const {isLoggedIn, userGroups, isLoggedInFunc}: any = useContext(AuthContext);

    const arrayIsSubset = (arr1: string[], arr2: string[]) => {
        return arr1.every((val) => arr2.includes(val));
    }

    const atLeastOneElementInArray = (arr1: string[], arr2: string[]) => {
        return arr1.some((val) => arr2.includes(val));
    }

    useEffect(() => {
        const userGroups = JSON.parse(localStorage.getItem('userGroups')??'[]');

        const myIsLoggedIn  = isLoggedInFunc();

        console.log('myIsLoggedIn', myIsLoggedIn);

        if (!myIsLoggedIn) {
            navigate('/');
        }
    }, []);

    return (
        <>
            {isLoggedIn && accessLevel && atLeastOneElementInArray(accessLevel, userGroups) && children}
        </>
    )
}
