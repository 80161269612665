import DynamicImage from "../../components/DynamicImage";

export function AdditionalFeatures() {
    return (
        <div className={'flex flex-col gap-16 self-center items-center max-sm:w-full max-sm:px-8 max-sm:text-center'}>
            <div className={'flex flex-col gap-2 max-sm:text-center'}>
                <h3 className={'text-primary text-body-24 max-sm:text-center'}>Additional Features</h3>
                <h2 className={'text-title-42 text-header text-header max-sm:text-center'}>We're not just about recruitment</h2>
            </div>
            <div className={'flex gap-16 max-sm:flex-col'}>
                <div className={'flex flex-col gap-8  items-center'}>
                    <DynamicImage imagePath={'Frame 2609939.png'} className={'self-center h-[428px] rounded-corners-big-card'}></DynamicImage>
                    <div className={'flex flex-col gap-6 w-[350px] md:w-[415px]'}>
                        <div className={'flex flex-col gap-[4px] sm:gap-6'}>
                            <h4 className={'text-center max-sm:text-body-18 text-header'}>Hoot Articles</h4>
                            <h2 className={'text-center text-title-24 sm:text-title-32 text-header'}>Keep up-to-date with educational trends</h2>
                        </div>
                        <p className={'text-body-16 text-header'}>
                            Explore our evolving catalogue of interesting articles and courses written by the worlds most influential educational voices.
                        </p>
                    </div>
                </div>
                <div className={'flex flex-col gap-8  items-center'}>
                    <DynamicImage imagePath={'Frame 2609939-5.png'} className={'self-center h-[428px] rounded-corners-big-card'}></DynamicImage>
                    <div className={'flex flex-col gap-[4px] sm:gap-6 w-[350px] md:w-[415px]'}>
                        <div className={'flex flex-col gap-6'}>
                            <h4 className={'text-center text-body-18 text-header max-sm:text-center'}>Hoot Courses</h4>
                            <h2 className={'text-center text-title-24 sm:text-title-32 text-header max-sm:text-center'}>Train your staff with bespoke courses</h2>
                        </div>
                        <p className={'text-body-16 text-header'}>
                            Explore our evolving catalogue of interesting articles and courses written by the worlds most influential educational voices.
                        </p>
                    </div>
                </div>
            </div>
        </div>

    )
}
