import {DropzoneFile} from "./DropZone";
import {Fragment, useEffect} from "react";

export function DropzoneFileList({files, deleteItem}: { files: DropzoneFile[], deleteItem?: Function }) {

    return (
        <div className={'flex flex-col gap-[16px]'}>
            {files && files.map((file: DropzoneFile, index: number) =>
                <Fragment key={index}>
                <div className={'flex flex-col gap-[12px]'}>
                <h1 className={'text-[24px]'}>{file.name}</h1>
                    <div>
                    <div>{file.caption}</div>
                    <div>{file.name}</div>
                    <div>{file.size}</div>
                    <div>{file.mimeType}</div>
                    <div>{file.url}</div>
                    {file.isNewFile && <div>NEW FILE</div>}
                    <div>{file.id}</div>
                    <button type={'button'} onClick={() => deleteItem? deleteItem(index): ()=>{}}>Delete</button>
                    </div>
                    </div>
                </Fragment>
            )}
        </div>
    )
}
