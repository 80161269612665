import {DropzoneFile, fileIsImage, fileIsVideo} from "./DropZone";
import {Text} from "react-aria-components";

export function ImageList({files, deleteItem}: { files: DropzoneFile[], deleteItem?: Function }) {

    return (
        <>
            {files.filter(fileIsImage).map((file:DropzoneFile, index:number) =>
                <>
                    {!file.url && <Text>Loading...</Text>}
                    <img className={'h-full object-scale-down'} key={index} src={file.url} alt=""/>
                </>
            )}
        </>
    )
}

export function VideoList({files, deleteItem}: { files: DropzoneFile[], deleteItem?: Function }) {

    return (
        <>
            {files.filter(fileIsVideo).map((file:DropzoneFile, index:number) =>
            <>
                {!file.url && <Text>Loading...</Text>}
                <video controls>
                    <source src={file.url} type={file.mimeType} />
                    <p>
                        Your browser doesn't support HTML video. Here is a
                        <a href="myVideo.mp4">link to the video</a> instead.
                    </p>
                </video>
            </>
            )}
        </>
    )
}
