import {ImageCarousel} from "../ImageCarousel/ImageCarousel";
import {Search} from "../Search/Search";
import './Splash.scss';
import {useContext, useEffect, useState} from "react";
import {InstantSearch, useStats} from "react-instantsearch";
import {SearchOptionsContext} from "../../providers/SearchOptionsProvider";

export function ShowResults() {
    const {hitsPerPage, nbHits, areHitsSorted, nbSortedHits, nbPages, page, processingTimeMS, query} = useStats();

    const addCommaForThousands = (num: number) => {
        return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }

    return (
        <h4>{addCommaForThousands(nbHits)}</h4>
    )
}

export function Splash() {
    const [hootJobs, setHootJobs] = useState(undefined);
    const [otherSchoolJobs, setOtherSchoolJobs] = useState(undefined);
    const [otherEducationJobs, setOtherEducationJobs] = useState(undefined);

    const {
        hitsPerPage,
        nbHits,
        areHitsSorted,
        nbSortedHits,
        nbPages,
        page,
        processingTimeMS,
        query,
    } = useStats();

    /*useEffect(() => {
        const getJobsCount = async () => {
            try {
                const response = await fetch(`${process.env.REACT_APP_API}/counts`);
                const json = await response.json();

                const response2 = await fetch(`${process.env.REACT_APP_API}/synopsis`);
                const json2 = await response2.json();
                //setHootJobs(json.hootJobs);
                //setOtherJobs(json.otherJobs);
                console.log('counts', json);
                console.log('synopsis', json2.hoot_jobs.length);

                setHootJobs(json2.hoot_jobs.length);
                setOtherSchoolJobs(json.hits1);
                setOtherEducationJobs(json.hits2);
            }
            catch (e) {
                console.log('error', e);
            }
        }

        getJobsCount();
    }, []);*/

    const addCommaForThousands = (num: number) => {
        return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }

    const {searchClient}: any = useContext(SearchOptionsContext);

    return (
        <div className="splash">
            <div className={'main'}>
                <div className="text-panel w-full">
                    <h1>Connecting Schools with Top Teaching Talent</h1>
                    <hr className={'sm:hidden'} />
                    <div className="stats">
                        <div className="education stat">
                            <ShowResults></ShowResults>
                            <span> Education Jobs</span>
                        </div>
                        {hootJobs && otherSchoolJobs && <div className="schools stat">
                            <h4>{addCommaForThousands(nbHits)}</h4>
                            <span>Schools</span>
                        </div>}
                    </div>
                </div>
                <div className="search-panel max-sm:absolute">
                    <h4 className={'max-sm:text-center'}>Find Your <span className="primary-color">Dream Job</span></h4>
                    <Search />
                </div>
            </div>
            <ImageCarousel />
        </div>
    )
}
