import './TextFeature.scss';
import {ReactNode} from "react";

export function TextFeature({emphasisColor = 'gold', title='Key features', subtitle=(<>See why teachers like you <br /> are <em>joining</em> us</>)}: {emphasisColor?: string, title?:string, subtitle?:string|ReactNode}) {
    return (
        <div className={`text-feature ${emphasisColor} max-sm:p-4`}>
            <h3>{title}</h3>
            <h2>{subtitle}</h2>
        </div>
    )
}
