import {JobCard} from "./JobCard";
import {useContext, useEffect, useState} from "react";
import {JobsContext} from "../../providers/JobsProvider";
import {useDynamo} from "../../hooks/useDynamo";

function JobWrapper({id}: {id:any}) {
    const job = useDynamo({tableName: 'jobs', id: id});
    return <JobCard job={job}/>
}

export function FavouriteJobCards() {
    const {getValue}: any = useContext(JobsContext);
    const [favoritesIDs, setFavoritesIDs] = useState<any[] | undefined>(undefined);

    useEffect(() => {
        const retrieveValue = async () => {
            const value = await getValue();
            setFavoritesIDs(value);
        }

        retrieveValue();
    }, [getValue]);

    useEffect(() => {
        console.log('favouritesIDs', favoritesIDs);
    }, [favoritesIDs]);

    return <div className="cards flex flex-wrap justify-center gap-8 pb-8">
                {favoritesIDs && favoritesIDs.length > 0 && favoritesIDs.map((id: any) => {
                    return (<JobWrapper id={id.toString()}/>)
                })}
                {!favoritesIDs || favoritesIDs.length === 0 &&
                    <span className={'flex flex-col items-center gap-[32px]'}>
                        <p>You have not saved any job posts to your profile.</p>
                        <p>Click on the heart symbol or ‘save’ button to add them to your favourites.</p>
                    </span>
                }
            </div>
        }
