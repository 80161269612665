import {
    Button, Label,
    ListBox,
    ListBoxItemProps,
    Popover,
    Select,
    SelectProps,
    SelectValue,
    ValidationResult
} from 'react-aria-components';
import {FieldError, ListBoxItem, Text} from 'react-aria-components';
import './AriaSelect.scss'

interface MySelectProps<T extends object>
    extends Omit<SelectProps<T>, 'children'> {
    label?: string;
    description?: string;
    errorMessage?: string | ((validation: ValidationResult) => string);
    items?: Iterable<T>;
    children: React.ReactNode | ((item: T) => React.ReactNode);
}

export function AriaSelect<T extends object>(
    { label, description, errorMessage, children, items, ...props }:
        MySelectProps<T>
) {
    return (
        <Select {...props} className={'flex flex-col'}>
            <Label>{label}</Label>
            <Button className={'flex items-center w-full border-solid rounded-corners bg-bg-emphasis border-border border-[1px] h-[42px]'}>
                <SelectValue style={{flexGrow: 1}} className={'text-left px-4'}  />
                {!props.isDisabled && <span aria-hidden="true"
                       className={'text-white bg-primary w-[42px] ' +
                           'h-[42px] flex items-center justify-center ' +
                           'rounded-corners'}>▼
                </span>}
            </Button>
            {description && <Text slot="description">{description}</Text>}
            <FieldError>{errorMessage}</FieldError>
            <Popover>
                <ListBox items={items} className={'border-solid bg-white border-primary border p-4 rounded-corners cursor-pointer'}>
                    {children}
                </ListBox>
            </Popover>
        </Select>
    );
    }

    export function AriaListItem(props: ListBoxItemProps) {
        return (
            <ListBoxItem
                {...props}
        className={({ isFocused, isSelected }) =>
        `my-item ${isFocused ? 'focused' : ''} ${isSelected ? 'selected' : ''}`}
        />
    );
}
