import {DynamicSvg} from "../../atoms/DynamicSVG/DynamicSVG";

export function DashboardSubscription() {
    return (
        <div className={'p-5 flex relative gap-4 w-full h-[88px] bg-gold items-center rounded-corners'}>
                <div className={'relative text-white flex-col rounded-2 bg-gold flex pl-[56px] pr-5'}>
                    <div className={"absolute justify-center items-center flex h-[44px] w-[44px] bg-white rounded-corners left-0"}>
                        <DynamicSvg name={'t'} stroke={''} />
                    </div>
                    <h3 className={'text-body-18'}>Subscription: Gold</h3>
                    <span className={'text-body-14'}>Active till: 24.09.2023</span>
                </div>
            <div className={'relative h-[44px] justify-center text-white flex-col rounded-2 bg-gold flex pl-[56px] pr-5 mr-auto'}>
                <div className={"absolute justify-center items-center flex h-[44px] w-[44px] bg-white rounded-corners left-0"}>
                    <DynamicSvg name={'briefcase-1'} stroke={''}/>
                </div>
                <h3 className={'text-body-18'}>Job Posts <span className={"ml-3 text-body-24 font-bold w-6"}>∞</span></h3>
            </div>
            <div className={'relative justify-center text-white flex-col rounded-2 bg-gold flex items-center pl-[56px] pr-5'}>
                <div className={"absolute justify-center items-center flex h-[44px] w-[44px] bg-white rounded-corners left-0"}>
                    <DynamicSvg name={'boost'} color={'#DB9D03'} stroke={''}/>
                </div>
                <h3 className={'text-body-18'}>Boosts <span className={"ml-3 text-body-18 font-semibold"}>6</span></h3>
            </div>
            <button className={'text-gold rounded-corners text-body-16 bg-white p-[10px]'}>
                    Manage
                </button>
        </div>
    )
}
