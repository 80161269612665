import {EditAside} from "../EditAside/EditAside";
import {ContactAside} from "../ContactAside/ContactAside";
import {ModalComponent} from "../ModalComponent/ModalComponent";
import {Checkbox} from "../../atoms/Checkbox/Checkbox";
import {JobDates} from "../../atoms/JobDates/JobDates";
import {Button} from "../../atoms/Button/Button";
import {useContext, useEffect, useState} from "react";
import {Job} from "../../types/job";
import GlobeIcon from "../../assets/icons/24/globe-blue.svg";
import PenIcon from "../../assets/icons/24/edit-2-blue.svg";
import {JobContext, JobProvider} from "../../providers/JobProvider";
import './JobEdit.scss';
import {Dialog, Modal, Button as AriaButton} from "react-aria-components";
import {AriaCheckbox} from "../../atoms/AriaCheckbox/AriaCheckbox";
import {DynamicSvg} from "../../atoms/DynamicSVG/DynamicSVG";
import {FileUploadTrigger} from "../../atoms/FileUpload/FileUpload";
import {saveFileToS3, saveToS3} from "../../utils/aws/s3";
import {AuthContext} from "../../providers/AuthProvider";

type JobEditProps = {
    dirtied: number[];
    selected: number[];
    onSelectToggled: Function
    onJobDirtied: Function
    onJobCleaned: Function
}

export function JobEdit({hit: searchJob}: {hit: any}) {
    const [expanded, setExpanded] = useState<boolean>(false);
    const [checked, setChecked] = useState<boolean>(false);
    const [viewAllText, setViewAllText] = useState<string>("View All");

     const {job:dbJob, saveJob, canSave}: {job: any, saveJob:any, canSave:boolean} = useContext(JobContext);
    const [job, setJob] = useState<Job>(searchJob);
    const {accessToken}: any = useContext(AuthContext);

    useEffect(() => {
        if (dbJob) {
            setJob(dbJob);
        }
        else if (searchJob) {
            setJob(searchJob);
        }

    }, [searchJob, dbJob]);

    const copyText = (text:any) => {
        navigator.clipboard.writeText(`https://www.tes.com${text}`);
    }

    const stripHtmlTags = (htmlContent: string) => {
        const parser = new DOMParser();
        const doc = parser.parseFromString(htmlContent, 'text/html');
        return doc.body.textContent;
    };

    const [editOpen, setEditOpen] = useState(false);
    const [isOpen, setOpen] = useState(false);
    const [logoUrl, setLogoUrl] = useState<string | undefined>(undefined);
    const [fileRef, setFileRef] = useState<File | undefined>(undefined);

    useEffect(() => {
        if (fileRef) {
            saveFileToS3(fileRef, undefined, `${job?.ID}/logos`).then((response: any) => {
                const images = job?.employer?.images;
                images.logo = response;
                const employer = job?.employer??{images};
                employer.images = images;
                const saveResponse = fetch(`${process.env.REACT_APP_API}/api/dynamo/jobs`, {
                    method: "put",
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': accessToken,
                    },

                    //make sure to serialize your JSON body
                    body: JSON.stringify({employer})
                });
            });
        }
    }, [fileRef]);

    return (
        <>
                <Modal isOpen={isOpen}  onOpenChange={setOpen} isDismissable>
                    <Dialog>
                        {({ close }) => (
                            <div style={{backgroundColor: '#FFFFFF', width:'fit-content', height: 'fit-content', 'borderRadius':'16px'}}>
                                <ContactAside />
                            </div>
                        )}
                    </Dialog>
                </Modal>

                <Modal isOpen={editOpen}  onOpenChange={setEditOpen} isDismissable>
                    <Dialog>
                        {({ close }) => (
                            <div className={'relative'} style={{backgroundColor: '#FFFFFF', width:'fit-content', height: 'fit-content', 'borderRadius':'16px'}}>
                                <EditAside onClose={close}  />
                                <AriaButton onPress={close} style={{background: 'none'}} className={'bg-none absolute right-2 top-2 text-body-24 flex gap-2 items-center close-button'}>
                                    <svg width="20" height="20" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M4 4L12 12M12 4L4 12" stroke="rgba(27, 31, 59, 0.8)" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                </svg></AriaButton>
                            </div>
                        )}
                    </Dialog>
                </Modal>

    <div className="job-edit">
        <div className="url-input-panel">
            {/*<AriaCheckbox></AriaCheckbox>*/}
            <div className="url-entry-holder">
                <a href={`https://www.tes.com${job?.canonicalUrl}`} className="navigateLink"></a>
            <input className="url-input" placeholder={`https://www.tes.com/${job?.canonicalUrl}`} />
            <button className="copyLink" onClick={()=>copyText(job?.canonicalUrl)} />
        </div>
    </div>
    <div className="content">
        <div className="location ">
            <div className="header flex gap-3 items-center">
                <div className="flex gap-2 items-center">
                    <div className={'relative w-[120px]'}>
                        {job.ID.length > 10 && <FileUploadTrigger onUrlGenerated={setLogoUrl} onFileRef={setFileRef}>
                            <AriaButton className={'absolute top-[0px] right-0'}>
                                <DynamicSvg size={'12'} name={'edit-2'} color={'white'}/>
                            </AriaButton>
                        </FileUploadTrigger>}
                        <img className={'w-[120px]'} src={logoUrl??job?.employer?.images?.logo} alt="" />
                    </div>
                    <div className="headings std">
                        <h3 className="std lg w600">{job?.employer?.name}</h3>
                        <h4 className="std sm w400 op600">{ job?.displayLocation }</h4>
                    </div>
                </div>
            </div>
            {!(job?.description) && job?.description_1_preview && <div
                className={`main-body ${!expanded?'collapsed':''}`}>{stripHtmlTags(job?.description_1_preview)}</div>}
            {job?.description && <div className={`main-body ${!expanded ? 'collapsed' : ''}`}>{stripHtmlTags(job?.description)}</div>}
        <JobDates applyDate={job?.application?.closeDate} startDate={job?.displayStartDate}></JobDates>
    </div>
        <div className="job">
        <div className="header std">
            <h3 className="std lg w600">{ job?.title }</h3>
            <h4 className="std sm w400 op600">{ job?.displaySalary }</h4>
        </div>
            {!(job?.description_0) && job?.description_0_preview && <div
                className={`main-body ${!expanded?'collapsed':''}`}>{stripHtmlTags(job?.description_0_preview)}</div>}
        {job?.employer?.description && <div
            className={`main-body ${!expanded?'collapsed':''}`}>{stripHtmlTags(job?.employer?.description??'')}</div>}
</div>
</div>
    <div className="ctas">
        {/*<Button size="sm" text="" expandable={true} clickHandler={saveJob} disabled={!canSave}>Save</Button>*/}
    <Button className={'w-[200px]'}  size="sm" variant="tertiary" text="" expandable={false} clickHandler={()=>setExpanded(!expanded)}>{viewAllText}</Button>
    <Button size="sm" variant="outline" color="blue" text="" icon={GlobeIcon} clickHandler={()=>setOpen(true)}>
        <DynamicSvg name={'globe'} stroke={'#3475F5'} />
        Contact Data
    </Button>
    <Button  size="sm" text="" variant="space" icon={PenIcon} clickHandler={()=>setEditOpen(true)}>
        <DynamicSvg name={'edit-2'} stroke={'#3475F5'} />
        Edit</Button>
</div>
</div>
        </>
    )
}
