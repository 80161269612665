import {Checkbox} from 'react-aria-components';
import './AriaCheckbox.scss';

export function AriaCheckbox() {
    return (
        <Checkbox>
            <div className="checkbox">
                <svg viewBox="0 0 18 18" aria-hidden="true">
                    <polyline points="1 9 7 14 15 4" />
                </svg>
            </div>
        </Checkbox>
    )
}
