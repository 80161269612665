import {Job} from "../../types/job";
import moment from "moment";
import {Button} from "../../atoms/Button/Button";
import {JobContext} from "../../providers/JobProvider";
import {useContext, useEffect, useState} from "react";
import './ApplyAside.scss';
import {JobsContext} from "../../providers/JobsProvider";

export function ApplyAside() {
    const {job}:Job = useContext(JobContext);
    const {getValue, favorites, toggleFavorites}: any = useContext(JobsContext);

    const [favoritesIDs, setFavoritesIDs] = useState<any[] | undefined>(undefined);

    useEffect(() => {
        const retrieveValue = async () => {
            const value = await getValue();
            setFavoritesIDs(value);
        }

        retrieveValue();
    }, []);

    useEffect(() => {
        console.log('JOB', job);
    }, [job]);

    const formatDate = (date: string | undefined) => {
        if (!date) return '';
        return moment(date).format('DD MMM YYYY');
    }

    const visitWebpage = () => {
        window.open(job?.employer?.contact?.website, "_blank");
    }

    const saveFave = (e: Event) => {
        //e.preventDefault();
        //e.stopPropagation();
        //alert(job?.id);
        toggleFavorites(job?.id.toString());
    }

    return !job? (<></>):(
        <aside className={'xl:sticky top-[200px] lg:col-start-2 lg:row-start-1 lg:row-end-3 xl:w-[415px] items-start h-fit'} style={{alignItems: 'start'}} >
            <div className="ctas w-full">
                {job?.employer?.contact?.website && <Button expandable={true} text="" clickHandler={visitWebpage}>Apply via school website</Button>}
                <Button clickHandler={saveFave} disabled={favorites?.includes(job.ID.toString())} color={'light-blue'} expandable={false} text={favorites?.includes(job.ID.toString())?'Saved':'Save'} variant="primary" ></Button>
            </div>
            <div className="apply-details">
                {job?.salary?.description && <div className="apply-detail">
                    <div className="label">
                        Salary:
                    </div>
                    <div className="value w600">
                        {job?.salary?.description}
                    </div>
                </div>}
                    <div className="apply-detail">
                        <div className="label">
                            Type:
                        </div>
                        <div className="value w600">
                            { job?.contractTypes?.map((type: any) => type).join(', ') }
                        </div>
                    </div>
                {job?.displayStartDate && <div className="apply-detail">
                    <div className="label">
                        Start Date:
                    </div>
                    <div className="value w600">
                        {job?.displayStartDate}
                    </div>
                </div>}
                    <div className="apply-detail">
                        <div className="label">
                            Apply by:
                        </div>
                        <div className="value w600">
                            { formatDate(job?.advert?.endDate) }
                        </div>
                    </div>
                    <div className="post-date">
                        Job posted { formatDate(job?.advert?.startDate) }
                    </div>
                </div>
        </aside>
    )
}
