import {DynamicSvg} from "../../atoms/DynamicSVG/DynamicSVG";
import DynamicImage from "../DynamicImage";

export function ArticleCard({type = 'article', imagePath='a1.png', title='Building a Student-Centred School: Empowering Educators and Enhancing Education'}: {type?: string, imagePath?: string, title?: string}) {
    const isSelected = Math.random() < 0;

    const borderSpec = isSelected ? 'border-solid border-[3px] border-primary' : 'border-solid border-[1px] border-border';

    return (
        <div className={`flex flex-col rounded-corners ${borderSpec}`}>
            <span className={'relative'}>
                <DynamicImage imagePath={imagePath} />
                {type === 'article' && <button
                    className={'px-[12px] py-[6px] rounded-[25px] border-white border-solid border-[4px] bottom-[-20px] left-4 absolute flex gap-[6px] bg-primary text-white'}>
                    <DynamicSvg name={'idea-2'} stroke={'white'}/>
                    <span>Article</span>
                </button>}
                {type !== 'article' && <button
                    className={'px-[12px] py-[6px] rounded-[25px] border-white border-solid border-[4px] bottom-[-20px] left-4 absolute flex gap-[6px] bg-secondary text-white'}>
                    <DynamicSvg name={'idea-bulb'} stroke={'white'}/>
                    <span>Learning Centre</span>
                </button>}
            </span>
            <header className={"p-5 pt-8 gap-4 flex flex-col"}>
                <h3 className={"text-body-18 text-header"}>{title}</h3>
                {type === 'article' && <div className={"flex gap-8 text-header-light text-body-14 items-center"}>
                    <span className={'flex gap-2'}><DynamicSvg name={'clock'}
                                                               stroke={'rgba(27, 31, 59, .6)'}/> 4 min</span>
                    <span className={"mr-auto flex gap-2"}><DynamicSvg name={'eye'} stroke={'rgba(27, 31, 59, .6)'}/> 366 views</span>
                    <DynamicSvg name={'share'} stroke={'rgba(27, 31, 59, .6)'}/>
                </div>}
                {type !== 'article' && <div className={"flex gap-8 text-header-light text-body-14 items-center"}>
                    <span className={'flex gap-2'}><DynamicSvg name={'calendar'}
                                                               stroke={'rgba(27, 31, 59, .6)'}/> Start today</span>
                </div>}
            </header>
        </div>
    )
}
