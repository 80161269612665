import {Button} from "../../atoms/Button/Button";
import './Footer.scss';
import {DynamicSvg} from "../../atoms/DynamicSVG/DynamicSVG";

export function Footer() {
    const scrollTop = () => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    }

    return (
        <section className="footer">
            <section className="copyright">
                <DynamicSvg type={'images'} name={'logo'} color={'white'} stroke={'none'} size={'54'}/>
                <span className="sm max-sm:hidden">© 2023 HOOT<br/> All rights reserved</span>
            </section>

            <div className="menu std">
                <h3 className="lg">Homepage</h3>
                <ul>
                    <li>
                        <a href="/search">Job Posts</a>
                    </li>
                    <li>
                        <a href="/teachers">For Teachers</a>
                    </li>
                    <li>
                        <a href="/schools">For Schools</a>
                    </li>
                </ul>
            </div>

            <div className="menu std">
                <h3 className="lg">Terms & Conditions</h3>
                <ul>
                    <li>
                        <a href="/privacy">Privacy Policy</a>
                    </li>
                    <li>
                        <a href="/terms">Terms & Conditions</a>
                    </li>
                </ul>
            </div>

            <div className="menu std">
                <h3 className="std lg">Contact Us</h3>
                <ul>
                </ul>
            </div>

            <div className={'max-sm:w-full'}>
                <Button type="main-2" clickHandler={() => scrollTop()} text="" expandable={true} className={'go-up max-sm:w-full'}>
                    <DynamicSvg name={'arrow-up'} stroke={'#fff'} color={'#fff'}/>
                    Go Up
                </Button>
            </div>

            <div className="sm md:hidden mb-5">© 2023 HOOT<br/> All rights reserved</div>
        </section>
    )
}
