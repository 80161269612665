import React, {useState, useRef, useContext, useEffect} from 'react';
import { useInstantSearch, useSearchBox } from 'react-instantsearch';
import {LocationContext} from "../../providers/LocationProvider";
import {Button} from "../../atoms/Button/Button";
import {SearchOptionsContext} from "../../providers/SearchOptionsProvider";
import {DynamicSvg} from "../../atoms/DynamicSVG/DynamicSVG";

export function CustomSearchBox(props:any) {

    const { query, refine } = useSearchBox(props);
    const { status } = useInstantSearch();
    const inputRef = useRef(null as HTMLInputElement | null);

    const isSearchStalled = status === 'stalled';

    const {latLng, setPostcode, postcode}: { latLng: number[], postcode: string, setPostcode: any } = useContext(LocationContext);
    const {query:savedQuery, setQuery:setSavedQuery}: any = useContext(SearchOptionsContext);

    useEffect(() => {
        refine(savedQuery);
    }, [savedQuery]);

    function setQuery(newQuery:any) {
        setSavedQuery(newQuery);

        refine(newQuery);
    }

    return (
        <div className={'flex-grow-1 flex w-full'}>
            <form
                className={'flex mx-auto gap-4 w-full flex-grow-1'}
                action=""
                role="search"
                noValidate
                onSubmit={(event) => {
                    event.preventDefault();
                    event.stopPropagation();

                    if (inputRef.current) {
                        inputRef.current?.blur();
                    }
                }}
                onReset={(event) => {
                    event.preventDefault();
                    event.stopPropagation();

                    setQuery('');

                    if (inputRef.current) {
                        inputRef.current?.focus();
                    }
                }}
            >
                <input
                    ref={inputRef}
                    autoComplete="off"
                    autoCorrect="off"
                    autoCapitalize="off"
                    placeholder="Search for jobs"
                    spellCheck={false}
                    maxLength={512}
                    type="search"
                    value={savedQuery}
                    onChange={(event) => {
                        setQuery(event.currentTarget.value);
                    }}
                    autoFocus
                    className={'flex-grow-1'}
                />
                <input placeholder={'Postcode'} className={'no-grow hidden'} type="text" value={postcode} onChange={(event) => {
                    setPostcode(event.currentTarget.value);
                }} />
                <div>
                    <button
                        className={'bg-primary rounded-corners w-[56px] h-[56px] max-md:w-[44px] max-md:h-[44px] flex items-center justify-center'}>
                        <DynamicSvg name={'search'} stroke={'white'}/>
                    </button>
                </div>
                <button
                    type="reset"
                    hidden={query.length === 0 || isSearchStalled}
                >
                    Reset
                </button>
            </form>
        </div>
    );
}
