import React, { useState, useEffect } from 'react';
export type IconSize = '12' | '16' | '24' | '32' | '42' | '44' | '54' | '96' | '120';

export function DynamicSvg({ name, size = '24', color = 'none', stroke='blue', type='icons' } :
        { name: string, size?: IconSize, color?: string, stroke?: string, type?:'icons'|'logos'|'images' }) {

    const [svg, setSvg] = useState(null as any);

    useEffect(() => {
        const importSvg = async () => {
            try {
                const ReactComponent = (await import(`!!@svgr/webpack?-svgo,+titleProp,+ref!../../assets/${type}${type==='icons'?`/${size==='12'?'24':size}`:''}/${name}.svg`)).default;
                if (!ReactComponent) {
                    throw new Error(`Cannot find SVG component '../../assets/icons/${size==='12'?'24':size}/${name}.svg'`);
                }

                setSvg(<ReactComponent height={size} fill={color} stroke={stroke} />);
            } catch (error) {
                console.error(`Error importing SVG: ${error}`);
            }
        };

        importSvg();
    }, [name, size, color]);

    return svg;
}
