import './Login.scss';
import {useContext, useEffect, useState} from "react";
import {signIn} from "../../utils/aws/cognito";
import {AuthContext} from "../../providers/AuthProvider";

export function Login({setOpen}: any) {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const {login}: any = useContext(AuthContext);
    const [errors, setErrors] = useState([] as string[]);

    const handleSignIn = async () => {
        try {
            const response = await login({email, password});
            setOpen(false);
        }
        catch (e) {
            setErrors(['Incorrect username or password']);
        }
    }

    return (
        <div className="login rounded-2xl gap-6 flex flex-col text-center rounded-corners">
            <h2 className={'text-body-18 font-semibold'}>Log in to Hoot</h2>
            <div className="rounded-corners membership-selector flex flex-1 p-[6px] bg-bg-emphasis rounded-corners">
                <button className={'flex-1 h-11 primary rounded-corners'}>Teacher</button>
                <button className={'flex-1 h-11 ghost rounded-corners'}>School</button>
            </div>
            {errors.length > 0 && <div className={'flex flex-col gap-2 text-error'}>
                {errors.map(error => <div className={'text-red-500 text-body-16'}>{error}</div>)}
            </div>}
            <div className="form flex flex-col flex-wrap gap-5">
                <div className={'flex'}>
                    <input value={email} onChange={(e)=>setEmail(e.target.value)} className={'basis-full h-14'} type="text" placeholder="Email" />
                </div>
                <div className={'flex'}>
                    <input value={password} onChange={(e)=>setPassword(e.target.value)} className={'basis-full h-14'} type="password" placeholder="Password" />
                </div>
                <div>
                    <button onClick={()=>handleSignIn()} className={'w-full rounded-corners flex-grow-1 basis-full mt-1 h-14 primary font-semibold'}>Log in</button>
                </div>
                <p className={'basis-full'}>Don’t have an account? <a className={'text-primary text-body-16'} href={'/register'}>Sign up to Hoot</a></p>
            </div>
        </div>
    )
}
