import {ChangeEvent, useEffect, useState} from "react";

export function useAssign<U extends object>(source: U)    {
    /*const assign = () => {
        return Object.assign(target, source);
    }*/

    const cloneObject = (obj: any) => {
        return JSON.parse(JSON.stringify(obj));
    }

    const [controlledSource, setControlledSource] = useState<U>(source);

    const overwriteWithValue = (value: U) => {
        setControlledSource(value);
    }

    useEffect(() => {
        console.log("controlledSource", controlledSource);
    }, [controlledSource]);

    function useField(key: keyof U, control: "text" | "checkbox" | "radio" | "select" | "textarea" | "custom" = "text") {

        const onTextChange = (event: ChangeEvent<HTMLInputElement>) => {
            onChange(event.target.value);
        }

        const onChange = (value: any) => {
            setControlledSource({...controlledSource, [key]: value});
        }

        const getChangeHandler = () => {
            switch (control) {
                case "text":
                    return onTextChange;
                default:
                    return onChange;
            }
        }

        return {value: controlledSource[key]??"", onChange};
    }

    function useSelectField(key: keyof U, options: string[]) {
        const items = options;

        const onChange = (value: any) => {
            setControlledSource({...controlledSource, [key]: value});
        }

        return {value: controlledSource[key]??"", onChange, items};
    }

    return {value: controlledSource, useField, useSelectField, overwriteWithValue};
}
