import { Button } from "../../atoms/Button/Button";
import { useNavigate } from "react-router-dom";
import BLueLogo from "../../assets/images/logo-blue.svg";
import WhiteLogo from "../../assets/images/logo-white.svg";
import "./Header.scss";
import {
  Dialog,
  Heading,
  Modal,
  OverlayArrow,
  Popover,
  PopoverProps,
} from "react-aria-components";
import { EditAside } from "../EditAside/EditAside";
import React, { useContext, useRef, useState } from "react";
import { Login } from "../../organisms/Login/Login";
import { AuthContext } from "../../providers/AuthProvider";
import { signOut } from "../../utils/aws/cognito";
import { DynamicSvg } from "../../atoms/DynamicSVG/DynamicSVG";
import DynamicImage from "../DynamicImage";

type PropTypes = {
  color?: string;
  logoColor?: string;
  displayCtas?: boolean;
};

interface MyPopoverProps extends Omit<PopoverProps, "children"> {
  children: React.ReactNode;
}

function MyPopover({ children, ...props }: MyPopoverProps) {
  return (
    <Popover {...props}>
      <OverlayArrow>
        {/*<svg width={12} height={12} viewBox="0 0 12 12">
                    <path d="M0 0 L6 6 L12 0" />
                </svg>*/}
      </OverlayArrow>
      <Dialog>{children}</Dialog>
    </Popover>
  );
}

export function Header({
  color,
  logoColor = "#3475F5",
  displayCtas = true,
}: PropTypes) {
  const navigate = useNavigate();
  let [isOpen, setOpen] = useState(false);
  let [isMenuOpen, setMenuOpen] = useState(false);
  const {
    isLoggedIn,
    userGroups,
    logout,
    userDetails: { name, family_name } = {
      name: undefined,
      family_name: undefined,
    },
  }: any = useContext(AuthContext);

  let triggerRef = useRef(null);

  const goToPage = (page: string) => {
    navigate(page);
  };

  return (
    <div
      className={`header ${
        color === "primary" ? "white" : ""
      } max-w-full box-border`}
    >
      <div className="logo-holder">
        <button onClick={() => goToPage("/")} type={"button"}>
          <DynamicSvg
            type={"images"}
            name={"logo"}
            color={logoColor}
            stroke={"none"}
            size={"54"}
          />
        </button>
        stage
      </div>
      <ul className="menu">
        <li>
          <a href="/">Home</a>
        </li>
        <li>
          <a href="/teachers">Teachers</a>
        </li>
        <li>
          <a href="/schools">Schools</a>
        </li>
        <li>
          <a href="/">Advertise with us</a>
        </li>
      </ul>
      <div className="ctas">
        {displayCtas && (
          <>
            {!isLoggedIn && (
              <>
                <Button
                  className={"max-xl:hidden"}
                  color={
                    color === "gold"
                      ? color
                      : color === "white"
                      ? "blue"
                      : "white"
                  }
                  size="sm"
                  text=""
                >
                  Advertise with us
                </Button>
                <Button
                  variant="primary"
                  className={"max-xl:hidden"}
                  color={
                    color === "gold"
                      ? color
                      : color === "white"
                      ? "light-blue"
                      : "blue"
                  }
                  size="sm"
                  text=""
                  clickHandler={() => setOpen(true)}
                >
                  Sign in
                </Button>
              </>
            )}
            {isLoggedIn && (
              <Button
                color={
                  color === "gold"
                    ? color
                    : color === "white"
                    ? "blue"
                    : "white"
                }
                size="sm"
                text=""
                clickHandler={() => logout()}
              >
                Log out
              </Button>
            )}
            <div className={"xl:hidden"} ref={triggerRef}>
              <Button
                className={"max-sm:w-[48px] max-sm:h-[48px] xl:hidden"}
                size="sm"
                text=""
                color={
                  color === "blue"
                    ? "white"
                    : color === "gold"
                    ? "gold"
                    : "blue"
                }
                clickHandler={() => setMenuOpen(!isMenuOpen)}
              >
                <DynamicSvg
                  name={"menu"}
                  stroke={color === "blue" ? "#3475F5" : "white"}
                />
              </Button>
            </div>
            {isLoggedIn && (
              <>
                <DynamicImage
                  imagePath={
                    color === "white" ? "Avatars.svg" : "Avatars_white.svg"
                  }
                />
                <a
                  className={"max-sm:hidden font-semibold"}
                  href={"/favourites"}
                >
                  {name} {family_name}
                </a>
              </>
            )}
            {/*<div className={'h-16 left-[64px] absolute'} ref={triggerRef}></div>*/}
            {/* eslint-disable-next-line react/jsx-no-undef */}
            <MyPopover
              className={"bg-white"}
              triggerRef={triggerRef}
              isOpen={isMenuOpen}
              onOpenChange={setMenuOpen}
            >
              <div className={"flex flex-col w-[315px]"}>
                <div
                  className={
                    "py-4 px-3 justify-between flex justify-between items-center"
                  }
                >
                  <DynamicSvg
                    type={"images"}
                    name={"logo"}
                    color={"#3475F5"}
                    stroke={"none"}
                    size={"44"}
                  />
                  <button onClick={() => setMenuOpen(false)}>
                    <DynamicSvg
                      type={"icons"}
                      name={"x"}
                      color={"#3475F5"}
                      stroke={"#3475F5"}
                      size={"24"}
                    />
                  </button>
                </div>
                <ul>
                  <li
                    className={
                      "flex justify-center w-full border-t-[1px] border-solid border-border"
                    }
                  >
                    <a
                      className={
                        "py-4 w-full text-center text-body-16 font-bold"
                      }
                      href="/"
                    >
                      Home
                    </a>
                  </li>
                  <li
                    className={
                      "flex justify-center w-full border-solid  border-t-[1px] border-border"
                    }
                  >
                    <a
                      className={
                        "py-4 w-full text-center text-body-16 font-bold"
                      }
                      href="/teachers"
                    >
                      Teachers
                    </a>
                  </li>
                  <li
                    className={
                      "flex justify-center w-full border-solid  border-t-[1px] border-border"
                    }
                  >
                    <a
                      className={
                        "py-4 w-full text-center text-body-16 font-bold"
                      }
                      href="/schools"
                    >
                      Schools
                    </a>
                  </li>
                  <li
                    className={
                      "flex justify-center w-full border-solid border-t-[1px] border-border"
                    }
                  >
                    <a
                      className={
                        "py-4 w-full text-center text-body-16 font-bold"
                      }
                      href="/terms"
                    >
                      Terms & Conditions
                    </a>
                  </li>
                  <li
                    className={
                      "flex justify-center w-full border-solid border-y-[1px] border-border"
                    }
                  >
                    <a
                      className={
                        "py-4 w-full text-center text-body-16 font-bold"
                      }
                      href="/privacy"
                    >
                      Privacy Policy
                    </a>
                  </li>
                </ul>
                <div className={"flex flex-col gap-2 p-4"}>
                  <Button
                    variant="primary"
                    color={"blue"}
                    size="sm"
                    text=""
                    clickHandler={() => setOpen(true)}
                  >
                    Advertise with us
                  </Button>
                  <Button
                    variant="primary"
                    color={"light-blue"}
                    size="sm"
                    text=""
                    clickHandler={() => navigate("/register")}
                  >
                    Sign in
                  </Button>
                </div>
              </div>
            </MyPopover>
          </>
        )}
      </div>
      <Modal isOpen={isOpen} onOpenChange={setOpen} isDismissable>
        <Dialog>
          {({ close }) => (
            <div
              className={"p-4"}
              style={{
                backgroundColor: "#FFFFFF",
                width: "fit-content",
                height: "fit-content",
                borderRadius: "16px",
              }}
            >
              <Login setOpen={setOpen} />
            </div>
          )}
        </Dialog>
      </Modal>
    </div>
  );
}
