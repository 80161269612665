import {parseDate} from "@internationalized/date";
import {
    Button,
    Calendar, CalendarCell, CalendarGrid, CalendarGridBody, CalendarGridHeader, CalendarHeaderCell,
    DateInput, DatePicker,
    DateSegment,
    Dialog,
    FieldError,
    Group, Heading, Input,
    Label,
    Popover,
    Text, TextField
} from "react-aria-components";
import "./AriaDatePicker.scss";
import {useEffect, useState} from "react";

export function AriaDatePicker({date, setDate}: any) {

    const tryParseDate = (date: string) => {
        try {
            return parseDate(date);
        }
        catch (e) {
            return undefined;
        }
    }

    const formateDate = (date: string) => {
        try {
            return date.split('T')[0].split('-').reverse().join('/');
        }
        catch (e) {
            return date;
        }
    }

    return (
        <>
            <TextField  onChange={setDate} value={formateDate(date)} className={'flex flex-col'}>
                <Label>{'Contract Terms'}</Label>
                <div className={'flex'}>
                    <Input className={'h-12 flex-shrink-0'}/>
                </div>
            </TextField>

            <Calendar className={'react-aria-Calendar text-primary flex justify-center items-center flex-col '} value={tryParseDate(date)??parseDate((new Date()).toISOString()?.split('T')[0])} onChange={(date)=>setDate(date.toString())}>
                <span className={'flex gap-2 justify-center items-center'}>
                    <Button slot="previous" className={'text-primary'}>◀</Button>
                    <Heading className={'text-body-14'} />
                    <Button slot="next" className={'text-primary'} >▶</Button>
                </span>
                <div >
                    <CalendarGrid className={'flex-grow-0 text-std-text'}>
                        <CalendarGridHeader>
                            {(day) => <CalendarHeaderCell />}
                        </CalendarGridHeader>
                        <CalendarGridBody>
                            {(date) => <CalendarCell date={date} />}
                        </CalendarGridBody>
                    </CalendarGrid>
                </div>
                <Text slot="errorMessage" />
            </Calendar>
        </>
    )
}
