import './Button.scss';

type PropTypes = {
    text?: string,
    color?: 'blue' | 'white' | 'red' | 'light-blue' | 'light-red' | 'gold',
    variant?: 'primary' | 'secondary' | 'tertiary' | 'outline' | 'space' | 'error' | 'success' | 'warning' | 'info'
    size?: string,
    icon?: string,
    expandable?: boolean,
    style?: object,
    clickHandler?: Function,
    disabled?: boolean,
    children?: any,
    type?: string,
    className?: string,
    onPress?: Function,
    ref?: any
}

const voidFunc = () => {};

export function Button({ref, onPress, children, text, color='blue', variant='primary', size='md', icon, expandable, style, clickHandler, disabled, type, className:classes}: PropTypes) {
  return (
      <button ref={ref} type="button" className={`hoot-button bold ${color} ${variant} btn-${size} ${icon?'icon':''} ${expandable?'expandable':''} ${classes}`} style={style} onClick={() => (clickHandler??voidFunc)()} disabled={disabled} >
        {children}
        { text }
      </button>
  )
}
