import {DynamicSvg} from "../../../atoms/DynamicSVG/DynamicSVG";


export function KeyFeature({title='Large School Network', text='Thousands of jobs added per-week.', color='red', icon='users'}:
                               {title: string, text: string, color?: string, icon?: string }) {
    return (
        <div className={'flex gap-4 md:min-w-[340px] max-sm:flex-col max-sm:items-center'}>
            <div className={'h-11 w-11 bg-white shrink-0 rounded-corners shadow-md flex items-center justify-center'}>
                <DynamicSvg name={icon} stroke={color}  />
            </div>
            <div className={'gap-2 flex flex-col'}>
                <h5 className={'max-sm:text-center'}>{title}</h5>
                <p className={'text-body-18 max-sm:text-center'}>{text}</p>
            </div>
        </div>
    )
}
