import {Tag} from "../../atoms/Tag/Tag";
import {AttachmentsGrid} from "../AttachmentsGrid/AttachmentsGrid";
import {JobContext} from "../../providers/JobProvider";
import {useContext, useEffect} from "react";
import {Job} from "../../types/job";
import './JobDetailCopy.scss';
import DynamicImage from "../DynamicImage";

export function JobDetailCopy() {
    const {job}:Job = useContext(JobContext);

    return (
        <div className="copy">

            <section className="job-info">
                {job?.description && <div className="main-copy"
                      dangerouslySetInnerHTML={{__html: job?.description}}></div>}
                {job?.employer?.description && <div className="main-copy"
                                            dangerouslySetInnerHTML={{__html: job?.employer?.description}}></div>}

            </section>
            {job?.attachments && job?.attachments.length > 0 && <section className="documents">
                <h3>Supporting Documents</h3>
                <AttachmentsGrid attachments={job?.attachments ?? []}/>
            </section>}
            {job?.employer?.name && job?.employer?.contact?.location?.latitude && job?.employer?.contact?.location?.longitude && <section className="map-pane">
                <h3>Location</h3>
                <iframe
                    className="map"
                    frameBorder="0" style={{border: 0}}
                    referrerPolicy="no-referrer-when-downgrade"
                    src={'https://www.google.com/maps/embed/v1/place?key=AIzaSyCw3TZKTlCjAwYmM5pqYAjbFFkpTFH3Efc&q=' + job?.employer?.name + ',' + job?._geoloc?.lat + ',' + job?._geoloc?.lng}// + job?.employer?.name + ',' + job?.employer?.location?.code}
                    allowFullScreen>
                </iframe>
            </section>}
        </div>
    );
}
