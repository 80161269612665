/**
 * Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 * SPDX-License-Identifier: Apache-2.0
 */

import {
    SignUpCommand,
    CognitoIdentityProviderClient,
    InitiateAuthCommand,
    AuthFlowType,
    GetUserCommand,
    UpdateUserAttributesCommand,
} from "@aws-sdk/client-cognito-identity-provider";

/**
 * Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 * SPDX-License-Identifier: Apache-2.0
 */
// @ts-ignore
import { curry, defaultTo } from "ramda";

const DEFAULT_REGION = "eu-west-1";

const orDefaultRegion = defaultTo(DEFAULT_REGION);

const createClientForRegion = curry(
    (region: any, ClientConstructor: any) =>
        new ClientConstructor({ region: orDefaultRegion(region) })
);

const createClientForDefaultRegion = createClientForRegion(null);

export {
    DEFAULT_REGION,
    createClientForDefaultRegion,
    createClientForRegion,
    orDefaultRegion,
};

/** snippet-start:[javascript.v3.cognito-idp.actions.SignUp] */
const signUp = async ({ fullName, surname, password, email, phone, subject }:any) => {
    //console.log({ fullName, password, email, phone, subject });
    try {
        const client = createClientForDefaultRegion(CognitoIdentityProviderClient);

        const clientId = process.env.REACT_APP_COGNITO_ID;

        if (phone.startsWith('0')) {
            phone = phone.replace('0', '+44');
        }

        const command = new SignUpCommand({
            ClientId: clientId,
            Username: email,
            Password: password,
            UserAttributes: [{ Name: "name", Value: fullName }, { Name: "family_name", Value: surname }, { Name: "phone_number", Value: phone }, { Name: "custom:subject", Value: subject },  { Name: "custom:registration_type", Value: 'teacher' }],
        });


        let response = await client.send(command);
        //console.log(response);

        if (response.UserConfirmed) {
            response = await signIn({ email, password });
            //console.log(response);
        }

        /*const hootResponse = await fetch('http://localhost:3030/api/hello', {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': response.AuthenticationResult.AccessToken,
            },
        });
        const hootJson = await hootResponse.json();

        if (hootJson.__type === "InvalidParameterException") {
            console.log("InvalidParameterException");
            if (hootJson.message.includes('User already exists')) {
                console.log(response);
            }
            if (hootJson.message.includes('User is disabled')) {
                console.log('User is disabled');
            }
            if (hootJson.message.includes('User does not exist')) {
                console.log('User does not exist');
            }
            if (hootJson.message.includes('Incorrect username or password.')) {
                console.log('Incorrect username or password.');
            }
            if (hootJson.message.includes('User is not confirmed.')) {
                console.log('User is not confirmed.');
            }
            if (hootJson.message.includes( 'User is already confirmed.')) {
                console.log('User is already confirmed.');
            }
            if (hootJson.message.includes('Invalid verification code provided, please try again.')) {
                console.log('Invalid verification code provided, please try again.');
            }
            if (hootJson.message.includes('Invalid password format.')) {
                console.log('Invalid password format.');
            }
            if (hootJson.message.includes('Invalid phone number format.')) {
                console.log('Invalid phone number format: should be in form +4412341233456');
            }
            if (hootJson.message.includes('Invalid email address format.')) {
                console.log('Invalid email address format.');
            }
            if (hootJson.message.includes('An account with the given email already exists.')) {
                console.log('An account with the given email already exists.');
            }
            if (hootJson.message.includes('An account with the given phone_number already exists.')) {
                console.log('An account with the given phone_number already exists.');
            }
        }
        console.log('hootJson', hootJson);*/

        return response;
    }
    catch (e:any) {
        console.log(e.toString())
        const errors = [];
        if (e.toString().includes('User already exists')) {
            errors.push('User already exists');
        }
        if (e.toString().includes('User is disabled')) {
            errors.push('User is disabled');
        }
        if (e.toString().includes('User does not exist')) {
            errors.push('User does not exist');
        }
        if (e.toString().includes('Incorrect username or password.')) {
            errors.push('Incorrect username or password.');
        }
        if (e.toString().includes('User is not confirmed.')) {
            errors.push('User is not confirmed.');
        }
        if (e.toString().includes( 'User is already confirmed.')) {
            errors.push('User is already confirmed.');
        }
        if (e.toString().includes('Invalid verification code provided, please try again.')) {
            errors.push('Invalid verification code provided, please try again.');
        }
        if (e.toString().includes('Invalid password format.')) {
            errors.push('Invalid password format.');
        }
        if (e.toString().includes('Invalid phone number format.')) {
            errors.push('Invalid phone number format.');
        }
        if (e.toString().includes('Invalid email address format.')) {
            errors.push('Invalid email address format.');
        }
        if (e.toString().includes('An account with the given email already exists.')) {
            errors.push('An account with the given email already exists.');
        }
        if (e.toString().includes('An account with the given phone_number already exists.')) {
            errors.push('An account with the given phone_number already exists.');
        }
        if (e.toString().includes('Value at \'password\' failed to satisfy constraint')) {
            errors.push('Stronger password required');
        }
        if (e.toString().includes('Member must have length greater than or equal to 1')) {
            errors.push('Ensure all fields are filled in');
        }
        if (errors.length === 0) {
            if (e.toString().includes(':')) {
                const errorArray = e.toString().split(':');
                const [first, ...errorMessage] = errorArray;
                errors.push(errorMessage.join(': '));
            }
            else {
                errors.push(e.toString());
            }
        }
        return {errors};
    }
};

const signIn = async ({ email, password }:any) => {
    const client = createClientForDefaultRegion(CognitoIdentityProviderClient);

    const clientId = process.env.REACT_APP_COGNITO_ID;

    const command = new InitiateAuthCommand({
        AuthFlow: AuthFlowType.USER_PASSWORD_AUTH,
        AuthParameters: {
            USERNAME: email,
            PASSWORD: password,
        },
        ClientId: clientId,
    });

    const result = await client.send(command);

    //console.log(result);

    localStorage.setItem('accessToken', JSON.stringify({...result.AuthenticationResult, expiry: Date.now() + (result.AuthenticationResult.ExpiresIn * 1000)}));

    return result;
};
/** snippet-end:[javascript.v3.cognito-idp.actions.SignUp] */

const update = async (attributes:any) => {
    const {AccessToken} = await loadAccessToken();
    const client = createClientForDefaultRegion(CognitoIdentityProviderClient);

    const input = { // UpdateUserAttributesRequest
        UserAttributes: attributes,
        AccessToken: AccessToken, // required
        //ClientMetadata: { // ClientMetadataType
        //  "<keys>": "STRING_VALUE",
        //},
    };
    const command = new UpdateUserAttributesCommand(input);
    const response = await client.send(command);
    // { // UpdateUserAttributesResponse
    //   CodeDeliveryDetailsList: [ // CodeDeliveryDetailsListType
    //     { // CodeDeliveryDetailsType
    //       Destination: "STRING_VALUE",
    //       DeliveryMedium: "SMS" || "EMAIL",
    //       AttributeName: "STRING_VALUE",
    //     },
    //   ],
    // };

}

const getUser = async () => {
    const client = createClientForDefaultRegion(CognitoIdentityProviderClient);
    const {AccessToken} = await loadAccessToken();
    const input = { // GetUserRequest
        AccessToken, // required
    };
    const command = new GetUserCommand(input);
    return await client.send(command);
    // { // GetUserResponse
    //   Username: "STRING_VALUE", // required
    //   UserAttributes: [ // AttributeListType // required
    //     { // AttributeType
    //       Name: "STRING_VALUE", // required
    //       Value: "STRING_VALUE",
    //     },
    //   ],
    //   MFAOptions: [ // MFAOptionListType
    //     { // MFAOptionType
    //       DeliveryMedium: "SMS" || "EMAIL",
    //       AttributeName: "STRING_VALUE",
    //     },
    //   ],
    //   PreferredMfaSetting: "STRING_VALUE",
    //   UserMFASettingList: [ // UserMFASettingListType
    //     "STRING_VALUE",
    //   ],
    // };

};

const isLoggedIn = async () => {
    const {expiry} = await loadAccessToken();
    return (expiry && !(await hasExpired(expiry))) ?? false;
};

const signOut = async () => {
    localStorage.removeItem('accessToken');
};

const loadAccessToken = async () => {
    return await JSON.parse(localStorage.getItem('accessToken')??'') ?? {};
};

const hasExpired = async (expiry:any) => {
    return expiry < Date.now();
};

export { signUp, signIn, update, getUser, isLoggedIn, signOut, loadAccessToken };

