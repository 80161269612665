import type {SliderProps} from 'react-aria-components';
import {Label, Slider, SliderOutput, SliderThumb, SliderTrack} from "react-aria-components";
import './AriaSlider.scss';

interface MySliderProps<T> extends SliderProps<T> {
    label?: string;
    thumbLabels?: string[];
}

export function AriaSlider<T extends number | number[]>(
    { label, thumbLabels, ...props }: MySliderProps<T>
) {
    return (
        /*<Slider {...props}>
            <Label>{label}</Label>
            <SliderOutput className={'bg-primary'}>
                {({ state }) =>
                    state.values.map((_, i) => state.getThumbValueLabel(i)).join(' – ')}
            </SliderOutput>
            <SliderTrack className={'bg-primary'}>
                {({ state }) =>
                    state.values.map((_, i) => (
                        <SliderThumb className={'bg-primary'} key={i} index={i} aria-label={thumbLabels?.[i]} />
                    ))}
            </SliderTrack>
        </Slider>*/
        <Slider {...props}>
            <Label>Set Distance</Label>
            <SliderTrack>
                <SliderThumb />
            </SliderTrack>
        </Slider>
    );
}


