import './AutoSlider.scss';

export function AutoSlider({children, speed = 20, fadeSides=true, maxWidth = 600, enabled=true, initialOffset=0}: {initialOffset?:number, enabled?:boolean, children:JSX.Element, speed?: number, fadeSides?: boolean, maxWidth?: number}) {
    const shouldAnimate = () => {
        return enabled && !window.matchMedia("(prefers-reduced-motion: reduce)").matches;
    }

    const maxSpeed = 80;

    return (
        <div className={`scroller ${fadeSides?'fade-sides':''} w-full`}
             data-animated={shouldAnimate()}>
            <div className={'tag-list scroller__inner flex w-max'} style={{animationDuration: `${maxSpeed - speed}s`, transform: `translateX(-${initialOffset}px)`}}>
                {children}
                {children}
            </div>
        </div>
    )
}
