import {Button} from "../../atoms/Button/Button";
import './Search.scss';
import type {TextFieldProps, ValidationResult} from 'react-aria-components';
import {FieldError, Input, Label, Text, TextField} from 'react-aria-components';
import {useLocation, useNavigate} from "react-router-dom";
import React, {useContext, useEffect} from "react";
import {LocationContext, LocationProvider} from "../../providers/LocationProvider";
import {SearchOptionsContext} from "../../providers/SearchOptionsProvider";

export function Search() {
    const {postcode, setPostcode}: any = useContext(LocationContext);
    const {query, setQuery, searchClient}: any = useContext(SearchOptionsContext);
    const navigate = useNavigate();

    const updateQuery = (value: any) => {
        //console.log(value);
        setQuery(value);
    }

    return (
        <div className="search " >
            <div className="input-holder max-md:w-full">
                <TextField  className={'flex w-full flex-grow-1'} value={query} onChange={updateQuery}>
                    <div className={'flex w-full flex-grow-1'}>
                        <Input placeholder={'Search'} style={{flexGrow: 1, width: '100%'}} className={'flex w-full flex-grow-1'} />
                    </div>

                </TextField>
            </div>
            <TextField value={postcode} onChange={setPostcode} className={'max-md:w-full'}>
                <input style={{width: '100%'}} placeholder={'Postcode'} className={'no-grow w-full'} type="text" value={postcode} onChange={(event) => {
                    setPostcode(event.currentTarget.value);
                }} />
            </TextField>
            <Button className={'max-md:w-full min-w-[200px]'} text="" clickHandler={() => navigate("/search")}>Find Jobs</Button>
        </div>
    )
}
