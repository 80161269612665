export const saveToS3 = async (files: any, fileName: string | undefined, subBucket?: string) => {
    //console.log('clicking');
    const accessToken = localStorage.getItem('accessToken')??'';
    const newFiles = [];
    for (const file1 of files.filter((file: any) => file.isNewFile)) {
        console.log('file1', file1);
        fileName = fileName? fileName: file1.name.replace(/\.[^/.]+$/, "");
        console.log('file namme is ', fileName);
        console.log('saving file1', file1);
        let presignedUrl = await retrievePresignedUrl(file1, fileName, subBucket);
        console.log('presignedUrl', presignedUrl);
        if (presignedUrl) {
            presignedUrl = presignedUrl.replace(/^"|"$/g, '').trim();
            console.log('putting file to:', presignedUrl);
            const response = await fetch(presignedUrl, {
                method: "put",
                headers: {
                    'Content-Type': 'application/octet-stream',
                },
                body: file1.file
            });
            //console.log('response from s3', response);
            newFiles.push({...file1, url: `${process.env.REACT_APP_FILE_BUCKET}/${subBucket?`${subBucket}/`:''}${fileName??file1.caption}.${file1.mimeType.split('/').pop()}`});
        }
    }
    const theFiles = [...files.filter((file: any) => !file.isNewFile), ...newFiles];
    //setFiles(theFiles);
    return theFiles;
}

export const saveFileToS3 = async (file: File, fileName: string | undefined, subBucket?: string) => {
    //console.log('clicking');
    const accessToken = localStorage.getItem('accessToken')??'';
    const newFiles = [];
    //for (const file1 of files.filter((file: any) => file.isNewFile)) {
        console.log('file1', file);
        fileName = fileName? fileName: file.name.replace(/\.[^/.]+$/, "");
        console.log('file namme is ', fileName);
        console.log('saving file1', file);
        let presignedUrl = await retrievePresignedUrl(file, fileName, subBucket);
        console.log('presignedUrl', presignedUrl);
        try {
            if (presignedUrl) {
                presignedUrl = presignedUrl.replace(/^"|"$/g, '').trim();
                console.log('putting file to:', presignedUrl);
                const response = await fetch(presignedUrl, {
                    method: "put",
                    headers: {
                        'Content-Type': 'application/octet-stream',
                    },
                    body: file
                });
                //console.log('response from s3', response);
                //newFiles.push({...file, url: `${process.env.REACT_APP_FILE_BUCKET}/${subBucket?`${subBucket}/`:''}${fileName??file.name}.${file.type.split('/').pop()}`});
            }
            //}
            //const theFiles = [...file.filter((file: any) => !file.isNewFile), ...newFiles];
            //setFiles(theFiles);
            return `${process.env.REACT_APP_FILE_BUCKET}/${subBucket?`${subBucket}/`:''}${fileName??file.name}.${file.type.split('/').pop()}`;
        }
        catch (e) {
            console.log('error while saving', e);
            return '';
        }
}

function uuidv4() {
    return "10000000-1000-4000-8000-100000000000".replace(/[018]/g, (c:any) =>
        (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16)
    );
}

const retrievePresignedUrl = async (file:File, fileName?: string, subBucket?: string) => {
    //console.log('retrieving presigned url');
    console.log('generating url for file:', file);
    try {
        const response = await fetch(`${process.env.REACT_APP_API}/api/s3-url`, {
            method: "post",
            headers: {
                'Content-Type': 'application/json',
                Authorization: localStorage.getItem('accessToken')??'',
            },

            //make sure to serialize your JSON body
            body: JSON.stringify({
                mimeType: file.type.split('/').pop(),
                name: fileName??file.name,
                subBucket
            })
        });
        const json = await response.text();
        //console.log("presigned url", json);
        return json;
    }
    catch (e) {
        console.log('error retrieving url', e);
    }
}
