import {useState} from "react";
import {FAQ} from "./FAQ/FAQ";

export function FAQs() {
    const [faqs, _setFaqs] = useState([1, 1, 1, 1]);

    return (
        <div className={'flex flex-col gap-16 items-center'}>
            <div className={'text-center'}>
                <h3 className={'text-center text-primary text-title-24'}>FAQ</h3>
                <h2 className={'text-center text-title-42'}>Do you have any questions?</h2>
            </div>
            <div className="faqs-grid mx-[25px] sm:mx-[100px] custom:mx-[512px] rounded rounded-corners-big-card overflow-hidden">
                {/*{faqs.map((faq, index) =>*/}
                    <>
                        {/*{index !== 0 && <hr />}*/}
                        <FAQ title={'Is Hoot free to sign up?'} text={'Absolutely! HOOT is committed to providing a user-friendly platform that connects job seekers with exciting opportunities without any cost. Creating a profile on HOOT is completely free, and you can start browsing and applying for your next career move today!'} />
                        <hr />
                        <FAQ title={'Can it be used for free?'} text={''} />
                        <hr />
                        <FAQ title={'How many people can be added to a HR team?'} text={''} />
                    </>
                {/*)}*/}
            </div>
        </div>
    )
}
