import React from 'react';
import './App.scss';
import {JobsProvider} from "./providers/JobsProvider";
import {BrowserRouter, Route, Routes} from "react-router-dom";
import {Home} from "./pages/Home/Home";
import {JobDetailCopy} from "./components/JobDetailCopy/JobDetailCopy";
import {DetailsPage} from "./pages/DetailsPage/DetailsPage";
import {EditPage} from "./pages/EditPage/EditPage";
import './style.scss';
import './typography.scss';
import {SearchBar} from "./organisms/SearchBar/SearchBar";
import {Search} from "./pages/Search";
import {Privacy} from "./pages/Content/Privacy";
import {LocationProvider} from "./providers/LocationProvider";
import {UsersPage} from "./pages/UsersPage/UsersPage";
import {SearchOptionsProvider} from "./providers/SearchOptionsProvider";
import {Register} from "./pages/Register/Register";
import {AuthProvider} from "./providers/AuthProvider";
import {AuthGuard} from "./providers/AuthGuard";
import {TeacherLanding} from "./pages/TeacherLanding/TeacherLanding";
import {SchoolLanding} from "./pages/SchoolLanding/SchoolLanding";
import {Dashboard} from "./pages/Dashboard/Dashboard";
import {Terms} from "./pages/Content/Terms";
import {FavouritesPage} from "./pages/FavouritesPage/FavouritesPage";

console.log({REACT_APP_API: process.env.REACT_APP_API})
function App() {
  return (
    <JobsProvider mode={'dummy'}>

            <SearchOptionsProvider>
                <LocationProvider>

            <BrowserRouter>
                <AuthProvider>
                <Routes>
                    <Route index element={<Home />} />
                    <Route path="teachers" element={<TeacherLanding />} />
                    <Route path="privacy" element={<Privacy />} />
                    <Route path="terms" element={<Terms />} />
                    <Route path="schools" element={<SchoolLanding />} />
                    <Route path="search" element={<Search />} />
                    <Route path="jobdetail/:id" element={<DetailsPage />} />
                    <Route path="hoot-admin" element={<AuthGuard accessLevel={['GodMode']}><EditPage /></AuthGuard>} />
                    <Route path="hoot-admin/editpage" element={<AuthGuard accessLevel={['GodMode']}><EditPage filter={'NOT source:hoot'} /></AuthGuard>} />
                    <Route path="hoot-admin/editsaved" element={<AuthGuard accessLevel={['GodMode']}><EditPage filter={'source:hoot'} /></AuthGuard>} />
                    <Route path="hoot-admin/users" element={<AuthGuard accessLevel={['GodMode']}><UsersPage /></AuthGuard>} />
                    <Route path="favourites" element={<AuthGuard accessLevel={['teacher', 'GodMode']}><FavouritesPage /></AuthGuard>} />
                    <Route path="register" element={<Register />} />
                    <Route path="dashboard" element={<Dashboard />} />
                    <Route path="*" element={<Home numJobsDisplayed={12} />} />
                </Routes>
                </AuthProvider>
            </BrowserRouter>

                </LocationProvider>
            </SearchOptionsProvider>

    </JobsProvider>
  );
}

export default App;
