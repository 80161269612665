import {LandingSplash} from "../../organisms/LandingSplash/LandingSplash";
import {TextFeature} from "../../organisms/TextFeature/TextFeature";
import {ImageFeature} from "../../organisms/ImageFeature/ImageFeature";
import {KeyFeatures} from "../../organisms/KeyFeatures/KeyFeatures";
import {FAQs} from "../../organisms/FAQs/FAQs";
import {Header} from "../../components/Header/Header";
import DynamicImage from "../../components/DynamicImage";
import {AdditionalFeatures} from "../../organisms/AdditionalFeatures/AdditionalFeatures";
import {Footer} from "../../components/Footer/Footer";

export function SchoolLanding() {
    return (
        <div className={'flex flex-col gap-[42px]  overflow-scroll'}>
            <div className={'bg-school-blue text-black relative max-custom:pb-[320px]'}>
                <DynamicImage imagePath={'Girl 1.png'} className={'absolute bottom-0 right-[30%] custom:bottom-[-10px] custom:right-[148px] z-10 h-[550px] max-custom:h-[450px] max-md:h-[400px] max-sm:hidden'}></DynamicImage>
                <DynamicImage imagePath={'Schools-Hoot-Pattern.svg'} className={'absolute bottom-[-10px] right-[-100px] h-[100%] opacity-60 max-sm:hidden'}></DynamicImage>
                <DynamicImage imagePath={'Frame 2610026.svg'} className={'absolute bottom-0 w-full sm:hidden'}></DynamicImage>
                <Header logoColor={'rgba(52, 117, 245, 1)'} color={'white'}></Header>
                <LandingSplash ctas={<><button className={'rounded-corners bg-primary text-white w-[205px]'}>Sign up</button>
                    <button className={'rounded-corners text-primary border-solid border-[1px] border-primary bg-none w-[205px]'}>Talk to our team</button></>} audience={'school'} header={<>The <em className={'text-primary'}>Smart Way </em> to <br /> Teacher Recruitment!</>}></LandingSplash>
            </div>
            <TextFeature emphasisColor={'primary'} title={'Key features'} subtitle={<span>See why schools like yours<br className={'max-sm:hidden'} /> are <em>joining </em> us</span>}></TextFeature>
            <DynamicImage imagePath={'Scrolling Animation.png'} className={'md:h-[791px] mx-auto'}></DynamicImage>
            <ImageFeature
                imagePath={'Frame 2609939.png'} header={'Personalised HR Dashboard'} subheader={'Manage your entire recruitment process'}
                copy={<p>The Hoot Dashboard gives you the power to oversee every step of your recruitment journey from scheduling job ads to tracking applicants and more.</p>}
            ></ImageFeature>
            <ImageFeature
                copy={<p>Compact and informative.<br />
                    Don't waste time - all the information you need is at your fingertips.</p>}
                imagePath={'Frame 2609939-1.png'} header={'Engaging Job Post Designs'} subheader={'Add Your Job Posts with Ease'} reverse={true}></ImageFeature>
            <ImageFeature
                copy={<p>View applications as they arrive in your inbox. Invite your decision makers to your team, rate applicants and shortlist the right candidates for interview.</p>}
                imagePath={'Frame 2609939-2.png'} header={'Applicant Tracking System'} subheader={'Your complete HR solution'}></ImageFeature>
            <ImageFeature
                copy={<p>All teachers on Hoot use a customised Hoot CV which contains everything you need to know about your applicants.</p>}
                imagePath={'Frame 2609939-3.png'} header={'Hoot CV'} subheader={'Fast and efficient CV reviews'} reverse={true}></ImageFeature>
            <ImageFeature
                copy={<p>Attract prospective teachers with your school profile page. Upload your branding assets and provide key information for your applicants. </p>}
                imagePath={'Frame 2609939-4.png'} header={'School Profile'} subheader={'Shout about what you do best'}></ImageFeature>
            <AdditionalFeatures />
            <KeyFeatures></KeyFeatures>
            <FAQs></FAQs>
            <Footer />
        </div>
    );
}
