import {ApplyAside} from "../../components/ApplyAside/ApplyAside";
import {Header} from "../../components/Header/Header";
import {Banner} from "../../components/Banner/Banner";
import {Footer} from "../../components/Footer/Footer";
import {JobContext, JobProvider} from "../../providers/JobProvider";
import {JobDetailCopy} from "../../components/JobDetailCopy/JobDetailCopy";
import {useParams} from "react-router-dom";
import './DetailsPage.scss';
import {Job} from "../../types/job";
import {useContext} from "react";
import {JobDetailHeader} from "../../components/JobDetailHeader/JobDetailHeader";
import DynamicImage from "../../components/DynamicImage";
import loaderGraphic from '../../assets/images/Hoot-animation.svg';
import {DynamicSvg} from "../../atoms/DynamicSVG/DynamicSVG";

export function Loader() {
    const {job}:Job = useContext(JobContext);

    return (
        <>
            {job && <Footer/>}
        </>
    )
}

export function LoadLogo() {
    const {job}:Job = useContext(JobContext);

    return (
        <>
            {!job && <div className={'w-full flex justify-center w-full'}>
                <div dangerouslySetInnerHTML={{
                    __html: `<svg xmlns="http://www.w3.org/2000/svg" width="100" height="100" viewBox="0 0 100 100"
                             fill="none">
                    <ellipse id="_a0" opacity="0.25" fill="#3475F5" rx="28.8" ry="28.8"
                             transform="translate(50.0002,50.0002)"/>
                    <ellipse id="_a1" opacity="0.25" fill="#3475F5" rx="28.8" ry="28.8"
                             transform="translate(50.0002,50.0002)"/>
                    <ellipse id="_a2" opacity="0.25" fill="#3475F5" rx="28.8" ry="28.8"
                             transform="translate(50.0002,50.0002)"/>
                    <ellipse id="_a3" fill="#3475F5" rx="28.8" ry="28.8" transform="translate(50.0002,50.0002)"/>
                    <path
                        d="M45.8097,41.4921L38.9893,41.4921L38.9893,48.9087L46.292,48.9087L46.292,67.6004L53.7086,67.6004L53.7086,48.9087L61.0113,48.9087L61.0113,41.4921L54.191,41.4921C53.7419,43.0129,52.0361,44.1444,50.0003,44.1444C47.9646,44.1444,46.2588,43.0129,45.8097,41.4921Z"
                        fill="white" transform="translate(50.0003,54.5463) translate(-50.0003,-54.5463)"/>
                    <path d="M50.0003,32.4004L57.2262,36.2283L50.0003,40.0562L42.7744,36.2283L50.0003,32.4004Z"
                          fill="white"
                          transform="translate(50.0003,36.2283) translate(-50.0003,-36.2283)"/>
                    <script>/*
                        KeyshapeJS v1.3.0 (c) 2018-2023 Pixofield Ltd | pixofield.com/keyshapejs/mit-license */
                        window.KeyshapeJS=function(){function r(a) {
                            return
                        &
                            quot;
                            undefined & quot;
                        !==
                            typeof a
                        }function u(a,b){return a&amp;&amp;0===a.indexOf(b)}function X(a){var b={},c;for(c in a)b[c]=a[c];return b}function F(a){if(!isFinite(a))throw Error(&quot;Non-finite value&quot;);}function Y(a){if(14&gt;=a)return 16;var b=Z[a];b||(b=r(ka[a])?0|(a.toLowerCase().indexOf(&quot;color&quot;)===a.length-5?48:0):1);return b}function N(a){return 0&lt;=a?Math.pow(a,1/3):-Math.pow(-a,1/3)}function la(a,b,c,d){if(0===a)return 0===b?b=-d/c:(a=Math.sqrt(c*c-4*b*d),d=(-c+a)/
                            (2*b),0&lt;=d&amp;&amp;1&gt;=d?b=d:(d=(-c-a)/(2*b),b=0&lt;=d&amp;&amp;1&gt;=d?d:0)),b;var e=c/a-b*b/(a*a)/3;c=b*b*b/(a*a*a)/13.5-b*c/(a*a)/3+d/a;var f=c*c/4+e*e*e/27;b=-b/(3*a);if(0&gt;=f){if(0===e&amp;&amp;0===c)return-N(d/a);a=Math.sqrt(c*c/4-f);d=Math.acos(-c/2/a);c=Math.cos(d/3);d=Math.sqrt(3)*Math.sin(d/3);a=N(a);e=2*a*c+b;if(0&lt;=e&amp;&amp;1&gt;=e)return e;e=-a*(c+d)+b;if(0&lt;=e&amp;&amp;1&gt;=e)return e;e=a*(d-c)+b;if(0&lt;=e&amp;&amp;1&gt;=e)return e}else{a=N(-c/2+Math.sqrt(f));c=N(-c/2-Math.sqrt(f));d=a+c+b;if(0&lt;=d&amp;&amp;1&gt;=d)return d;d=-(a+c)/2+b;if(0&lt;=d&amp;&amp;1&gt;=d)return d}return 0}
                            function ma(a,b){if(48===a&amp;&amp;&quot;number&quot;===typeof b)return&quot;rgba(&quot;+(b&gt;&gt;&gt;24)+&quot;,&quot;+(b&gt;&gt;&gt;16&amp;255)+&quot;,&quot;+(b&gt;&gt;&gt;8&amp;255)+&quot;,&quot;+(b&amp;255)/255+&quot;)&quot;;if(64===a)return b=b.map(function(f){return f+&quot;px&quot;}),b.join(&quot;,&quot;);if(96===a){for(var c=&quot;&quot;,d=b.length,e=0;e&lt;d;e+=2)c+=b[e],c+=b[e+1].join(&quot;,&quot;);return c}if(80===a){if(0===b[0])return&quot;none&quot;;c=&quot;&quot;;d=b.length;for(e=0;e&lt;d;)c+=aa[b[e]],1===b[e]?c+=&quot;(&quot;+b[e+1]+&quot;) &quot;:5===b[e]?(c+=&quot;(&quot;+b[e+1]+&quot;px &quot;+b[e+2]+&quot;px &quot;+b[e+3]+&quot;px rgba(&quot;+(b[e+4]&gt;&gt;&gt;24)+&quot;,&quot;+(b[e+4]&gt;&gt;16&amp;255)+&quot;,&quot;+(b[e+4]&gt;&gt;8&amp;255)+&quot;,&quot;+(b[e+
                            4]&amp;255)/255+&quot;)) &quot;,e+=3):c=2===b[e]?c+(&quot;(&quot;+b[e+1]+&quot;px) &quot;):7===b[e]?c+(&quot;(&quot;+b[e+1]+&quot;deg) &quot;):c+(&quot;(&quot;+(0&gt;b[e+1]?0:b[e+1])+&quot;) &quot;),e+=2;return c}return 32===a?b+&quot;px&quot;:b}function B(a){return 0&gt;=a?0:255&lt;=a?255:a}function na(a,b,c,d){if(16===a||32===a)return(c-b)*d+b;if(0===a)return.5&gt;d?b:c;if(112===a)return 0&gt;=d?b:1&lt;=d?c:&quot;visible&quot;;if(48===a){if(&quot;number&quot;===typeof b&amp;&amp;&quot;number&quot;===typeof c){a=1-d;var e=a*(b&gt;&gt;&gt;16&amp;255)+d*(c&gt;&gt;&gt;16&amp;255),f=a*(b&gt;&gt;&gt;8&amp;255)+d*(c&gt;&gt;&gt;8&amp;255),m=a*(b&amp;255)+d*(c&amp;255);return B(a*(b&gt;&gt;&gt;24)+d*(c&gt;&gt;&gt;24))&lt;&lt;
                            24|B(e)&lt;&lt;16|B(f)&lt;&lt;8|B(m)}return.5&gt;d?b:c}if(64===a){0===b.length&amp;&amp;(b=[0]);0===c.length&amp;&amp;(c=[0]);a=b.length;b.length!==c.length&amp;&amp;(a=b.length*c.length);e=[];for(f=0;f&lt;a;++f)m=b[f%b.length],m=(c[f%c.length]-m)*d+m,0&gt;m&amp;&amp;(m=0),e.push(m);return e}if(96===a){if(b.length!==c.length)return.5&gt;d?b:c;a=b.length;e=[];for(f=0;f&lt;a;f+=2){if(b[f]!==c[f])return.5&gt;d?b:c;e[f]=b[f];e[f+1]=[];for(m=0;m&lt;b[f+1].length;++m)e[f+1].push((c[f+1][m]-b[f+1][m])*d+b[f+1][m])}return e}if(80===a){a=b.length;if(a!==c.length)return.5&gt;
                            d?b:c;e=[];for(f=0;f&lt;a;){if(b[f]!==c[f]||1===b[f])return.5&gt;d?b:c;e[f]=b[f];e[f+1]=(c[f+1]-b[f+1])*d+b[f+1];if(5===b[f]){e[f+2]=(c[f+2]-b[f+2])*d+b[f+2];e[f+3]=(c[f+3]-b[f+3])*d+b[f+3];m=1-d;var k=b[f+4],h=c[f+4],g=m*(k&gt;&gt;&gt;24)+d*(h&gt;&gt;&gt;24),n=m*(k&gt;&gt;8&amp;255)+d*(h&gt;&gt;8&amp;255),p=m*(k&amp;255)+d*(h&amp;255);e[f+4]=(B(m*(k&gt;&gt;16&amp;255)+d*(h&gt;&gt;16&amp;255))&lt;&lt;16|B(n)&lt;&lt;8|B(p))+16777216*(B(g)|0);f+=3}f+=2}return e}return 0}function ba(a,b){a:{var c=a+b[2];for(var d=b[4].length,e=0;e&lt;d;++e)if(c&lt;b[4][e]){c=e;break a}c=d-1}d=b[2];e=b[4][c-
                            1]-d;d=(a-e)/(b[4][c]-d-e);if(b[6]&amp;&amp;b[6].length&gt;c-1)if(e=b[6][c-1],1===e[0])if(0&gt;=d)d=0;else if(1&lt;=d)d=1;else{var f=e[1],m=e[3];d=la(3*f-3*m+1,-6*f+3*m,3*f,-d);d=3*d*(1-d)*(1-d)*e[2]+3*d*d*(1-d)*e[4]+d*d*d}else 2===e[0]?(e=e[1],d=Math.ceil(d*e)/e):3===e[0]&amp;&amp;(e=e[1],d=Math.floor(d*e)/e);return na(b[1]&amp;240,b[5][c-1],b[5][c],d)}function O(){C||(z=(new Date).getTime()+ca)}function U(a){if(a||!J){for(var b=!1,c=0;c&lt;D.length;++c)D[c].O(a)&amp;&amp;(b=!0);if(a)for(;0&lt;K.length;)if(a=K.shift(),c=a[0],1===a[1])c.onfinish&amp;&amp;
                            (c.onfinish(),b=!0),c.N();else if(2===a[1]&amp;&amp;c.onloop)c.onloop();return b}}function da(){O();U(!0)&amp;&amp;!C?(J=!0,P(da)):J=!1}function Q(){J||(J=!0,P(da))}function ea(a,b){var c=[];a.split(b).forEach(function(d){c.push(parseFloat(d))});return c}function G(a){-1===a.indexOf(&quot;,&quot;)&amp;&amp;(a=a.replace(&quot; &quot;,&quot;,&quot;));return ea(a,&quot;,&quot;)}function fa(a){a._ks||(a._ks={});if(!a._ks.transform){for(var b=a._ks.transform=[],c=0;14&gt;=c;++c)b[c]=0;b[10]=1;b[11]=1;if(a=a.getAttribute(&quot;transform&quot;)){a=a.trim().split(&quot;) &quot;);for(c=a.length-
                            2;0&lt;=c;--c)if(u(a[c],&quot;translate(&quot;)){for(var d=0;d&lt;c;d++)a.shift();break}c=a.shift();u(c,&quot;translate(&quot;)&amp;&amp;(c=G(c.substring(10)),b[1]=c[0],b[2]=r(c[1])?c[1]:0,c=a.shift());u(c,&quot;rotate(&quot;)&amp;&amp;(c=G(c.substring(7)),b[6]=c[0],c=a.shift());u(c,&quot;skewX(&quot;)&amp;&amp;(c=G(c.substring(6)),b[7]=c[0],c=a.shift());u(c,&quot;skewY(&quot;)&amp;&amp;(c=G(c.substring(6)),b[8]=c[0],c=a.shift());u(c,&quot;scale(&quot;)&amp;&amp;(c=G(c.substring(6)),b[10]=c[0],b[11]=r(c[1])?c[1]:c[0],c=a.shift());u(c,&quot;translate(&quot;)&amp;&amp;(c=G(c.substring(10)),b[13]=c[0],b[14]=r(c[1])?c[1]:
                            0)}}}function ha(a){this.m=a;this.I=[];this.G=[];this.D=0;this.B=this.g=this.i=null;this.o=this.j=this.l=0;this.h=1;this.s=this.K=this.A=!1}function H(a,b,c){b=a[b];void 0===b&amp;&amp;(b=a[c]);return b}function oa(a){return Array.isArray(a)?a:u(a,&quot;cubic-bezier(&quot;)?(a=a.substring(13,a.length-1).split(&quot;,&quot;),[1,parseFloat(a[0]),parseFloat(a[1]),parseFloat(a[2]),parseFloat(a[3])]):u(a,&quot;steps(&quot;)?(a=a.substring(6,a.length-1).split(&quot;,&quot;),[a[1]&amp;&amp;&quot;start&quot;===a[1].trim()?2:3,parseFloat(a[0])]):[0]}function pa(a){a=a.trim();
                            return u(a,&quot;#&quot;)?(parseInt(a.substring(1),16)&lt;&lt;8)+255:u(a,&quot;rgba(&quot;)?(a=a.substring(5,a.length-1),a=a.split(&quot;,&quot;),(parseInt(a[0],10)&lt;&lt;24)+(parseInt(a[1],10)&lt;&lt;16)+(parseInt(a[2],10)&lt;&lt;8)+255*parseFloat(a[3])&lt;&lt;0):a}function ia(a){!1===a.s&amp;&amp;(D.push(a),a.s=!0,!1!==a.m.autoplay&amp;&amp;a.play());return this}function V(a){if(!0===a.s){a._cancel();var b=D.indexOf(a);-1&lt;b&amp;&amp;D.splice(b,1);b=K.indexOf(a);-1&lt;b&amp;&amp;K.splice(b,1);a.s=!1}return this}var W=Error(&quot;Not in timeline list&quot;),ja=&quot;mpath posX posY    rotate skewX skewY  scaleX scaleY  anchorX anchorY&quot;.split(&quot; &quot;),
                            qa=&quot; translate translate    rotate skewX skewY  scale scale  translate translate&quot;.split(&quot; &quot;),aa=&quot;none url blur brightness contrast drop-shadow grayscale hue-rotate invert opacity saturate sepia&quot;.split(&quot; &quot;),P=window.requestAnimationFrame||window.webkitRequestAnimationFrame||window.mozRequestAnimationFrame||window.oRequestAnimationFrame||window.msRequestAnimationFrame||null;P||(P=function(a){window.setTimeout(a,16)});var ra=/apple/i.test(navigator.vendor),Z={d:97,fill:48,fillOpacity:16,filter:80,height:33,
                            opacity:16,offsetDistance:33,rx:33,ry:33,stroke:48,strokeDasharray:64,strokeDashoffset:32,strokeOpacity:16,strokeWidth:32,transform:1,visibility:113,width:33},ka=window.getComputedStyle(document.documentElement),J=!1,z=(new Date).getTime(),C,ca=0,D=[],K=[];ha.prototype={J:function(a){var b=0;if(null!==this.i){var c=this.u();0&lt;this.h&amp;&amp;null!==c&amp;&amp;c&gt;=this.j?this.o?(this.i=z-this.l/this.h,this.o--,b=2):(b=1,a?this.g=c:this.g=this.B?Math.max(this.B,this.j):this.j):0&gt;this.h&amp;&amp;null!==c&amp;&amp;c&lt;=this.l?this.o&amp;&amp;
                            Infinity!==this.j?(this.i=z-this.j/this.h,this.o--,b=2):(this.o=0,b=1,a?this.g=c:this.g=this.B?Math.min(this.B,this.l):this.l):null!==c&amp;&amp;0!==this.h&amp;&amp;(a&amp;&amp;null!==this.g&amp;&amp;(this.i=z-this.g/this.h),this.g=null)}this.B=this.u();return b},O:function(a){a&amp;&amp;(this.A&amp;&amp;(this.A=!1,null===this.i&amp;&amp;(0!==this.h&amp;&amp;null!==this.g?(this.i=z-this.g/this.h,this.g=null):this.i=z)),null===this.g&amp;&amp;null!==this.i&amp;&amp;(a=this.J(!1),0!==a&amp;&amp;K.push([this,a])));a=this.u();if(null===a)return!1;for(var b=this.I,c=this.G,d=0;d&lt;b.length;++d){for(var e=
                            b[d],f=!1,m=0;m&lt;c[d].length;++m){var k=c[d][m],h=k[0];if(null!==h){var g=k[2];var n=k[4].length,p=k[4][n-1]-g;g=0===p?k[5][n-1]:a&lt;g?!k[9]||k[9][0]&amp;1?k[5][0]:k[9][1]:a&gt;=g+k[3]?!k[9]||k[9][0]&amp;2?0===k[3]%p?k[5][n-1]:ba(k[3]%p,k):k[9][1]:ba((a-g)%p,k);0===h?(e._ks.mpath=k[8],e._ks.transform[h]=g,f=!0):14&gt;=h?(e._ks.transform[h]=g,f=!0):(g=ma(k[1]&amp;240,g),k[1]&amp;1?e.setAttribute(h,g):e.style[h]=g)}}if(f){f=e;fa(f);m=f._ks.transform;k=&quot;&quot;;if(h=f._ks.mpath)n=m[0],0&gt;n&amp;&amp;(n=0),100&lt;n&amp;&amp;(n=100),n=n*h[2]/100,g=h[1].getPointAtLength(n),
                            k=&quot;translate(&quot;+g.x+&quot;,&quot;+g.y+&quot;) &quot;,h[0]&amp;&amp;(.5&gt;n?(n=g,g=h[1].getPointAtLength(.5)):n=h[1].getPointAtLength(n-.5),k+=&quot;rotate(&quot;+180*Math.atan2(g.y-n.y,g.x-n.x)/Math.PI+&quot;) &quot;);for(h=1;h&lt;m.length;++h)g=m[h],g!==(10===h||11===h?1:0)&amp;&amp;(k+=&quot; &quot;+qa[h]+&quot;(&quot;,k=2&gt;=h?k+(1===h?g+&quot;,0&quot;:&quot;0,&quot;+g):13&lt;=h?k+(13===h?g+&quot;,0&quot;:&quot;0,&quot;+g):10&lt;=h?k+(10===h?g+&quot;,1&quot;:&quot;1,&quot;+g):k+g,k+=&quot;)&quot;);f.setAttribute(&quot;transform&quot;,k)}ra&amp;&amp;e.setAttribute(&quot;opacity&quot;,e.getAttribute(&quot;opacity&quot;))}return&quot;running&quot;===this.v()},N:function(){!1!==this.m.autoremove&amp;&amp;&quot;finished&quot;===
                            this.v()&amp;&amp;V(this)},H:function(){if(!this.K){this.K=!0;for(var a=this.I,b=this.G,c=0;c&lt;a.length;++c)for(var d=a[c],e=0;e&lt;b[c].length;++e)14&gt;=b[c][e][0]&amp;&amp;fa(d)}},C:function(a){if(&quot;number&quot;===typeof a)return[a,0];if(!r(this.m.markers)||!r(this.m.markers[a]))throw Error(&quot;Invalid marker: &quot;+a);a=this.m.markers[a];return r(a.time)?[+a.time,+(a.dur||0)]:[+a,0]},play:function(a){r(a)&amp;&amp;null!==a&amp;&amp;(a=this.C(a)[0],F(a),0&gt;this.h&amp;&amp;a&lt;this.l&amp;&amp;(a=this.l),0&lt;this.h&amp;&amp;a&gt;this.j&amp;&amp;(a=this.j),this.F(a,!0));if(!this.s)throw W;
                            a=this.u();if(0&lt;this.h&amp;&amp;(null===a||a&gt;=this.j||a&lt;this.l))this.g=this.l;else if(0&gt;this.h&amp;&amp;(null===a||a&lt;=this.l||a&gt;this.j)){if(Infinity===this.j)throw Error(&quot;Cannot seek to Infinity&quot;);this.g=this.j}else 0===this.h&amp;&amp;null===a&amp;&amp;(this.g=this.l);if(null===this.g)return this;this.i=null;this.A=!0;this.H();Q();return this},pause:function(a){if(!this.s)throw W;r(a)&amp;&amp;(a=this.C(a)[0],F(a));if(&quot;paused&quot;!==this.v()){O();var b=this.u();if(null===b)if(0&lt;=this.h)this.g=this.l;else{if(Infinity===this.j)throw Error(&quot;Cannot seek to Infinity&quot;);
                            this.g=this.j}null!==this.i&amp;&amp;null===this.g&amp;&amp;(this.g=b);this.i=null;this.A=!1;this.J(!1);this.H();Q()}r(a)&amp;&amp;this.F(a,!0);return this},range:function(a,b){if(0===arguments.length)return{&quot;in&quot;:this.l,out:this.j};var c=this.C(a)[0],d=this.D;r(b)&amp;&amp;(d=this.C(b)[0]);F(c);if(0&gt;c||0&gt;d||c&gt;=d||isNaN(d))throw Error(&quot;Invalid range&quot;);var e=this.v();this.l=c;this.j=d;&quot;finished&quot;===e&amp;&amp;&quot;running&quot;===this.v()&amp;&amp;this.play();return this},loop:function(a){if(!r(a))return{count:this.o};this.o=!0===a?Infinity:Math.floor(a);
                            if(0&gt;this.o||isNaN(this.o))this.o=0;return this},u:function(){return null!==this.g?this.g:null===this.i?null:(z-this.i)*this.h},F:function(a,b){b&amp;&amp;O();null!==a&amp;&amp;(this.H(),null!==this.g||null===this.i||0===this.h?(this.g=a,U(!1)):this.i=z-a/this.h,this.s||(this.i=null),this.B=null,this.J(!0),Q())},L:function(){return this.u()},time:function(a){if(r(a)){if(!this.s)throw W;a=this.C(a)[0];F(a);this.F(a,!0);return this}return this.L()},v:function(){var a=this.u();return this.A?&quot;running&quot;:null===a?&quot;idle&quot;:
                            null===this.i?&quot;paused&quot;:0&lt;this.h&amp;&amp;a&gt;=this.j||0&gt;this.h&amp;&amp;a&lt;=this.l?&quot;finished&quot;:&quot;running&quot;},state:function(){return this.v()},duration:function(){return this.D},M:function(a){F(a);O();var b=this.u();this.h=a;null!==b&amp;&amp;this.F(b,!1)},rate:function(a){return r(a)?(this.M(a),this):this.h},markers:function(a){return r(a)?(this.m.markers=X(a),this):r(this.m.markers)?this.m.markers:{}},marker:function(a){return r(this.m.markers)?this.m.markers[a]:void 0},_cancel:function(){if(!this.s||&quot;idle&quot;===this.v())return this;
                            this.i=this.g=null;this.A=!1;return this}};return{version:&quot;1.3.0&quot;,animate:function(){var a={};1===arguments.length%2&amp;&amp;(a=X(arguments[arguments.length-1]));a=new ha(a);for(var b=arguments,c=0,d=0;d&lt;b.length-1;d+=2){var e=b[d];var f=e instanceof Element?e:document.getElementById(e.substring(1));if(!f)throw Error(&quot;Invalid target: &quot;+e);e=f;f=b[d+1];e._ks||(e._ks={});for(var m=[],k=0;k&lt;f.length;++k){var h=f[k],g=H(h,&quot;p&quot;,&quot;property&quot;);if(&quot;string&quot;!==typeof g||-1!==g.indexOf(&quot;-&quot;)||&quot;&quot;===g||!(0&lt;Z[g]||0&lt;=ja.indexOf(g)))throw Error(&quot;Invalid property: &quot;+
                            g);var n=ja.indexOf(g);&quot;&quot;!==g&amp;&amp;0&lt;=n&amp;&amp;(g=n);n=Y(g);var p=H(h,&quot;t&quot;,&quot;times&quot;);if(!p||2&gt;p.length)throw Error(&quot;Not enough times&quot;);p=p.slice();if(!isFinite(p[0])||0&gt;p[0])throw Error(&quot;Invalid time: &quot;+p[0]);for(var w=1;w&lt;p.length;++w)if(!isFinite(p[w])||0&gt;p[w]||p[w]&lt;p[w-1])throw Error(&quot;Invalid time: &quot;+p[w]);w=p[0];var R=p[p.length-1]-w,S=h.iterations||0;1&gt;S&amp;&amp;(S=1);R*=S;c&lt;R+w&amp;&amp;(c=R+w);var I=H(h,&quot;v&quot;,&quot;values&quot;);if(!I||I.length!==p.length)throw Error(&quot;Values do not match times&quot;);I=I.slice();for(var x=g,l=I,L=Y(x)&amp;
                            240,q=0;q&lt;l.length;++q)if(96===L){for(var E=l[q].substring(6,l[q].length-2).match(/[A-DF-Za-df-z][-+0-9eE., ]*/ig),M=[],t=0;t&lt;E.length;++t){M.push(E[t][0]);for(var v=1&lt;E[t].trim().length?E[t].substring(1).split(&quot;,&quot;):[],A=0;A&lt;v.length;++A)v[A]=parseFloat(v[A]);M.push(v)}l[q]=M}else if(48===L)u(l[q],&quot;#&quot;)?(E=9===l[q].length,l[q]=parseInt(l[q].substring(1),16),E||(l[q]=256*l[q]|255)):u(l[q],&quot;url(&quot;)||&quot;none&quot;===l[q]||(console.warn(&quot;unsupported color: &quot;+l[q]),l[q]=0);else if(80===L){E=l;M=q;t=l[q];if(&quot;none&quot;===
                            t)t=[0];else{v=[];for(var y=t.indexOf(&quot;(&quot;);0&lt;y;)if(A=aa.indexOf(t.substring(0,y)),0&lt;=A){v.push(A);var T=t.indexOf(&quot;) &quot;);0&gt;T&amp;&amp;(T=t.length-1);y=t.substring(y+1,T).split(&quot; &quot;);5===A?(v.push(parseFloat(y[0])),v.push(parseFloat(y[1])),v.push(parseFloat(y[2])),v.push(pa(y[3]))):1===A?v.push(y[0]):v.push(parseFloat(y[0]));t=t.substring(T+1).trim();y=t.indexOf(&quot;(&quot;)}else break;t=v}E[M]=t}else 64===L?&quot;none&quot;!==l[q]?/^[0-9 .]*$/.test(l[q])?l[q]=ea(l[q],&quot; &quot;):(console.warn(&quot;unsupported value: &quot;+l[q]),l[q]=[0]):
                            l[q]=[0]:32===L?(F(l[q]),l[q]=parseFloat(l[q])):0===x&amp;&amp;(l[q]=parseFloat(l[q]));x=H(h,&quot;e&quot;,&quot;easing&quot;);l=p.length;for(x||(x=[]);x.length&lt;l;)x.push([1,0,0,.58,1]);for(l=0;l&lt;x.length;++l)x[l]=oa(x[l]);n=[g,n,w,R,p,I,x,S];p=H(h,&quot;mp&quot;,&quot;motionPath&quot;);r(p)&amp;&amp;0===g&amp;&amp;(n[8]=[],n[8][0]=h.motionRotate,g=document.createElementNS(&quot;http://www.w3.org/2000/svg&quot;,&quot;path&quot;),p||(p=&quot;M0,0&quot;),g.setAttribute(&quot;d&quot;,p),n[8][1]=g,n[8][2]=g.getTotalLength());h=H(h,&quot;f&quot;,&quot;fill&quot;);r(h)&amp;&amp;(n[9]=h.slice());m.push(n)}0&lt;m.length&amp;&amp;(a.I.push(e),a.G.push(m))}a.D=
                            c;a.l=0;a.j=a.D;ia(a);return a},add:ia,remove:V,removeAll:function(){for(var a=D.length-1;0&lt;=a;--a)V(D[a]);return this},timelines:function(){return D.slice()},globalPlay:function(){C&amp;&amp;(ca=C-(new Date).getTime(),C=void 0,Q());return this},globalPause:function(){C||(C=z,U(!1));return this},globalState:function(){return C?&quot;paused&quot;:&quot;running&quot;}}}();
                            </script>
                            <script>if(KeyshapeJS.version.indexOf('1.')!=0)throw Error('Expected KeyshapeJS v1.*.*');window.ks=document.ks=KeyshapeJS;(function(ks){
                            var tl=ks.animate('#_a0',[{p:'scaleX',t:[0,1000,2000],v:[1,1.73,1],e:[[1,0.42,0,0.58,1],[1,0.42,0,0.58,1],[1,0.42,0,0.58,1]]},{p:'scaleY',t:[0,1000,2000],v:[1,1.73,1],e:[[1,0.42,0,0.58,1],[1,0.42,0,0.58,1],[1,0.42,0,0.58,1]]}],
                            '#_a1',[{p:'scaleX',t:[0,1000,2000],v:[1,1.452,1],e:[[1,0.42,0,0.58,1],[1,0.42,0,0.58,1],[1,0.42,0,0.58,1]]},{p:'scaleY',t:[0,1000,2000],v:[1,1.452,1],e:[[1,0.42,0,0.58,1],[1,0.42,0,0.58,1],[1,0.42,0,0.58,1]]}],
                            '#_a2',[{p:'scaleX',t:[0,1000,2000],v:[1,1.21,1],e:[[1,0.42,0,0.58,1],[1,0.42,0,0.58,1],[1,0.42,0,0.58,1]]},{p:'scaleY',t:[0,1000,2000],v:[1,1.21,1],e:[[1,0.42,0,0.58,1],[1,0.42,0,0.58,1],[1,0.42,0,0.58,1]]}],
                            '#_a3',[{p:'scaleX',t:[0,1000,2000],v:[1,1,1],e:[[1,0.42,0,0.58,1],[1,0.42,0,0.58,1],[1,0.42,0,0.58,1]]},{p:'scaleY',t:[0,1000,2000],v:[1,1,1],e:[[1,0.42,0,0.58,1],[1,0.42,0,0.58,1],[1,0.42,0,0.58,1]]}],
                        {autoremove:false}).range(0,2000).loop(true);
                            if(document.location.search.substr(1).split('&amp;').indexOf('global=paused')&gt;=0)ks.globalPause();})(KeyshapeJS);
                    </script>
                </svg>`
                }}/>
            </div>
            }
        </>
    )
}


export function DetailsPage() {
    const {id} = useParams();
    console.log("My ID", id);

    return (
        <div className={'job-details flex flex-col gap-16 max-xl:gap-8'}>

            <JobProvider jobId={id} immediateLoad={true}>
                <header className={"px-8 max-sm:px-5 max-w-full"}>
                    <Header color="white"></Header>
                    <Banner/>
                </header>
                    <LoadLogo/>
                <div className={'px-1 max-sm:px-5 box-border md:px-8 max-w-full'}>
                    <main className={'max-w-full grid gap-6 justify-between mx-auto w-full py-0'}>
                        <JobDetailHeader/>

                        <ApplyAside/>

                        <JobDetailCopy/>
                    </main>
                </div>
                <Loader/>
            </JobProvider>
        </div>
    )
}
