import {DynamicSvg} from "../../atoms/DynamicSVG/DynamicSVG";

export function DashboardStats() {
    return (
        <div className={'flex gap-6 w-full'}>
            <div className={'rounded-corners-big-card w-[304px] p-5 border-[1px] border-solid border-border gap-6 flex flex-col'}>
                <h2 className={'text-body-18'}>My Job Stats</h2>
                <div className={'flex justify-between'}>
                    <div className={'flex flex-col gap-2'}>
                        <h3 className={"text-body-16 font-normal"}>Applications</h3>
                        <span className={'text-title-28 text-header'}>433</span>
                        <span className={'flex text-body-14 gap-1'}>
                            <DynamicSvg name={'chartline-up'} size={'16'} stroke={'#3475F5'} />
                            <span className={'text-primary text-body-12'}>5%</span>
                            <span className={'text-header text-body-12 opacity-60'}>vs last month</span>
                        </span>
                    </div>
                    <div className={'flex flex-col gap-2'}>
                        <h3 className={"text-body-16 font-normal"}>Views</h3>
                        <span className={'text-title-28 text-header'}>2812</span>
                        <span className={'flex text-body-14 gap-1'}>
                            <DynamicSvg name={'chartline-up'} size={'16'} stroke={'#3475F5'} />
                            <span className={'text-primary text-body-12'}>5%</span>
                            <span className={'text-header opacity-60 text-body-12'}>vs last month</span>
                        </span>
                    </div>
                </div>
            </div>
            <div className={'rounded-corners-big-card w-[315px] p-5 border-[1px] border-solid border-border gap-6 flex flex-col'}>
                <h2 className={'text-body-18'}>Applicant Statistics</h2>
                <div className={'flex gap-4 items-center'}>
                    <div className={'flex flex-col justify-center items-center relative h-[96px] w-[96px]'}>
                        <div className={'absolute h-full w-full'}>
                            <DynamicSvg name={'pie'} size={'96'} stroke={''} />
                        </div>
                        <span className={'text-body-16'}>324</span>
                        <span className={'text-body-12 opacity-60'}>Viewed</span>
                    </div>
                    <div className={'flex flex-col gap-1 text-body-14 text-header flex-grow'}>
                        <span className={"flex w-full items-center gap-2"}>
                            <span className={"flex rounded-full w-2 h-2 bg-primary"} />
                            <span className={'mr-auto'}>Interested</span>
                            <span className={'opacity-60'}>33%</span>
                        </span>
                        <span className={"flex w-full items-center gap-2"}>
                            <span className={"flex rounded-full w-2 h-2 bg-secondary"}/>
                            <span className={'mr-auto'}>Shortlisted</span>
                            <span className={'opacity-60'}>33%</span>
                        </span>
                        <span className={"flex w-full items-center gap-2"}>
                            <span className={"flex rounded-full w-2 h-2 bg-error"}/>
                            <span className={'mr-auto'}>Declined</span>
                            <span className={'opacity-60'}>33%</span>
                        </span>
                    </div>
                </div>
            </div>
            <div className={'rounded-corners-big-card p-5 border-[1px] border-solid border-border gap-6 flex flex-col flex-grow'}>
                <h2 className={'text-body-18 flex items-center'}>
                    <span className={"mr-auto"}>HR Team</span>
                    <DynamicSvg name={'chevron-right'} size={'24'} />
                </h2>
                <div className={"flex"}>
                    <div>
                        <DynamicSvg name={'avatars'} size={'42'} />
                    </div>
                    <div className={'ml-[-20px]'}>
                        <DynamicSvg name={'avatars'} size={'42'} />
                    </div>
                    <div className={'ml-[-20px]'}>
                        <DynamicSvg name={'avatars'} size={'42'} />
                    </div>
                    <div className={'ml-[-20px]'}>
                        <DynamicSvg name={'avatars'} size={'42'} />
                    </div>
                </div>
                <div className={'text-primary'}>
                    4 Members
                </div>
            </div>
        </div>
    )
}
