import {Tag} from "../../atoms/Tag/Tag";
import {AttachmentsGrid} from "../AttachmentsGrid/AttachmentsGrid";
import {JobContext} from "../../providers/JobProvider";
import {useContext, useEffect} from "react";
import {Job} from "../../types/job";
import './JobDetailHeader.scss';
import DynamicImage from "../DynamicImage";

export function JobDetailHeader() {
    const {job}:Job = useContext(JobContext);

    return (
            <section className="location-details flex flex-col gap-3">
                {
                    job? <Tag variant="location" text={job?.displayLocation}></Tag>: <></>
                }
                <h2>{ job?.title }</h2>
            </section>
    );
}
