import {Children, createContext, useContext, useEffect, useReducer, useState} from "react";
import {JobsContext} from "./JobsProvider";
import {Job} from "../types/job";
import {AuthContext} from "./AuthProvider";

export const UsersContext: any = createContext(null);

export function UsersProvider(props:any) {
    //const {jobs, dispatch}: any = useContext(JobsContext);

    const [users, setUsers] = useState(undefined as any | undefined);
    const {accessToken}: any = useContext(AuthContext);

    const listUsers = async () => {
        console.log('accessToken', accessToken);
        try {
            const response = await fetch(`${process.env.REACT_APP_API}/api/list-users`, {
                method: "get",
                headers: {
                    Authorization: accessToken
                },
            });

            //const response = await fetch(`https://api.hoot.works/jobs/${props.jobId}`);
            const json = await response.json();
            console.log('users json', json);
            //console.log(json.usersWithGroups);
            setUsers(json);
        }
        catch (e) {

            // setJob(undefined);
        }
    }

    useEffect(() => {


        listUsers();
    }, [accessToken]);

    const deleteUser = async (username: string) => {
        console.log('username', username);
        try {
            const response = await fetch(`${process.env.REACT_APP_API}/api/list-users`, {
                method: "delete",
                headers: {
                    Authorization: accessToken,
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({username})
            });

            //const response = await fetch(`https://api.hoot.works/jobs/${props.jobId}`);
            const json = await response.json();
            listUsers();
            //console.log(json.usersWithGroups);
            //setUsers(json.usersWithGroups);
            console.log('json', json);
        }
        catch (e) {

            // setJob(undefined);
        }
    }

    return (
        <UsersContext.Provider value={{users, deleteUser}}>
            {props.children}
        </UsersContext.Provider>
    )
}
