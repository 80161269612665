import {Button} from "../../atoms/Button/Button";
import {JobEdit} from "../../components/JobEdit/JobEdit";
import {Job} from "../../types/job";
import React, {useContext, useEffect, useState} from "react";
import {JobsContext} from "../../providers/JobsProvider";
import imageLogo from "../../assets/images/logo-blue.svg"
import './UsersPage.scss';

import DocumentIcon from "../../assets/icons/24/file-plus-blue.svg";
import SendIcon from "../../assets/icons/24/send-white.svg";
import {JobProvider} from "../../providers/JobProvider";
import {EditAside} from "../../components/EditAside/EditAside";
import {Dialog, DialogTrigger, Heading, Input, Label, Modal, TextField} from 'react-aria-components';
import {User} from "../../components/User/User";
import {UsersProvider} from "../../providers/UserProvider";
import {AuthContext} from "../../providers/AuthProvider";

export function UsersPage() {
    const {jobs, dispatch}: any = useContext(JobsContext);
    const [menuCollapsed, setMenuCollapsed] = useState(false);
    const [dirtyJobs, setDirtyJobs] = useState([] as number[]);
    const [checked, setChecked] = useState([] as number[]);
    const [filterLevel, setFilterLevel] = useState('all');
    const [query, setQuery] = useState('');

    const toggleMenuCollapse = () => {
        setMenuCollapsed(!menuCollapsed);
    }

    const selectAll = () => {
        const ids = jobs.map((job:any) => job.id);
        ids.forEach((id:number) => {
            if (!checked.includes(id)) {
                setChecked([...checked, id])
            }
        });
    }

    const unselectAll = () => {
        setChecked([]);
    }

    const [selectAllText, setSelectAllText] = useState('Select All');

    useEffect(() => {
        setSelectAllText(checked.length === jobs.length ? 'Unselect All' : 'Select All');
    }, [checked]);
    /*const selectAllText = () => {
        return checked.length === jobs.length ? 'Unselect All' : 'Select All';
    }*/

    window.onbeforeunload = function() {
        if (dirtyJobs.length  > 0) {
            return "Are you sure you want to leave?";
        }
    };

    const handleJobCleaned = (id: number) => {
        const index = dirtyJobs.indexOf(id);
        setDirtyJobs(dirtyJobs.splice(index-1, 1));
    }

    const handleJobDirtied = (id: number) => {
        if (!dirtyJobs.includes(id)) {
            setDirtyJobs([...dirtyJobs, id]);
        }
    }

    const toggleSelected = (id: number) => {
        if (!checked.includes(id)) {
            setChecked([...checked, id]);
        }
        else {
            const index = checked.indexOf(id);
            checked.splice(index-1, 1);
        }
    }

    const saveAllSelectedJobs = async () => {
        //await publishJobs(checked);
        checked.forEach((id: number) => {
            handleJobCleaned(id);
        });
        checked?.splice(0, checked.length);
    }

    const removeJobs = async () => {
        localStorage.removeItem('jobs');
    }

    let [isOpen, setOpen] = useState(false);

    const {logout}: any = useContext(AuthContext);

    return (
        <div className={`users-page page ${menuCollapsed?'menu-collapsed':''}`}>
            <div className="sidebar">
                <div className="top">
                    <div className="logo-holder">
                        <img src={imageLogo} alt="" className="logo" />
                    </div>
                    <span className="g">God Mode</span>
                </div>
                <ul className="top-menu">
                    <li><a href="/hoot-admin/editpage" className="web-scrape">Web Scrape</a></li>
                    <li><a href="/hoot-admin/editsaved" className="hoot-schools">Hoot Schools</a></li>
                    <li><a href="/hoot-admin/editpage" className="other-schools">Other Schools</a></li>
                    <li><a href="/hoot-admin/users" className="users">Users</a></li>
                </ul>
                <ul className="bottom-menu">
                <li><a className={'cursor-pointer collapse-btn'} onClick={toggleMenuCollapse}>Collapse</a></li>
                    <li><a className="logout cursor-pointer" onClick={()=>logout()}>Log-out</a></li>
                </ul>
            </div>
            <div className="main">
                <div className="top-header">
                    <div className="review xl w600">Edit Users</div>
                    <div className="ctas">

                    </div>
                </div>
                <main>
                    <div className={'mr-8 flex flex-col gap-8'}>
                        <div className={'flex'}>
                            <input type="text" value={query} onChange={(e)=>setQuery(e.target.value)} />
                        </div>
                        {/*<span className={'flex gap-4 filters'}>
                        <button onClick={()=>setFilterLevel('all')} className={`${filterLevel==='all'?'selected':''} h-11 md px-4 rounded-dd bg-white`}>All</button>
                        <button onClick={()=>setFilterLevel('bronze')} className={`${filterLevel==='bronze'?'selected':''} h-11 md px-4 rounded-dd bg-white`}>Bronze</button>
                        <button onClick={()=>setFilterLevel('gold')} className={`${filterLevel==='gold'?'selected':''} h-11 md px-4 rounded-dd bg-white`}>Gold</button>
                        <button onClick={()=>setFilterLevel('silver')} className={`${filterLevel==='silver'?'selected':''} h-11 md px-4 rounded-dd bg-white`}>Silver</button>
                    </span>*/}
                        <UsersProvider>
                            <User  filterLevel={filterLevel} searchQuery={query} />
                        </UsersProvider>
                        {/*{jobs.map((job:Job, index: any) =>
                        <JobProvider key={index} jobId={job.id}>
                            <JobEdit dirtied={dirtyJobs} selected={checked} onSelectToggled={toggleSelected} onJobDirtied={handleJobDirtied} onJobCleaned={handleJobCleaned} />
                        </JobProvider>
                    )}*/}
                    </div>
                </main>
            </div>
        </div>
    )
}
