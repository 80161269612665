import DynamicImage from "../DynamicImage";
import {DynamicSvg} from "../../atoms/DynamicSVG/DynamicSVG";

export function DashboardCard({title, date}: {title?: string, date?: Date}) {
    const isSelected = Math.random() < 0.3;
    const isExpired = date && date < new Date();

    const borderSpec = isSelected ? 'border-solid border-[3px] border-primary' : 'border-solid border-[1px] border-border';

    const opacity = isExpired ? 'opacity-60' : '';

    return (
        <div className={`flex flex-col p-5 gap-5 rounded-corners-big-card ${borderSpec}`}>
            <header className={"flex gap-3 items-center"}>
                <div className={"mr-auto"}>
                    <h3 className={`text-primary text-body-18 ${opacity}`}>{title??"Head of Drama"}</h3>
                    <h4 className={`text-header-light text-body-14 ${opacity}`}>Felixstowe School</h4>
                </div>
                <DynamicImage imagePath={'school-logo.svg'} />
                <DynamicSvg name={'more-vertical'} />
            </header>
            <p className={` ${opacity}`}>
                Felixstowe School is seeking a dynamic and creative educator to lead our Drama department as the Head of
                Drama. The successful candidate will have a passion for drama, a commitment to nurturing students'
                artistic talents, and the ability to inspire both students and colleagues. This role offers an exciting
                opportunity to shape the direction of our Drama program and contribute to the growth and success of our
                school.
            </p>
            {!isExpired && <div className={"flex gap-4"}>
                {isSelected && <button
                    className={"flex-shrink-0 w-1/2 text-primary bg-background flex-grow h-[56px] rounded-corners flex items-center justify-center gap-2"}>
                    <DynamicSvg name={'boost'} stroke={'#3475F5'}/> <span>Boosted</span></button>}
                {!isSelected && <button
                    className={"flex-shrink-0 w-1/2 text-white bg-primary flex-grow h-[56px] rounded-corners flex items-center justify-center gap-2"}>
                    <DynamicSvg name={'boost'} stroke={'white'}/> <span>Boost</span></button>}
                <button
                    className={"text-primary w-1/2 border-primary border-solid border-[1px] flex-grow h-[56px] rounded-corners flex items-center justify-center gap-2"}>
                    <span>Closing 16.03.2023</span></button>
            </div>}
            {isExpired && <div className={"flex gap-4"}>
                <button
                    className={"flex-shrink-0 w-1/2 text-white bg-error flex-grow h-[56px] rounded-corners flex items-center justify-center gap-2"}>
                    <span>Expired</span></button>
                <button
                    className={"text-primary w-1/2 border-primary border-solid border-[1px] flex-grow h-[56px] rounded-corners flex items-center justify-center gap-2"}>
                    <DynamicSvg name={'refresh-ccw'} stroke={'#3475F5'}/> <span>Closed {date.toLocaleDateString().replaceAll('/', '.')??'16.03.2023'}</span></button>
            </div>}
            <button className={"text-primary bg-background flex-grow h-[56px] rounded-corners"}>19 Applications</button>
        </div>
    )
}
