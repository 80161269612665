import {Header} from "../../components/Header/Header";
import {Footer} from "../../components/Footer/Footer";
import {FavouriteJobCards} from "../../components/JobCard/FavouriteJobCard";
import {useContext} from "react";
import {AuthContext} from "../../providers/AuthProvider";
//import backgroundImage from "../../../public/TextBackground.svg";

export const FavouritesPage = () => {
    const {userDetails: {name, family_name} = {name: undefined, family_name: undefined}}: any = useContext(AuthContext);

    return (
        <>
            <div className={"w-full flex-1 max-w-[1440px] mx-auto"}>
                <Header/>
                <div className={'h-[397px] flex items-center justify-center flex-col gap-6'} style={{backgroundImage:'url("/TextBackground.svg")'}}>
                    <img src="/Avatars.svg" />
                    <h2>{name} {family_name}</h2>
                </div>
                <div className={'mt-[54px]'}>
                    <FavouriteJobCards />
                </div>
            </div>
            <Footer/>
        </>
    )
}
