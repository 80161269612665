import {AutoSlider} from "../AutoSlider/AutoSlider";
import DynamicImage from "../DynamicImage";

const images = [
    'https://hootfiles.s3.eu-west-1.amazonaws.com/hoot-logos/%2B.png',
    'https://hootfiles.s3.eu-west-1.amazonaws.com/hoot-logos/600x200crest.png',
    'https://hootfiles.s3.eu-west-1.amazonaws.com/hoot-logos/Abbey-Logo-NewGreen2x.webp',
    'https://hootfiles.s3.eu-west-1.amazonaws.com/hoot-logos/c5570.jpg',
    'https://hootfiles.s3.eu-west-1.amazonaws.com/hoot-logos/Colchester_Grammer-16827be1.png',
    'https://hootfiles.s3.eu-west-1.amazonaws.com/hoot-logos/footer-logo.png',
    'https://hootfiles.s3.eu-west-1.amazonaws.com/hoot-logos/henrietta-barnett.jpg',
    'https://hootfiles.s3.eu-west-1.amazonaws.com/hoot-logos/logo-dark+(1).png',
    'https://hootfiles.s3.eu-west-1.amazonaws.com/hoot-logos/logo-tgs+(1).png',
    'https://hootfiles.s3.eu-west-1.amazonaws.com/hoot-logos/Logo-Wandsworth.jpg',
    'https://hootfiles.s3.eu-west-1.amazonaws.com/hoot-logos/logo.jpg',
    'https://hootfiles.s3.eu-west-1.amazonaws.com/hoot-logos/logo.png',
    'https://hootfiles.s3.eu-west-1.amazonaws.com/hoot-logos/logo_cambridge.png',
    'https://hootfiles.s3.eu-west-1.amazonaws.com/hoot-logos/Logo_of_King\'s_School%2C_Canterbury.svg.png',
    'https://hootfiles.s3.eu-west-1.amazonaws.com/hoot-logos/OFS%2BLogo%2BFiles-web-29.png',
    'https://hootfiles.s3.eu-west-1.amazonaws.com/hoot-logos/Ramsbury-Pre-School-Logo.jpg',
    'https://hootfiles.s3.eu-west-1.amazonaws.com/hoot-logos/Screenshot_2022-07-19_at_12.07.54_QhAJV7s.png',
    'https://hootfiles.s3.eu-west-1.amazonaws.com/hoot-logos/SFS_Logo_RGB_Black.png',
    'https://hootfiles.s3.eu-west-1.amazonaws.com/hoot-logos/South-West-Grammar-School-logo-2019.png',
    'https://hootfiles.s3.eu-west-1.amazonaws.com/hoot-logos/STG_Logo.png',
    'https://hootfiles.s3.eu-west-1.amazonaws.com/hoot-logos/TWS-Horizontal-2021PNG-01HIGHRES-01.png',
    'https://hootfiles.s3.eu-west-1.amazonaws.com/hoot-logos/westminster_60777c25-8b72-471f-b3ea-a37f7df5efdd_1063x.webp',
    'https://hootfiles.s3.eu-west-1.amazonaws.com/hoot-logos/Westminster_HomePage_Logo_Header_PNG-01.png',
    'https://hootfiles.s3.eu-west-1.amazonaws.com/hoot-logos/wspa.png',
];

export function ImageCarousel() {

    return (
        <div className={'max-sm:hidden'} style={{zIndex: 10, overflow: 'hidden', background:'white', boxSizing: 'border-box', paddingTop: '10px', paddingBottom:'10px', borderRadius: '16px'}}>

            <AutoSlider speed={0} >
                <div className={'flex gap-[250px] items-center'}>
                {images.map((image, index) => <>
                    {/*<DynamicImage className={'h-12'} key={index} imagePath={'logos/' + image}></DynamicImage>*/}
                    <img src={image} className={`h-12 ${images.length-1===index?'mr-[250px]':''}`} key={index}  />
                </>)}
                </div>
            </AutoSlider>

        </div>
    )
}
