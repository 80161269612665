import {Children, createContext, useContext, useEffect, useReducer, useState} from "react";
import {JobsContext} from "./JobsProvider";
import {Job} from "../types/job";
import {UsersContext} from "./UserProvider";
import algoliasearch from "algoliasearch/lite";

export const SearchOptionsContext: any = createContext(null);

const searchApp = process.env.REACT_APP_SEARCH_ID??'';
const searchKey = process.env.REACT_APP_SEARCH_KEY??'';

console.log('searchApp', searchApp);
console.log('searchKey', searchKey);

const searchClient = algoliasearch(searchApp, searchKey, { });

export function SearchOptionsProvider(props:any) {
    const [query, setQuery] = useState(undefined as string | undefined);

    return (
        <SearchOptionsContext.Provider value={{query, setQuery, searchClient}}>
            {props.children}
        </SearchOptionsContext.Provider>
    )
}
