import Editor from "@ckeditor/ckeditor5-build-classic";

export let config = Editor.defaultConfig;

config.toolbar.items = [ 'heading', '|', 'bold', 'italic', 'link', 'bulletedList', 'numberedList', 'blockQuote' ];

/*config.heading = {
    options: [
        { model: 'paragraph', title: 'Paragraph', class: 'ck-heading_paragraph' },
        { model: 'heading1', view: 'h2', title: 'Heading 1', class: 'ck-heading_heading1' },
        { model: 'heading2', view: 'h3', title: 'Heading 2', class: 'ck-heading_heading2' },
        { model: 'heading3', view: 'h4', title: 'Heading 3', class: 'ck-heading_heading2' }
    ]
};*/

export const linkOnly = {
    toolbar: [ 'link' ],
};

