import {Header} from "../components/Header/Header";
import {Footer} from "../components/Footer/Footer";
import {SearchBar} from "../organisms/SearchBar/SearchBar";
import {JobContext, JobProvider} from "../providers/JobProvider";
import {SearchJobCard as JobCard} from "../components/JobCard/SearchJobCard";
import {Button} from "../atoms/Button/Button";
import {ReactNode, useCallback, useContext, useEffect, useState} from "react";
import {JobsContext} from "../providers/JobsProvider";
import './Search.scss';
import algoliasearch from 'algoliasearch/lite';
import {
    InstantSearch,
    Hits,
    Highlight, RefinementList, Configure, SearchBox, useStats,
} from 'react-instantsearch';
import {FacetDropdown} from "./FacetDropdown";
import {Job} from "../types/job";
import {LocationContext} from "../providers/LocationProvider";
import {CustomSearchBox} from "../components/Search/SearchBox";
import {ResultsCount} from "./ResultsCount";
import {SearchOptionsContext} from "../providers/SearchOptionsProvider";

type PropTypes = {
    pageSize?: number
}



export function latlngsToDistance(lat1: number, lon1: number, lat2: number, lon2: number) {
    const R = 6371e3; // metres
    const φ1 = lat1 * Math.PI/180; // φ, λ in radians
    const φ2 = lat2 * Math.PI/180;
    const Δφ = (lat2-lat1) * Math.PI/180;
    const Δλ = (lon2-lon1) * Math.PI/180;

    const a = Math.sin(Δφ/2) * Math.sin(Δφ/2) +
        Math.cos(φ1) * Math.cos(φ2) *
        Math.sin(Δλ/2) * Math.sin(Δλ/2);

    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1-a));

    return R * c; // in metres
}

export function metresToMiles(metres: number) {
    return metres * 0.000621371192;
}

export function Search({pageSize = 60}: PropTypes) {
    const {latLng, setPostcode, radius}: { radius: number, latLng: number[], setPostcode: any } = useContext(LocationContext);
    const {query, setQuery, searchClient}: any = useContext(SearchOptionsContext);

    const [numJobsToDisplay, setNumJobsToDisplay] = useState(pageSize);


    const {jobs, dispatch}: any = useContext(JobsContext);

    const jobSlice = useCallback(() => {
        return jobs.slice(0, 12);
    }, [jobs, 12]);



    return (
        <InstantSearch searchClient={searchClient} indexName="hoot_jobs_search">
            <Configure  query={query} hitsPerPage={numJobsToDisplay}
                        aroundLatLng={latLng?.join(', ')} aroundRadius={radius} filters={`application.closeDateTimestamp >= ${Date.now()}`} />
            <div className={'flex-col flex'}>
                <header className={'flex flex-col gap-2'}>
                    <Header color="white"></Header>
                    <div className={'flex gap-4 px-8 sm:px-15 w-full justify-center max-sm:flex-col'}>
                        <div className={'w-full max-w-[1000px]'}>
                            <CustomSearchBox placeholder={'Job Title'} />
                        </div>
                        <SearchBar />
                    </div>
                </header>
                <main>
                    <h3 className={'recent-header mb-6 mt-8'}>Recent Jobs</h3>


                    <div className="cards">

                        <Hits hitComponent={JobCard}/>

                        <div className="view-more max-sm:mt-14 mt-12 flex w-full">

                            <div className={'max-w-full'}>
                                <Button disabled={false} expandable={true} text=""
                                        clickHandler={() => setNumJobsToDisplay(numJobsToDisplay + pageSize)}>
                                    View More
                                </Button>
                            </div>

                        </div>
                    </div>


                </main>
                <Footer/>
            </div>
        </InstantSearch>
    )
}
