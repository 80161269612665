import {useContext, useState} from "react";
import {signUp} from "../../utils/aws/cognito";
import {Checkbox} from "../../atoms/Checkbox/Checkbox";
import {AriaCheckbox} from "../../atoms/AriaCheckbox/AriaCheckbox";
import {navigate} from "@storybook/addon-links";
import {useNavigate} from "react-router-dom";
import {AuthContext} from "../../providers/AuthProvider";


export function RegisterForm() {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [fullName, setFullName] = useState('');
    const [surname, setSurname] = useState('');
    const [subject, setSubject] = useState('');
    const [phone, setPhone] = useState('');
    const [errors, setErrors] = useState([] as string[]);
    const navigate = useNavigate();
    const {register}:any = useContext(AuthContext);

    const performSignup = async () => {
        const response = await register({email, password, fullName, surname, subject, phone});
        if (response.errors) {
            setErrors(response.errors);
        }
        else {
            //console.log('navigate');
            navigate('/');
        }
    }

    return (
        <div className="login rounded-2xl gap-4 sm:gap-8 flex flex-col rounded-corners">
            <h2 className={'text-title-20 sm:text-title-32 font-bold'}>Register</h2>
            <div className="membership-selector flex flex-1 p-[6px] bg-bg-emphasis rounded-corners">
                <button className={'flex-1 h-11 primary rounded-corners'}>Teacher</button>
                <button className={'flex-1 h-11 ghost'}>School</button>
            </div>
            {errors.length > 0 && <div className={'flex flex-col gap-2 text-error'}>
                {errors.map(error => <div className={'text-red-500 text-body-16'}>{error}</div>)}
            </div>}
                <fieldset className={'grid grid-cols-1 gap-4 md:gap-5 basis-full'} >
                    <div className={'flex gap-2 max-sm:flex-col max-sm:gap-4'}>
                        <div className={'flex relative flex-grow'}>
                            <input value={fullName} onChange={(e) => setFullName(e.target.value)} type="text" placeholder="First Name" />
                        </div>
                        <div className={'flex relative flex-grow'}>
                            <input value={surname} onChange={(e) => setSurname(e.target.value)} type="text" placeholder="Surname" />
                        </div>
                    </div>
                    <div className={'flex relative'}>
                        <input value={subject} onChange={(e) => setSubject(e.target.value)} type="text" placeholder="Subject" />
                    </div>
                    <div className={'flex relative'}>
                        <svg className={'absolute left-[8px] top-[10px] md:top-[16px]'} style={{color: '#1B1F3B'}} width="24" height="24" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M14.6654 3.99935C14.6654 3.26602 14.0654 2.66602 13.332 2.66602H2.66536C1.93203 2.66602 1.33203 3.26602 1.33203 3.99935M14.6654 3.99935V11.9993C14.6654 12.7327 14.0654 13.3327 13.332 13.3327H2.66536C1.93203 13.3327 1.33203 12.7327 1.33203 11.9993V3.99935M14.6654 3.99935L7.9987 8.66602L1.33203 3.99935" stroke="rgba(27, 31, 59, .3)" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                        </svg>

                        <input style={{paddingLeft: '40px'}} value={email} onChange={(e) => setEmail(e.target.value)} type="text" placeholder="Email" />
                    </div>
                    <div className={'flex relative'}>
                        <svg className={'absolute left-[8px] top-[10px] md:top-[16px]'} style={{color: '#1B1F3B'}} width="24" height="24" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M4.66667 7.33301V4.66634C4.66667 3.78229 5.01786 2.93444 5.64298 2.30932C6.2681 1.6842 7.11595 1.33301 8 1.33301C8.88406 1.33301 9.7319 1.6842 10.357 2.30932C10.9821 2.93444 11.3333 3.78229 11.3333 4.66634V7.33301M3.33333 7.33301H12.6667C13.403 7.33301 14 7.92996 14 8.66634V13.333C14 14.0694 13.403 14.6663 12.6667 14.6663H3.33333C2.59695 14.6663 2 14.0694 2 13.333V8.66634C2 7.92996 2.59695 7.33301 3.33333 7.33301Z" stroke="rgba(27, 31, 59, .3)" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                        </svg>

                        <input style={{paddingLeft: '40px'}} value={password} onChange={(e) => setPassword(e.target.value)} type="password" placeholder="Password" />
                    </div>
                    <div className={'flex relative'}>
                        <svg className={'absolute left-[8px] top-[10px] md:top-[16px]'} style={{color: '#1B1F3B'}} width="24" height="24" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <g clip-path="url(#clip0_354_2154)">
                                <path d="M14.6669 11.2797V13.2797C14.6677 13.4654 14.6297 13.6492 14.5553 13.8193C14.4809 13.9894 14.3718 14.1421 14.235 14.2676C14.0982 14.3932 13.9367 14.4887 13.7608 14.5482C13.5849 14.6077 13.3985 14.6298 13.2136 14.6131C11.1622 14.3902 9.19161 13.6892 7.46028 12.5664C5.8495 11.5428 4.48384 10.1772 3.46028 8.56641C2.3336 6.82721 1.63244 4.84707 1.41361 2.78641C1.39695 2.60205 1.41886 2.41625 1.47795 2.24082C1.53703 2.0654 1.63199 1.9042 1.75679 1.76749C1.88159 1.63077 2.03348 1.52155 2.20281 1.44675C2.37213 1.37196 2.55517 1.33325 2.74028 1.33307H4.74028C5.06382 1.32989 5.37748 1.44446 5.62279 1.65543C5.8681 1.8664 6.02833 2.15937 6.07361 2.47974C6.15803 3.11978 6.31458 3.74822 6.54028 4.35307C6.62998 4.59169 6.64939 4.85102 6.59622 5.10033C6.54305 5.34964 6.41952 5.57848 6.24028 5.75974L5.39361 6.60641C6.34265 8.27544 7.72458 9.65737 9.39361 10.6064L10.2403 9.75974C10.4215 9.5805 10.6504 9.45697 10.8997 9.4038C11.149 9.35063 11.4083 9.37004 11.6469 9.45974C12.2518 9.68544 12.8802 9.84199 13.5203 9.92641C13.8441 9.97209 14.1399 10.1352 14.3513 10.3847C14.5627 10.6343 14.6751 10.9528 14.6669 11.2797Z" stroke="rgba(27, 31, 59, .3)" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                            </g>
                            <defs>
                                <clipPath id="clip0_354_2154">
                                    <rect width="16" height="16" fill="white"/>
                                </clipPath>
                            </defs>
                        </svg>
                        <input style={{paddingLeft: '40px'}} value={phone} onChange={(e) => setPhone(e.target.value)} type="text" placeholder="Phone: +4412341233456" />
                    </div>
                </fieldset>
                <div className={'grid grid-cols-1 gap-5 basis-full'}>
                    <div className={'flex gap-2 items-start'}>
                        <AriaCheckbox></AriaCheckbox>
                        <p className={'text-body-12 sm:text-body-16 p-0 m-0'}><a>Creating an account means you’re okay with our <a className={'text-primary'}>Terms of Service</a>, <a className={'text-primary underline'}>Privacy Policy</a>, and our default <a className={'text-primary underline'}>Notification Settings</a>.</a></p>
                    </div>
                    <button  onClick={()=>performSignup()} className={'rounded-corners basis-full mt-1 h-14 primary font-semibold'}>Register</button>
                    <p className={'basis-full'}>Already a member? <a className={'text-primary text-body-16'}>Sign in</a></p>
                </div>
        </div>
    )
}
