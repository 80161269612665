import {Children, createContext, useEffect, useReducer, useState} from "react";
import json from '../assets/json/samplejobs.json';
import {Job} from "../types/job";

const publishJobs = async (jobs: Job[], publishIds: (number|string)[], _mode='dummy') => {
    const jobsToPublish = jobs.filter((job) => job.id && publishIds.includes(job.id))
        .map(
            (job) => {
                return {...job}
            });
    if (_mode === 'live') {
        /*const response = await fetch('https://jrob4ucwng.execute-api.us-east-1.amazonaws.com/default/post', {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            method: 'POST',
            body: JSON.stringify(jobsToPublish),
        });*/
        //const results = await response.json();
        if (_mode !== 'live') {
        }
    }
    else {
        jobsToPublish.forEach((jobToPublish:Job) => {
            const index = jobs.findIndex((job) => job.id === jobToPublish.id);
            jobs[index] = jobToPublish;
        });
        localStorage.setItem('jobs', JSON.stringify(jobs));
    }
}

    const reducer =  (state:any[], action:any):any[] => {
        switch (action.type) {
            case 'publish':
                publishJobs(state, action.payload);
                return state;
            case 'initialise':
                return action.payload;
            case 'addJob':
                return [...state, action.payload];
            case 'removeJob':
                return [...state.filter((job: any) => job !== action.payload)];
            case 'editJob':
                return [...state.filter((job: any) => job !== action.payload.id), action.payload];
            default:
                throw new Error();
        }
    }

    type PropTypes = {
        children: any,
        mode: string,
    }

export const JobsContext: any = createContext(null);

export function JobsProvider({children, mode:_mode}:PropTypes) {
    const [favorites, setFavorites] = useState<any[]>([]);

    useEffect(() => {
        console.log('favorites', favorites);
    }, [favorites]);

    const toggleFavorites = (item: any) => {
        console.log('destr favorites', favorites);
        let temp = favorites?[...favorites]:[];
        if (temp.includes(item)) {
            temp = temp.filter(i => i !== item);
        } else {
            temp = [...temp, item];
        }
        setFavorites(temp);
        saveFaves(temp);
    }

    const saveFaves = async(ids: any[]) => {
        try {
            console.log('ids', ids);
            const body = JSON.stringify(ids);
            console.log('body', body);
            const response = await fetch(`${process.env.REACT_APP_API}/api/dynamo/favourites`, {
                method: "put",
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': localStorage.getItem('accessToken') || '',
                },
                body: JSON.stringify({updateData: {favourites: ids}}),
            });
            const results = await response.json();
            if (results) {
                setFavorites(results.favourites??[]);
            }
        }
        catch {
            console.log('Logged out');
        }
    }

    const getValue = async(showAll = false) => {
        try {
            if (localStorage.getItem('accessToken')) {
                const response = await fetch(`${process.env.REACT_APP_API}/api/dynamo/favourites${showAll ? '?showAll=true' : ''}`, {
                    method: "get",
                    headers: {
                        'Authorization': localStorage.getItem('accessToken') || '',
                    }
                });
                const results = await response.json();
                console.log('results', results.Items[0].favourites);
                /*const ids = results.map((result: any) => {
                    console.log('result', result);
                    console.log('result id', result["id"]);
                    return result??[];
                });*/
                //console.log('ids', ids);
                return results.Items[0].favourites;
            }
            else {
                return [];
            }
        }
        catch {
            console.log('Logged out');
        }
    }

    useEffect(() => {
        const retrieveValue = async () => {
            const value = await getValue();
                console.log('favourite value', value);
                setFavorites(value);
        }

        retrieveValue();
    }, []);

    async function load(): Promise<Job[]> {
        let jobsList: Job[] = [];
        if (_mode === 'live') {
            const response = await fetch('https://jrob4ucwng.execute-api.us-east-1.amazonaws.com/default/show');
            const results = await response.json();
            jobsList = results.Items;
        }
        else {
            const locallyStoredJobString = localStorage.getItem('jobs');
            if (locallyStoredJobString) {
                jobsList = JSON.parse(locallyStoredJobString);
            }
            else {
                jobsList = json as Job[];
                localStorage.setItem('jobs', JSON.stringify(json));
            }
        }
        return jobsList;
    }

    const getJobById = (id: number): Job | undefined => {
        return jobs.find((job) => job.id === Number(id));
    }

    const updateJob = (newJob: Job, id: number) => {
        const index = jobs.findIndex((job) => job.id === id);
        jobs[index] = newJob;
        // localStorage.setItem('jobs', JSON.stringify(jobs.value));
    }

    const [jobs, dispatch] = useReducer(reducer, []);

    useEffect(() => {
        load().then((jobsList: Job[]) => {
            dispatch({type: 'initialise', payload: jobsList});
        });
    }, []);

    return (
        <JobsContext.Provider value={{jobs, dispatch, favorites, toggleFavorites, getValue}}>
            {children}
        </JobsContext.Provider>
    )
}
