import {Button} from "../../atoms/Button/Button";
import {Job} from "../../types/job";
import {useNavigate} from "react-router-dom";
import {JobDates} from "../../atoms/JobDates/JobDates";
import {Tag} from "../../atoms/Tag/Tag";
import {useContext, useEffect, useState} from "react";
import {JobContext} from "../../providers/JobProvider";
import './JobCard.scss';
import {latlngsToDistance} from "../../pages/Search";
import {LocationContext} from "../../providers/LocationProvider";
import {DynamicSvg} from "../../atoms/DynamicSVG/DynamicSVG";
import {JobsContext} from "../../providers/JobsProvider";
import {AuthContext} from "../../providers/AuthProvider";

export function JobCard({job}: {job: any}) {
    const [distance, setDistance] = useState('');
    const [color, setColor] = useState('rgba(27, 31, 59, 0.1)');
    const {latLng, setPostcode, radius}: { radius: number, latLng: number[], setPostcode: any } = useContext(LocationContext);
    const {isLoggedIn, userGroups}: any = useContext(AuthContext);

    useEffect(() => {
        if (job && latLng)  {
            const d = latlngsToDistance(latLng[0], latLng[1], job?._geoloc?.lat, job?._geoloc?.lng);
            setDistance((Math.floor(d)).toString());
        }
    }, [job, latLng]);

    const {favorites, toggleFavorites}: any = useContext(JobsContext);


    useEffect(() => {
        console.log('favorites updated:', favorites);

    }, [favorites]);

    //const job:Job = useContext(JobContext);
    const navigate = useNavigate();

    const goToPage = (page: string) => {
        navigate(page);
    }

    const expiresSoon = (date: string) => {
        const today = new Date();
        const expiry = new Date(date);
        const diffTime = Math.abs(expiry.getTime() - today.getTime());
        const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
        return diffDays < 7;
    }

    return (
        <>
            {job?.employer?.name && !(job?.employer?.name?.toLowerCase().startsWith('tes')) && <a className={'cursor-pointer'} onClick={() => {
                goToPage(`/jobdetail/${job?.id}`)
            }}>
                <div className="job-card job relative" >
                    {isLoggedIn && <button className={`absolute right-[24px]`} onClick={e => {
                        e.preventDefault();
                        e.stopPropagation();
                        toggleFavorites(job?.id);
                    }}>
                        <DynamicSvg name={'heart'} stroke={'none'}
                                    color={favorites?.includes(job?.id) ? 'rgba(52, 117, 245, 1)' : 'rgba(27, 31, 59, 0.1)'}/>
                    </button>}
                    <div className="tags">
                        <Tag variant="location" text={job?.displayLocation.split(',')[0]} distance={distance}/>
                        {expiresSoon(job?.application?.closeDate) && <Tag variant="flag" text="Expiring Soon"/>}
                    </div>
                    <div className="header">
                        <div className="headings">
                            <h3 className="std lg job-title w600">{job?.title}</h3>
                            <h4 className="std sm job-location w400 op600">{job?.employer?.name}{/*, { job?.employer?.location?.name }*/}</h4>
                        </div>
                        <img src={job?.employer?.images?.logo} alt="" className="image-holder"/>
                    </div>
                    <div className="description">
                        <p>{job?.shortDescription}</p>
                    </div>
                    <div className="ctas">
                        <Button text="View" variant="primary" size="sm" expandable={true} clickHandler={() => {
                            goToPage(`/jobdetail/${job?.id}`)
                        }}/>
                        <JobDates applyDate={job?.application?.closeDate}
                                  startDate={job?.displayStartDate}></JobDates>
                    </div>
                </div>
            </a>}
        </>
    )
}
