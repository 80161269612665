import './Tag.scss';

type PropTypes = {
    text?: string,
    color?: string,
    variant?: string,
    size?: string,
    style?: object,
    icon?: object,
    distance?: string,
}

export function Tag({text, color='blue', variant, size='sm', icon, distance}: PropTypes) {
    const style = ():any => {
        return {
            '--bg-image': icon? `url('${icon}')`: 'none',
        }
    };

    return (
        <div className={`tag ${color} ${variant} ${variant==='location' && 'flex items-center overflow-hidden'}`} style={style()}>
            <div>{text}</div>
            {/*{variant==='location' &&
            <div style={{backgroundColor:'pink',height:'100%', left:'10px', background:'rgba(229, 233, 242, 1)'}} className={'px-2 relative flex items-center'}>{distance}</div>
            }*/}
        </div>
    )
}
