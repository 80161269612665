import React, {useContext} from "react";
import {Button} from "../../atoms/Button/Button";
import {Job} from "../../types/job";
import "./Banner.scss";
import {JobContext} from "../../providers/JobProvider";
import {DynamicSvg} from "../../atoms/DynamicSVG/DynamicSVG";

export function Banner() {
    const {job}:Job = useContext(JobContext);
    const visitWebpage = () => {
        window.open(job?.employer?.contact?.website, "_blank");
    }

    return (
        <div className="banner max-w-full max-xl:mt-4">

            { job &&
                <>
                    <img src={job?.employer?.images?.header} alt="" />
                    <header>
                        <div className="headings">
                            <h4>{ job?.employer?.name }</h4>
                            <span>{ job?.displayLocation }</span>
                        </div>
                        <div className="ctas">
                            {job?.employer?.contact?.website && <Button text="Website" variant="outline" clickHandler={visitWebpage}/>}
                        </div>
                    </header>
                </>
            }
        </div>
    )
}
